import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import getDataById from "../../../hooks/getDataById";

const EditVendor = () => {

  const [vendor_contact, setVendorContact] = useState("")
  const { control, register, setValue, handleSubmit, watch, formState: { errors } } = useForm();
  const user = JSON.parse(localStorage.getItem("user"))
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)

  const [error, setError] = useState("");


  const { id } = useParams();

  useEffect(() => {
    const getById = async () => {
      try {
        const response = await getDataById("admin/getbyidvendor", id);
        if (response.status_code === 200) {
          const { vendor_name, vendor_email, vendor_contact } = response.data;

          try {
            setValue("vendor_name", vendor_name)
            setValue("vendor_email", vendor_email)
            setValue("vendor_contact", vendor_contact)
          } catch (e) {
          }
        }
      }
      catch (err) {
      }


      setLoading(false);
    }
    getById();
  }, [id])

  const onSubmit = async (data) => {
    data["vendor_id"] = parseInt(id);

    const res = await fetch(`${process.env.REACT_APP_APIURL}/admin/updatevendor`, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      }

    })
    const response = await res.json();
    // const response = undefined

    if (response?.status_code === 200) {
      toast.success("Vendor updated Successfully");

      navigate("/admin/vendors");

    }
    else {
      toast.error("Something went Wrong")
    }
  }

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <h2 className="text-title text-lg md:text-2xl pb-2">Edit Vendor</h2>
      <div className="p-4 border border-gray-200 bg-white shadow-sm col-span-2 max-w-lg mx-auto">

        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
          <div className="form-control w-full max-w-md">
            <label className="label">
              <span className="label-text">Vendor Name</span>

            </label>
            <input
              {...register("vendor_name")}
              type="text"
              placeholder="Type here"
              required
              className="input input-bordered w-full max-w-md rounded-none" />

          </div>




          <div className="form-control w-full max-w-md">
            <label className="label">
              <span className="label-text">Email</span>

            </label>
            <input
              {...register("vendor_email")}
              type="email"
              placeholder="Type here"
              className="input input-bordered w-full max-w-md rounded-none" />


          </div>
          <div className="form-control w-full max-w-md">
            <label className="label">
              <span className="label-text">Contact</span>

            </label>
            <input
              {...register("vendor_contact")}
              type="number"
              placeholder="Type here"
              className="input input-bordered w-full max-w-md rounded-none" />


          </div>





          <div className="flex justify-center gap-x-4">

            <Link to={'/admin/vendors'} className="btn btn-sm btn-error max-w-md text-white rounded">Cancel</Link>
            <button className="btn btn-sm btn-primary max-w-md text-white rounded">Save</button>
          </div>
        </form>


      </div>
    </>
  );
};

export default EditVendor;
