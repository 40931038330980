import React, { useEffect, useState } from "react";
import ShopCategories from "./StoreComponents/ShopCategories";
import ShopHeader from "./StoreComponents/ShopHeader";
import ShopHeroSection from "./StoreComponents/ShopHeroSection";
import ShopNavbar from "./StoreComponents/ShopNavbar";
import StoreFooter from "./StoreComponents/StoreFooter";
import "./StoreStyle/StoreStyle.css";
import ScrollTopButton from "../../components/ScrollTopButton";
import { useContext } from "react";
import { userProvider } from "../../contexts/UserContext";
import LoadingSpinner from "../../components/utils/LoadingSpinner";
import { storeInformation } from "../../contexts/StoreInformationContext";
import FeedBack from "./FeedBack";
import FeatherProduct from "./FeatherProduct";
import { Helmet } from "react-helmet-async";
import { domainProvider } from "../../contexts/DomainContext";
import axios from "axios";

const StoreHome = () => {
  const [products, setProducts] = useState([]);
  const { isVendor } = useContext(userProvider);
  const { loading, storeDetails } = useContext(storeInformation);
  const storeName = storeDetails?.data?.storeDetails?.store_name
    ? storeDetails?.data?.storeDetails?.store_name
    : "";

  // custom domain
  const { customdomain, titleName } = useContext(domainProvider);


  useEffect(() => {
    const getAllProducts = async () => {
      var res;
      if (customdomain === "uat.aitech.com.sa") {
        res = await axios.get(
          `${process.env.REACT_APP_APIURL}/vendor/store/getallproducts/${storeName}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } else {
        res = await axios.get(
          `${process.env.REACT_APP_APIURL}/vendor/store/getallproducts`,
          {
            headers: {
              "Content-Type": "application/json",
              domain_name: customdomain,
            },
          }
        );
      }
      const response = await res.data;
      if (response.status_code === 200) {
        if (response?.data) setProducts(response.data);
        else if (response?.dummyData) setProducts(response.dummyData);
      }
    };
    getAllProducts();
  }, [storeName]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <ScrollTopButton>
      <div>
        <Helmet>
          <title>Home - {titleName}</title>
        </Helmet>
        {isVendor && <ShopHeader />}
        <div
          className={` ${isVendor && "mt-[26px]"}
        `}
        >
          <ShopNavbar />
        </div>
        <ShopHeroSection
          storeName={storeDetails?.data?.storeDetails?.store_name}
          slider={storeDetails?.data?.slider}
        />
        <ShopCategories />
        {/* Featured products section  */}
        <FeatherProduct
          products={products}
          storeName={storeDetails?.data?.storeDetails?.store_name}
        />
        {/* feedback section */}
        <FeedBack average={storeDetails?.data?.avg_rating[0]?.avg_rating} />
        {/* flower slider */}
        {/* <TopProductsSlider
          type={"customer"}
          storeName={storeDetails?.data?.storeDetails?.store_name}
        /> */}
        <StoreFooter />
      </div>
    </ScrollTopButton>
  );
};

export default StoreHome;

// {isVendor &&
//   (customdomain === "uat.aitech.com.sa" ||
//     orgDomain === "uat.aitech.com.sa") && <ShopHeader />}
