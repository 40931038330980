import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import SectionTitle from "../components/utils/SectionTitle";

const AgrementPage = () => {
  const { t } = useTranslation();
  let selectLanguage = i18next.language;
  return (
    <div id="agreement" className="mb-10">
      <div className="py-7">
        <SectionTitle page="agrement" />
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">مقدمة :</h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">Introduction :</h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <p className="my-3">
              ترحب بك تقنيات الذكاء الاصطناعي لتكنولوجيا المعلومات مع رقم
              التسجيل التجاري: 465028014، وتبلغك بأنك ستجد أدناه الشروط والأحكام
              التي تحكم إستخدامك لمنصة تقنيات الذكاء الاصطناعي، وجميع الآثار
              القانونية التي تنتج عن إستخدامك لخدمات منصة تقنيات الذكاء
              الاصطناعي على شبكة الإنترنت، كما يلي: أي شخص كان يستعمل لمنصة
              تقنيات الذكاء الاصطناعي، سواء كان مستهلكا أو تاجرا أو غير ذلك،
              فهذا يعتبر موافقة وقبولا منه، وهو بكل صفته محط نظر في الشريعة
              والنظام والقانون لكل مواد وأحكام هذه الاتفاقية، وهو تأكيد على
              التزامكم بنظامها وما ورد فيها، ونشير إليكم أن منبر تكنولوجيا
              الذكاء الاصطناعي قد يكون ( موقع إلكتروني أو تطبيق على الموبايل أو
              منصة إلكترونية) تكون هذه الاتفاقية سارية وواجبة التطبيق بمجرد
              الموافقة عليها وبدء التسجيل على منصة تقنية الذكاء الاصطناعي بموجب
              المادتين الخامسة والعاشرة من قانون المعاملات الإلكترونية السعودي .
            </p>
          </>
        ) : (
          <>
            <p className="py-3">
              Artificial Intelligence Technologies for Information Technology
              with Commercial Registration No.: 4650228014 welcomes you, and
              informs you that you will find below the terms and conditions
              governing your use of the Artificial Intelligence Technologies
              platform and all the legal effects that result from your use of
              the services of the Artificial Intelligence Technologies platform
              on the World Wide Web, as the use of any person was for the
              platform Artificial intelligence technologies, whether it is a
              consumer, a trader, or otherwise, this is considered an approval
              and acceptance by him, and he is in full capacity considered in
              Sharia, system and law for all the articles, terms and provisions
              of this agreement, and it is an affirmation of your commitment to
              its systems and what is mentioned in it, and we point out to you
              that the platform of AI Techmay be ( A website, application on
              mobile phones, or an electronic platform) This agreement is valid
              and enforceable once you approve it and start registering on the
              AI Techplatform under Articles Five and Ten of the Saudi
              Electronic Transactions Law .
            </p>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">المادة 1 - مقدمة وتعاريف :</h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article 1 - Introduction and Definitions :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <p className="py-3">
              وتشكل الديباجة الواردة أعلاه جزءا لا يتجزأ من هذا الاتفاق. ستجد
              أدناه المعاني والتعاريف الخاصة بالعبارات الرئيسية المستخدمة في هذه
              الاتفاقية :
            </p>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                منصة تقنيات الذكاء الاصطناعي المملوكة من مؤسسة تقنيات الذكاء
                الاصطناعي لتقنية المعلومات: هذا المصطلح يعني مؤسسة تطبيق تقنيات
                الذكاء الاصطناعي لتقنية المعلومات، ويتضمن هذا التعريف كل أشكال
                مؤسسة تطبيق تقنيات الذكاء الاصطناعي لتقنية المعلومات على
                الإنترنت، سواء كان تطبيقا إلكترونيا، موقعا على الشبكة العنكبوتية
                العالمية .
              </li>
              <li className="mb-3">
                التاجر : يعني هذا المصطلح كل تاجر يسجل في منصة آي تي تكنو ينشئ
                متجره الإلكتروني، سواء كان شخصا طبيعيا أو اعتباريا، ويشمل هذا
                التعريف كل جوانب المتجر ما دام يمارس تجارته عبر منصة تقنيات
                الذكاء الاصطناعي، وهذا يشمل موقع التاجر .
              </li>
              <li className="mb-3">
                المتجر : هذا المصطلح يعني المتجر الذي يستخدمه التاجر في النظام
                التقني الإلكتروني AI .
              </li>
              <li className="mb-3">
                المستهلك : تعني هذه العبارة كل مستهلك يشتري منتجا أو خدمة من
                تاجر، من خلال متجر التاجر على الإنترنت، الذي أنشأه من خلال منصة
                آي تي تيك.
              </li>
              <li className="mb-3">
                الاتفاق : هذا المصطلح يعني القواعد والأحكام والشروط اللازمة
                لاستخدام منصة تكنولوجيا الذكاء الاصطناعي الإلكترونية، أي جميع
                أحكام وشروط هذه الاتفاقية، التي تحكم وتنظم العلاقة بين أطراف هذه
                الاتفاقية .
              </li>
              <li className="mb-3">
                مقدم الخدمة : يعني هذا المصطلح الخدمات التي تقدمها منصة
                تكنولوجيا الذكاء الاصطناعي للتجار عن طريق الغير، ويعني تقديم
                الخدمات ببساطة توفير رابط بين التاجر ومقدم الخدمة، حيث لا توجد
                سلطة أو علاقة مع منصة تكنولوجيا الذكاء الاصطناعي بالاتفاق المبرم
                بين التاجر ومقدم الخدمة .
              </li>
            </ul>
          </>
        ) : (
          <>
            <p className="py-3">
              The preamble above is an integral part of this agreement. Below
              you will find the meanings and definitions of the main phrases
              used in this agreement :
            </p>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                {" "}
                Artificial Intelligence Technologies Platform owned by the
                Artificial Intelligence Technologies Corporation for Information
                Technology: This term means the Corporation for the Application
                of Artificial Intelligence Technologies for Information
                Technology, and this definition includes all forms of the
                Corporation for the Application of Artificial Intelligence
                Technologies for Information Technology on the Internet, whether
                it is an electronic application, A website on the World Wide Web
                .
              </li>
              <li className="mb-3">
                {" "}
                Merchant : This term means every merchant who registers in the
                platform of AI Techto establish his online store, whether he is
                a natural or legal person, and this definition includes all
                aspects of the store as long as he conducts his trade through
                the platform of artificial intelligence technologies, and this
                includes the website of the merchant .
              </li>
              <li className="mb-3">
                {" "}
                Store : This term means the store used by the merchant in the
                electronic AI Techplatform .
              </li>
              <li className="mb-3">
                {" "}
                consumer : This phrase means every consumer who purchases a
                product or service from a merchant, through the merchant's
                online store, which he established through the platform of AI
                Tech.
              </li>
              <li className="mb-3">
                {" "}
                Agreement : This term means the rules, terms and conditions for
                using the electronic AI Techplatform, that is, all the
                provisions and conditions of this agreement, which govern and
                regulate the relationship between the parties to this agreement
                .
              </li>
              <li className="mb-3">
                {" "}
                Service provider : This term means the services that the
                platform of AI Techprovides to merchants through third parties,
                and the provision of services means simply providing a link
                between the merchant and the service provider, as there is no
                authority or relationship to the platform of AI Techwith the
                agreement concluded between the merchant and the service
                provider .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة 2 - الأهلية القانونية للتاجر :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article 2 - Legal eligibility of the trader :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                ويعترف التاجر بأنه يتمتع بأهلية قانونية مدروسة قانونيا وقانونيا،
                وأن عمره لا يقل عن 18 عاما، كما هو وارد في القوانين والأنظمة
                السارية في المملكة العربية السعودية، وأن لديه المعرفة الكافية
                لإنشاء وإنشاء وإدارة متجره عبر الإنترنت من خلال منصة آي تي تك.
              </li>
              <li className="mb-3">
                إستثناء مما ورد في البند (1) من المادة (2) يجوز للتاجر أن يزاول
                التجارة إذا كان أقل من سن الرشد في المملكة العربية السعودية (18
                عاما)، لأنه ملزم بتقديم دليل على موافقة ولي أمره على إنشاء
                المتجر. الإلكتروني، مثل صك الوصاية، وبيان الموافقة الموقع من
                الوصي القانوني، إذا لزم الأمر .
              </li>
              <li className="mb-3">
                يعترف التاجر بأنه يتمتع بأهلية سليمة وأنه لا يعاني من أي من
                أعراض الأهلية .
              </li>
              <li className="mb-3">
                في حاله تسجيل التاجر علي انه مؤسسه أو شركه أو أي شكل قانوني آخر
                يجب ان تكون لهذه المؤسسه أو الشركه أو الشكل القانوني الذي يسجل
                من خلاله الاهليه القانونيه والتنظيميه والقانونيه اللازمه للقيام
                بالاعمال التجاريه من خلال المنبر الإلكتروني التقني التابع الذكاء
                الاصطناعي .
              </li>
              <li className="mb-3">
                يوافق التاجر على أنه إذا انتهك هذه المادة، "الأهلية القانونية
                للتاجر"، فإنه سيتحمل عواقب هذا الانتهاك أمام المستهلكين،
                المستخدمين، أو الأطراف الثالثة، لأن منصة تكنولوجيا الذكاء
                الاصطناعي لا علاقة لها بتعاملات التاجر مع المستهلك أو الأطراف
                الثالثة من وجهة نظر قانونية وتنظيمية ومشروعة. تقنيات الذكاء
                الاصطناعي، في هذه الحالة، تحاسب التاجر وفقا لأحكام المسؤولية
                التعاقدية، وتطالب بالتعويض عن الأضرار التي قد تنشأ نتيجة لهذا
                الانتهاك، مثل الأضرار التي لحقت منصة AI Techin سمعتها أمام
                التجار الآخرين، المستهلكين، المستخدمين، أو الأطراف الثالثة .
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                {" "}
                The merchant acknowledges that he has a legal capacity
                considered legally and legally, and that he is at least eighteen
                years old, as is considered in the laws and regulations in force
                in the Kingdom of Saudi Arabia, and that he has sufficient
                knowledge to create, establish and manage his online store
                through the platform of AI Tech.
              </li>
              <li className="mb-3">
                {" "}
                As an exception to what was stated in Clause 1 (1 ) of Article 2
                (2), the trader may engage in trade if he is less than the
                considered age of majority in the Kingdom of Saudi Arabia
                (eighteen years), as he is obligated to provide proof of the
                consent of his guardian to establish the store. Electronic, such
                as the guardianship deed, and the consent statement signed by
                his legal guardian, if necessary .
              </li>
              <li className="mb-3">
                {" "}
                The trader acknowledges that he is of sound eligibility and that
                he does not suffer from any of the symptoms of eligibility .
              </li>
              <li className="mb-3">
                {" "}
                In the event that the trader registers as an establishment or
                company, or any other legal form, then this establishment,
                company, or legal form through which he is registered must have
                the legal, regulatory and legal capacity necessary to carry out
                trade business through the electronic AI Techplatform .
              </li>
              <li className="mb-3">
                {" "}
                The merchant agrees that if he violates this article, “the
                merchant’s legal capacity,” he will bear the consequences of
                this violation in front of consumers, users, or third parties,
                as the platform for AI Techhas nothing to do with the merchant’s
                dealings with the consumer or third parties from a legal,
                regulatory, and legitimate point of view. Artificial
                intelligence techniques, in this case, hold the merchant
                accountable according to the provisions of contractual
                liability, and demand compensation for damages that may arise as
                a result of this violation, such as damages to the platform of
                AI Techin its reputation before other merchants, consumers,
                users, or third parties .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة 3 - طبيعة التزام منصة تكنولوجيا الذكاء الاصطناعي :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article 3 - The nature of the commitment of the AI Techplatform :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                مهمة منصة تكنولوجيا الذكاء الاصطناعي هي مجرد توفير أدوات الدعم
                الإلكترونية، من خلال إنشاء المتجر، حيث أن التزام منصة تكنولوجيا
                الذكاء الاصطناعي بموجب هذه الاتفاقية هو فقط لإنشاء متجر على
                الإنترنت للتاجر في منصة تكنولوجيا الذكاء الاصطناعي الإلكترونية،
                ووضع المتجر أمام المستخدمين .
              </li>
              <li className="mb-3">
                منصة تقنية الذكاء الاصطناعي قد تقدم خدمات أخرى للتاجر أو للمتجر
                مثل خدمات دعم التسويق أو خدمات التوجيه والإرشاد، وكذلك بوابات
                الدفع الإلكترونية عبر الإنترنت، وجميع خدمات منصة تقنية الذكاء
                الاصطناعي تخضع لمواد وأحكام وشروط إتفاقية الاستخدام هذه .
              </li>
              <li className="mb-3">
                منصة AI Techis ليست ملزمة بإتمام بيع المنتجات أو الخدمات بين
                المستهلك والمتجر، حيث أن التزامها ينتهي بتوفير خدمات إنشاء
                المتجر عبر الإنترنت من خلال منصة تقنيات الذكاء الاصطناعي، وهذا
                لا يضر الخدمات الأخرى التي لا تزال تقدم منصة AI Techhis مثل
                خدمات التوجيه والمشورة والدعم التقني والتسويق ووسائل الدفع .
              </li>
              <li className="mb-3">
                منصة AI Tech قد توفر مؤقتا بعض الخدمات المجانية لبعض المتاجر،
                مثل للتاجر: تعديل وتغيير مظهر أو شكل أو ألوان المتجر وفقا
                لرغبته، وهذه الخدمات يتم تقديمها من قبل إدارة منصة AI Tech مجانا
                وهي محدودة وتخضع لقيود محددة ومحددة إذا كان التاجر يرغب في خدمات
                إضافية، يجب عليه الاتصال بالدعم الفني من AI Techplatform
                للاستفسار عن العروض ورسوم الطرود والخدمات الإضافية .
              </li>
              <li className="mb-3">
                كل المعاملات التي تتم بين التاجر والمستهلك لا تمت بصلة إلى شخص
                منصة تكنولوجيا الذكاء الاصطناعي، و لا علاقة لمنصة تكنولوجيا
                الذكاء الاصطناعي بها، حيث أن هذه الصفقة علاقة تعاقدية مستقلة
                تخضع إلى الاتفاقية المبرمة بين التاجر والمستهلك. وعليه فإذا لم
                يدفع المستهلك ثمن الخدمة أو المنتج المقدم من التاجر فلا علاقة
                لمنصة الذكاء الاصطناعي بهذه المخالفات .
              </li>
              <li className="mb-3">
                جميع المعاملات التي تتم بين التاجر ومقدمي الخدمات (خدمات الطرف
                الثالث) الذين تقدم AI TechPlatform خدماتهم أو تقدم خدماتهم لصالح
                التاجر والمستهلك لا علاقة لها ب AI TechPlatform، حيث إن هذا
                التعامل هو علاقة تعاقدية مستقلة عن منصة تكنولوجيا الذكاء الذكاء
                الذكاء الذكاء الاصطناعي وتخضع للاتفاق المبرم بين التاجر ومقدم
                الخدمة، وبالتالي، إذا فشل أو رفض أو لا أحد الطرفين تنفيذ
                التزاماته التي تم الاتفاق عليها أو عدم تنفيذها بالطريقة
                المطلوبة، فإن منصة AI TechPlatform ليست مسؤولة عن نتائج هذه
                الإجراءات. الذكاء الاصطناعي ليس مسؤولا عن أي انتهاكات تحدث أو
                ترتكب بين التاجر ومقدم الخدمة .
              </li>
              <li className="mb-3">
                تعلمون أن منصة تكنولوجيا الذكاء الاصطناعي هي منصة إلكترونية
                تقنية على الإنترنت تسمح للتاجر الذي يوافق على هذه الاتفاقية
                بإنشاء متجره على الإنترنت والقيام بنشاطه من خلال المتجر، وتنتهي
                مهمته في هذه المرحلة. لا يوجد على منصة آي تي تكفور أي مسئولية عن
                مخالفات يرتكبها التاجر في متجره بالمخالفة لأحكام هذه الإتفاقية،
                ولا يوجد لمنصة أي تكنيك أي علاقة فيما يتعلق بالمعاملات التي تتم
                بين التاجر والمستهلك .
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                {" "}
                The task of the AI Techplatform is merely to provide electronic
                support tools, by establishing the store, as the commitment of
                the AI Techplatform under this agreement is only to create the
                online store for the merchant at the electronic AI Techplatform,
                and to put the store in front of users .
              </li>
              <li className="mb-3">
                {" "}
                The artificial intelligence technology platform may provide
                other services to the merchant or store such as marketing
                support services or guidance and counseling services, as well as
                electronic payment gateways via the Internet, and all the
                services of the artificial intelligence technology platform are
                subject to the articles, terms and conditions of this usage
                agreement .
              </li>
              <li className="mb-3">
                {" "}
                The platform of AI Techis not obligated to complete the sale of
                products or services between the consumer and the store, as its
                commitment ends with providing the services of creating the
                online store through the platform of artificial intelligence
                technologies, and this does not harm the other services that the
                platform of AI Techcontinues to provide such as guidance and
                counseling services, technical support, marketing and means
                Payment .
              </li>
              <li className="mb-3">
                {" "}
                The platform of AI Techmay temporarily provide some free
                services to some stores, such as for the merchant to: modify and
                change the appearance, shape or colors of the store according to
                his desire, and these services are provided by the management of
                the platform of AI Techfree of charge and are limited and are
                subject to specific and certain restrictions If the merchant
                desires additional services, he must contact the technical
                support of the AI Techplatform to inquire about offers and fees
                for packages and additional services .
              </li>
              <li className="mb-3">
                {" "}
                All transactions that take place between the merchant and the
                consumer have nothing to do with the person of the AI
                Techplatform , and the AI Techplatform is not responsible for
                them, as this dealing is an independent contractual relationship
                subject to the agreement concluded between the merchant and the
                consumer. Accordingly, if the consumer fails to pay the price of
                the service or product provided by the merchant, the artificial
                intelligence platform has nothing to do with these violations .
              </li>
              <li className="mb-3">
                {" "}
                All transactions that take place between the merchant and
                service providers (third party services) whose services the AI
                Techplatform provides linking with or offer their services for
                the benefit of the merchant and the consumer have nothing to do
                with the AI Techplatform, as this dealing is a contractual
                relationship independent of the intelligence technologies
                platform artificial intelligence and subject to the agreement
                concluded between the merchant and the service provider, and
                accordingly, if he fails, refuses, or one of the parties does
                not implement his obligations that were agreed upon or does not
                implement them in the required manner, the AI Techplatform is
                not responsible for what results from these actions. Artificial
                Intelligence is not responsible for any violations that occur or
                are committed between the merchant and the service provider .
              </li>
              <li className="mb-3">
                {" "}
                You know that the artificial intelligence technology platform is
                a technical electronic platform on the Internet that allows the
                merchant who agrees to this agreement to establish his online
                store and carry out his activity through the store, and its
                mission ends at this point. There is no responsibility on the
                platform of AI Techfor violations committed by the merchant in
                his store in violation of the provisions of this agreement, and
                the platform of AI Techhas no relationship with regard to the
                transactions that take place between the merchant and the
                consumer .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة 4 - ضوابط إنشاء متجر على الإنترنت :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article 4 - Controls for creating an online store :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                يجوز لكل شخص لديه الأهلية القانونية أن ينشئ متجره وفقا لقواعد
                وأحكام إتفاقية الاستخدام، ولا سيما " المادة الأولى - الأهلية
                القانونية للتاجر ".
              </li>
              <li className="mb-3">
                يجب على المتجر الذي تم إنشاؤه وفقا للاتفاق الخاص باستخدام منصة
                آي تي أن لا يخالف القوانين والأنظمة في المملكة العربية السعودية،
                والتاجر ملزم بتوضيح ما هو العمل التجاري الذي يقوم من خلاله
                التجارة الإلكترونية، وما هي الخدمات أو المنتجات التي يقدمها أو
                يبيعها، ومنصة آي تي تيك تنصل من مسؤوليتها عن انتهاك المتجر
                لأحكام النظام السعودي في المملكة العربية السعودية العربية
                والآداب العامة ومنصة أي تي أي التكنولوجية يحق لها دائما رفض
                تسجيل أي متجر إلكتروني لا يتوافق مع القوانين والأنظمة المرعية في
                المملكة العربية السعودية أو أحكام هذه الاتفاقية، ومن ثم يعترف
                التاجر، وفقا لأحكام الاتفاقية، بأن متجره لا يخالف النظام العام
                في المملكة العربية السعودية أو الآداب الإسلامية .
              </li>
              <li className="mb-3">
                يجب ألا يكون المتجر الذي تم إنشاؤه من خلال منصة AI Tech مخالفا
                لقواعد وأحكام إتفاقية الاستخدام هذه، ويقر التاجر بأن المتجر الذي
                يركز على التعامل في المتجر لا يخالف هذه الاتفاقية ولا يخالف
                القوانين والأنظمة المعمول بها في المملكة العربية السعودية .
              </li>
              <li className="mb-3">
                لا أحد يملك الحق في إستخدام منصة AI Techif عضويته أو متجره ملغاه
                من قبل منصة AI Techor بأوامر أو أحكام قضائية .
              </li>
              <li className="mb-3">منصة AI التقنية</li>
              <li className="mb-3">
                يجب على جميع المحلات والتجار الالتزام بكافة القوانين المرعية
                الإجراء والمتعلقة بتنظيم التجارة الإلكترونية والالتزام بقوانين
                جرائم المعلومات وبأنظمة وزارة التجارة والاستثمار ونظام التعاملات
                الإلكترونية ونظام التجارة الإلكترونية .
              </li>
              <li className="mb-3">
                ويقر التاجر بانه تحقق قبل التسجيل في النظام الإلكتروني أي ايه من
                توافر جميع الاجراءات والمتطلبات التي تطلبها الجهات الرسمية في
                المملكة العربية السعودية، وقد أوفى بكل هذه الاجراءات والمتطلبات
                لممارسة نشاطه في المتجر .
              </li>
              <li className="mb-3">
                إذا كان التاجر الذي يطلب الانضمام إلى المتجر وتأسيسه تاجرا فرديا
                "شخصا طبيعيا" فيكون ملزما أيضا بالتحقق من الاشتراطات التي
                تتطلبها السلطات الرسميه وتوفيرها وفقا لطبيعة نشاط التاجر الفرد
                حيث ان التاجر الفرد يعترف بانه ملتزم بهذه الاشتراطات وملتزم
                بتوفيرها وتجهيزها كما التزم التاجر ويجب علي الفرد ان يقدم رقم
                هويته الوطنيه وغيرها من المعلومات والمستندات اللازمه التي
                تتطلبها المنصة من AI Tech.
              </li>
              <li className="mb-3">
                إذا كان التاجر الذي يتقدم بطلب الانضمام وانشاء متجره يمثل مؤسسه
                تجاريه أو شركه أو مؤسسه خيريه أو كيانه اعتباريه وجب تزويد مؤسسه
                أي ايه للتقنية بكافه المعلومات والمستندات المؤيده كالسجل التجاري
                وايه مستندات آخري للمتجر تكون في حاجه إلى تسجلها من قبل أي من
                هذه المنبر لإثبات الشخصيه الاعتباريه للمتجر .
              </li>
              <li className="mb-3">
                يجب أن يلتزم المتجر والتاجر بالتزامات التسجيل المحددة في "
                المادة الخامسة - الحسابات والتزامات التسجيل"، وجميع قواعد وأحكام
                إتفاقية الاستخدام .
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                {" "}
                It is permissible for every person who has the legal capacity
                considered legally and legally to establish his store in
                accordance with the rules and provisions of the usage agreement,
                and in particular “ Article One – The Merchant’s Legal Capacity
                ”.
              </li>
              <li className="mb-3">
                {" "}
                The store that was established according to the agreement to use
                the platform of AI Techmust not violate the laws and regulations
                in the Kingdom of Saudi Arabia, and the merchant is obligated to
                clarify what business he is doing electronic commerce through,
                and what services or products he provides or sells, and the
                platform of AI Techdisclaims its responsibility For the store’s
                violation of the provisions of the Saudi system in the Kingdom
                of Saudi Arabia and public morals, and the platform for AI
                Techalways has the right to refuse to register any online store
                that does not comply with the laws and regulations in force in
                the Kingdom of Saudi Arabia or the provisions of this agreement,
                and accordingly the merchant acknowledges, according to the
                provisions of the agreement, that His store does not violate
                public order in the Kingdom of Saudi Arabia or Islamic morals .
              </li>
              <li className="mb-3">
                {" "}
                The store that was established through the platform of AI
                Techmust not be in violation of the rules and provisions of this
                usage agreement, and the merchant acknowledges that the store
                that is focused on dealing in the store is not in violation of
                this agreement and is not in violation of the laws and
                regulations in force in the Kingdom of Saudi Arabia .
              </li>
              <li className="mb-3">
                {" "}
                No one has the right to use the platform of AI Techif his
                membership or store is canceled by the platform of AI Techor by
                judicial orders or rulings .
              </li>
              <li className="mb-3"> AI Techplatform .</li>
              <li className="mb-3">
                {" "}
                All stores and merchants must abide by all applicable laws
                regulating online commerce, as well as comply with the
                information crimes law, the regulations of the Ministry of
                Commerce and Investment, the electronic transactions system, and
                the electronic commerce system .
              </li>
              <li className="mb-3">
                {" "}
                The merchant acknowledges that before registering in the
                electronic AI Techplatform, he has verified the availability of
                all procedures and requirements required by the official
                authorities in the Kingdom of Saudi Arabia, and he has fulfilled
                all these procedures and requirements to practice his activity
                in the store .
              </li>
              <li className="mb-3">
                {" "}
                In the event that the merchant applying to join and establish
                the store is an individual merchant “a natural person”, then he
                is also obligated to verify and provide the requirements
                required by the official authorities according to the nature of
                the activity of the individual merchant, as the individual
                merchant acknowledges that he is committed to these requirements
                and is committed to providing and equipping them, just as the
                merchant is committed The individual shall provide his national
                identity number and other necessary information and documents
                required by the platform of AI Tech.
              </li>
              <li className="mb-3">
                {" "}
                In the event that the merchant applying to join and establish
                his store represents a commercial establishment, company,
                charitable organization, or legal entity, the AI Techplatform
                must be provided with all supporting information and documents,
                such as the commercial register and any other documents for the
                store required by the AI Techplatform for registration and to
                prove the legal personality of the store .
              </li>
              <li className="mb-3">
                {" "}
                The store and the merchant must abide by the registration
                obligations specified in “ Article Five - Accounts and
                Registration Obligations”, and all the rules and provisions of
                the usage agreement .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة الخامسة - الحسابات والتزامات التسجيل :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article Five - Accounts and Registration Obligations :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <p className="my-2">
              مباشرة بعد التقدم بطلب الانضمام إلى عضوية منصة تكنولوجيا الذكاء
              الاصطناعي أو طلب تأسيس متجر على الإنترنت، يتوجب عليك إفشاء معلومات
              محددة واختيار اسم مستخدم وكلمة مرور سرية لاستخدامها عند الدخول إلى
              خدمات منصة تكنولوجيا الذكاء الاصطناعي . بعد تنشيط حسابك، ستصبح
              مستخدما لخدمات منصة تقنية الذكاء الاصطناعي، وبالتالي وافقت على
            </p>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                أن تكون مسؤولا عن الحفاظ على سرية معلومات حسابك وسرية كلمة
                المرور، وعندئذ توافق على إبلاغ منصة تقنيات الذكاء الاصطناعي فورا
                بأي إستخدام غير مرخص لمعلومات حسابك مع منصة تقنيات الذكاء
                الاصطناعي أو أي خرق آخر لمعلوماتك السرية .
              </li>
              <li className="mb-3">
                لن تكون منصة تقنيات الذكاء الاصطناعي مسؤولة بأي حال من الأحوال
                عن أي خسارة قد تتعرض لها، بشكل مباشر أو غير مباشر، سواء أكانت
                أخلاقية أو مادية، كنتيجة للكشف عن اسم المستخدم أو معلومات تسجيل
                الدخول، أو في حالة إساءة إستخدام المتجر .
              </li>
              <li className="mb-3">
                أنت ملزم أن تستخدم متجرك على الإنترنت بمفردك، بصفتك المسؤول
                كاملا عنه، وإذا كان شخص آخر يستخدمه، فهذا يعني أنك قد سمحت له
                باستخدام المتجر باسمك ولحسابك، ما لم يخبر المتجر قسم تقنيات
                الذكاء الاصطناعي بالعكس .
              </li>
              <li className="mb-3">
                أنت ملزم عند إستخدام منصة تكنولوجيا الذكاء الاصطناعي لاستخدامها
                بكل جدية ومصداقية، والالتزام بقواعد وأحكام إتفاقية الاستخدام
                والالتزام بالضوابط القانونية والتنظيمية المعمول بها في المملكة
                العربية السعودية، وأنت ملزم بتعويض منصة تكنولوجيا الذكاء
                الاصطناعي عن أي خسائر مباشرة أو غير مباشرة التي قد تتكبدها منصة
                تكنولوجيا الذكاء الاصطناعي نتيجة أي غير قانونية أو غير حقيقية أو
                الاستخدام غير المصرح به لحسابك من قبلك أو من أي شخص آخر حصل على
                مفاتيح الدخول إلى حسابك على النظام الأساسي، سواء كان ذلك لأداء
                خدمات باستخدام اسم المستخدم وكلمة المرور أو كنتيجة لإهمالك في
                الحفاظ على سرية اسم المستخدم وكلمة المرور، وسواء بتفويض منك أو
                بدونه .
              </li>
              <li className="mb-3">
                أنت ملزم بالكشف عن المعلومات الصحيحة الصحيحة والمحدثة والكاملة
                والقانونية عن نفسك كما هو مطلوب أثناء التسجيل في منصة تقنيات
                الذكاء الاصطناعي وأنك ملزم بتحديث بياناتك إذا تغيرت في الواقع أو
                إذا كانت هناك حاجة لذلك .
              </li>
              <li className="mb-3">
                عدم تضمين اسم المستخدم أي من تفاصيل جهات الاتصال الخاصة بك مثل
                عناوين البريد الإلكتروني أو أرقام الهاتف أو أي تفاصيل شخصية أو
                أي عبارة تشير إلى علاقة شخصية أو تجارية بينك وبين منصة تقنيات
                الذكاء الاصطناعي أو موظفيها أو أصحابها .
              </li>
              <li className="mb-3">
                أنك لا تضع أي شيء يدل في متجرك على أي علاقة مباشرة أو غير مباشرة
                بين المتجر ومنصة AI Techor أو إدارته، من المالكين أو الموظفين،
                لأن منصة AI Techor لا علاقة لها بما تقوم به في متجرك وليست
                مسؤولة عن نشاط متجرك .
              </li>
              <li className="mb-3">
                TechPlatform من AI ملتزم بالتعامل مع معلوماتك الشخصية وعناوين
                جهات الاتصال الخاصة بك بسرية وفقا لأحكام سياسة الخصوصية وسرية
                المعلومات المطبقة على TechPlatform من AI .
              </li>
              <li className="mb-3">
                ستكون ملزما بالحفاظ على بيانات التسجيل وتحديثها دائما للحفاظ على
                صحتها وصحتها وحديثها وإكمالها وقانونية. في إيقاف أو تجميد أو
                إلغاء عضويتك أو متجرك وحسابك على المنصة، دون المساس بحقوق المنصة
                الخاصة بشركة AI Techand، وسائلهم المشروعة لاسترداد حقوقهم وحماية
                بقية المستخدمين .
              </li>
              <li className="mb-3">
                منصة تقنية الذكاء الاصطناعي لها الحق في أي وقت لإجراء أي تحريات
                يراها ضرورية، سواء مباشرة أو من خلال طرف ثالث، وتطلب منك الإفصاح
                عن أي معلومات إضافية أو وثائق من أي حجم لإثبات هويتك أو ملكية
                أموالك أو حسابك .
              </li>
              <li className="mb-3">
                في حالة عدم التزامك بأي مما تقدم، يحق لإدارة منصة تقنية الذكاء
                الاصطناعي إيقاف أو إلغاء متجرك أو عضويتك، أو منعك من الوصول إلى
                خدمات منصة تقنية الذكاء الاصطناعي مرة أخرى. وتحتفظ أيضا بحق
                إلغاء أي حسابات أو عمليات أو حسابات غير مؤكدة أو لم يتم التحقق
                منها منذ فترة طويلة.
              </li>
            </ul>
          </>
        ) : (
          <>
            <p className="my-2">
              Immediately after applying to join the membership of the AI
              Techplatform or requesting the establishment of the online store,
              you are required to disclose specific information and choose a
              username and a secret password to be used when accessing the
              services of the AI Techplatform . After activating your account,
              you will become a user of the services of the AI Techplatform, and
              thus you have agreed to
            </p>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                To be responsible for maintaining the confidentiality of your
                account information and the confidentiality of the password ,
                and by that you agree to inform the Artificial Intelligence
                Technologies platform immediately of any unauthorized use of
                your account information with the Artificial Intelligence
                Technologies platform or any other breach of your confidential
                information .
              </li>
              <li className="mb-3">
                In no event will the Artificial Intelligence Technologies
                platform be responsible for any loss that may be incurred by
                you, directly or indirectly, moral or material, as a result of
                revealing the username or login information, or in the event of
                misuse of the store .
              </li>
              <li className="mb-3">
                You are obligated to use your online store by yourself, as you
                are fully responsible for it, and if someone else uses it, this
                means that you have authorized them to use the store in your
                name and for your account, unless the store informs the
                Department of Artificial Intelligence Technologies to the
                contrary .
              </li>
              <li className="mb-3">
                You are obligated when using the AI Techplatform to use it with
                all seriousness and credibility, to abide by the rules and
                provisions of the usage agreement and to abide by the legal and
                regulatory controls in force in the Kingdom of Saudi Arabia, and
                you are obligated to compensate the AI Techplatform for any
                direct or indirect losses that may be incurred by the AI
                Techplatform Artificial intelligence as a result of any illegal,
                unreal or unauthorized use of your account by you or by any
                other person who obtained access keys to your account on the
                platform, whether it is to perform services using the username
                and password or as a result of your negligence to maintain the
                confidentiality of the username and password, and whether With
                or without your authorization .
              </li>
              <li className="mb-3">
                You are obligated to disclose true, correct , updated, complete
                and legal information about yourself as required during
                registration with the Artificial Intelligence Technologies
                platform and you are obligated to update your data if it changes
                in reality or if there is a need to do so .
              </li>
              <li className="mb-3">
                Not to include in the username any of your contact details such
                as email addresses, phone numbers, or any personal details, or
                any phrase indicating a personal or commercial relationship
                between you and the Artificial Intelligence Technologies
                platform, its employees or owners .
              </li>
              <li className="mb-3">
                That you do not put anything indicating in your store any direct
                or indirect relationship between the store and the platform of
                AI Techor its management, owners or employees, as the platform
                of AI Techhas nothing to do with what you do in your store and
                is not responsible for the activity of your store .
              </li>
              <li className="mb-3">
                The AI Techplatform is committed to dealing with your personal
                information and contact addresses confidentially in accordance
                with the provisions of the privacy policy and confidentiality of
                information applicable to the AI Techplatform .
              </li>
              <li className="mb-3">
                You will be obligated to maintain and always update the
                registration data to keep it true, correct, current, complete
                and legal. In stopping, freezing or canceling your membership or
                your store and your account on the platform, without harming the
                rights of the platform of other AI Techand their legitimate
                means to recover their rights and protect the rest of the users
                .
              </li>
              <li className="mb-3">
                The artificial intelligence technology platform has the right at
                any time to conduct any investigations it deems necessary,
                whether directly or through a third party, and asks you to
                disclose any additional information or documents of any size to
                prove your identity or ownership of your money or your account .
              </li>
              <li className="mb-3">
                In the event that you do not comply with any of the above, the
                management of the artificial intelligence technology platform
                has the right to stop or cancel your store or membership, or
                block you from accessing the services of the artificial
                intelligence technology platform again. It also reserves the
                right to cancel any unconfirmed and unverified accounts,
                operations, or accounts that have been inactive for a long time
                .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة السادسة - الاتصالات الإلكترونية ووسائل الاتصال الرسمي :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article Six - Electronic Communications and Official Communication
              Means :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                يوافق التاجر في منصة تكنولوجيا الذكاء الاصطناعي على التواصل معه
                عبر البريد الإلكتروني، أو من خلال إدارة منصة تكنولوجيا الذكاء
                الاصطناعي التي تبث رسائل عامة إلى جميع المستخدمين أو إلى
                مستخدمين معينين عند الوصول إلى حساباتهم ضمن منصة تكنولوجيا
                الذكاء الاصطناعي . كما يوافق التاجر في منصة تكنولوجيا الذكاء
                الاصطناعي على أن جميع الاتفاقات والإعلانات والبيانات وغيرها من
                الاتصالات التي تقدم إلكترونيا تحل محل نظيراتها الكتابية، وهي حجة
                قائمة بذاتها، في تلبية الاحتياجات القانونية .
              </li>
              <li className="mb-3">
                خلال فترة عضويتك وتجارتك مع المنصة، سترسل منصة الذكاء الاصطناعي
                رسائل إلكترونية ترويجية لإعلامك بأي تغييرات أو إجراءات أو أنشطة
                إعلانية جديدة قد تضاف إلى منصة الذكاء الاصطناعي .
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                {" "}
                The trader in the AI Techplatform agrees to communicate with him
                via e-mail, or through the management of the AI Techplatform
                broadcasting general messages to all users or to specific users
                upon accessing their accounts within the AI Techplatform . The
                trader in the AI Techplatform also agrees that all agreements,
                advertisements, data and other communications that are provided
                electronically take the place of their written counterparts,
                which is a stand-alone argument, in meeting legal needs .
              </li>
              <li className="mb-3">
                {" "}
                The AI Techplatform will, during the period of your membership
                and trade with the platform, send promotional e-mails to inform
                you of any changes, procedures or new advertising activities
                that may be added to the AI Techplatform .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة السابعة - تعديلات على إتفاق الاستخدام والرسوم :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article Seven - Amendments to the Usage and Fees Agreement :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                أنت تعلم وتوافق أن منصة تقنية الذكاء الاصطناعي ستبلغك بأي تعديل
                لهذه الاتفاقية، ينص على أن التزاماتك ستتضاعف أو أن حقوقك ستتضاءل
                وفقا لأي تعديلات قد تتم على إتفاقية الاستخدام هذه .
              </li>
              <li className="mb-3">
                أنت توافق على أن منصة AI Techhas هي السلطة المطلقة وبدون تحمل
                المسؤولية القانونية لإجراء أي تعديلات أساسية أو ثانوية على هذه
                الاتفاقية، ويتم إخطار المستخدمين بهذا التعديل بأي وسيلة تقنية
                يتم توفيرها، وقد يكون ذلك عن طريق البريد الإلكتروني أو عن طريق
                بث رسالة عامة لجميع المستخدمين، يوفر منصة AI Tech وسائل تقنية
                لإظهار قبول المتجر بهذا التعديل، وهذا القبول قانوني، جاهل، وملزم
                للمتجر أحكام الاتفاق بما في ذلك التعديلات .
              </li>
              <li className="mb-3">
                في حالة الاعتراض على أي تعديل على إتفاقية الاستخدام، قد يكون هذا
                عائقا أمام الوصول إلى المتجر، بالنسبة للاستفادة من خدمات منصة
                تكنولوجيا الذكاء الاصطناعي، يجب الموافقة على هذه الاتفاقية وأي
                تعديل عليها، وبالتالي في حالة عدم قبول التعديل، تأمل منصة تقنيات
                الذكاء الاصطناعي أن تتوقف عن إستخدام خدماتها، كمجرد وصولك إلى
                حسابك على منصة تكنولوجيا الذكاء الاصطناعي أو إستخدامك لتقنية
                الذكاء الاصطناعي المنصة تعتبر قبولك بالتعديلات، والموافقة
                الكاملة والكاملة على الجهل، و منصة الذكاء الاصطناعي تسعد
                بالإجابة على استفساراتك بخصوص هذه الاتفاقية، وتتسلم أي اقتراحات
                تراها المتجر .
              </li>
              <li className="mb-3">
                منصة تقنيات الذكاء الاصطناعي ، شريطة أن تتم عملية الدفع عن طريق
                وسائل محددة معتمدة ومتوفرة من خلال منصة تكنولوجيا الذكاء
                الاصطناعي .
              </li>
              <li className="mb-3">
                ليست كل الرزم والعروض لمنصة الذكاء الاصطناعي مجانية للتجار أو
                المتاجر، فبعض هذه الرزم والعروض تكون خاضعة لرسوم متفاوتة .
              </li>
              <li className="mb-3">
                قد تفرض منصة الذكاء الاصطناعي رسوما على التجار أو المتاجر بناء
                على العروض أو الحزم التي ينضمون إليها أو السياسات التي
                يستخدمونها .
              </li>
              <li className="mb-3">
                تحتفظ منصة تقنيات الذكاء الاصطناعي بحق إضافة أو زيادة أو تخفيض
                أو خصم أي رسوم أو نفقات بموجب قواعد وأحكام إتفاقية الاستخدام،
                لأي من المستخدمين، بغض النظر عن سبب تسجيلهم .
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                {" "}
                You know and agree that the artificial intelligence technology
                platform will notify you of any amendment to this agreement,
                according to which your obligations will double or your rights
                will be diminished according to any amendments that may be made
                to this usage agreement .
              </li>
              <li className="mb-3">
                {" "}
                You agree that the platform of AI Techhas the absolute authority
                and without assuming legal responsibility to make any basic or
                secondary amendments to this agreement, and users are notified
                of this amendment by any technical means provided, and this may
                be by e-mail or by broadcasting a public message For all users,
                the platform of AI Techprovides technical means to show the
                store's acceptance of this amendment, and this acceptance is
                legal, ignorant, and binding on the store for the provisions of
                the agreement, including the amendments .
              </li>
              <li className="mb-3">
                {" "}
                In the event of objection to any amendment to the usage
                agreement, this may be an obstacle to accessing the store, as to
                benefit from the services of the AI Techplatform, this agreement
                and any amendment to it must be approved, and therefore in the
                event that the amendment is not accepted, the intelligence
                technologies platform hopes that you Stop using its services, as
                your mere access to your account on the AI Techplatform or your
                use of the AI Techplatform is considered your acceptance of the
                modifications and complete and complete approval of ignorance,
                and the AI Techplatform is happy to answer your inquiries
                regarding this agreement and receive any suggestions that the
                store sees .
              </li>
              <li className="mb-3">
                {" "}
                intelligence technologies platform , provided that payment is
                made by approved, specific means available through the AI
                Techplatform .
              </li>
              <li className="mb-3">
                {" "}
                Not all packages and offers of the artificial intelligence
                platform are free for merchants or stores, as some of these
                packages and offers are subject to varying fees .
              </li>
              <li className="mb-3">
                {" "}
                The AI platform may charge merchants or stores depending on the
                offers or packages they join or the policies they use .
              </li>
              <li className="mb-3">
                {" "}
                The Artificial Intelligence Technologies platform reserves the
                right to add, increase, reduce or deduct any fees or expenses
                under the rules and provisions of the usage agreement, for any
                of the users, whatever the reason for their registration .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة الثامنة - منصة تكنولوجيا الذكاء الاصطناعي :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article Eight - Artificial intelligence technology platform :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                وتوفر منصة AI TechPlatform، من خلال شركائها، نظام الدفع في منصة
                AI Techplatform. يمكن القيام بذلك عبر الإنترنت بالكامل من خلال
                خيارات الدفع المتاحة على منصة AI Techplatform أو من خلال أي
                طريقة دفع تقدمها منصة AI TechPlatform من وقت لآخر .
              </li>
              <li className="mb-3">
                لا توجد علاقة مع منصة AI Techwith طريقة الدفع عند الاستلام، حيث
                تخضع طريقة الدفع هذه للعلاقة بين المستهلك والتاجر ومقدم الخدمة .
              </li>
              <li className="mb-3">
                منصة تقنية الذكاء الاصطناعي قد تشترط في أي وقت وعلى أي حال أن
                عمليات الدفع تتم مباشرة بين التاجر والمستهلك، ومن خلال حساباتهم
                المصرفية، ولا علاقة بعد ذلك بمنصة تقنية الذكاء الاصطناعي .
              </li>
              <li className="mb-3">
                إن توفير منصة تقنية الذكاء الاصطناعي لخدمة الدفع عبر الإنترنت من
                خلال المنصة هو لغرض تسهيل والحفاظ على حقوق التجار والمتاجر .
              </li>
              <li className="mb-3">
                التاجر ملزم بتحديد أسعار الخدمات أو السلع التي يعرضها في متجره
                وفقا للقيمة السوقية المعترف بها، وليس لمنصة الذكاء الاصطناعي أي
                علاقة بالتقدير الخاطئ لتكلفة المنتجات أو الخدمات المقدمة في
                المتاجر على منصة الذكاء الاصطناعي، حيث أن تقديرها هو في شكل
                معترف به تجاريا. بل هو فرض على عاتق التاجر .
              </li>
              <li className="mb-3">
                التاجر ملزم بتقديم الفواتير وقسائم الاستلام وقسائم الاستلام
                لجميع المبالغ والأرباح التي تنشأ في خزانه، وهو ملزم بأن يبين في
                جميع هذه الفواتير نوع البضائع أو الخدمات وكمياتها وأوصافها
                وقيمتها. التاجر ملزم بتوفير المواصفات المحاسبيه المطلوبه في
                متجره علي الإنترنت تطبيقا لاحكام هذه الاتفاقيه وما ورد في هذه
                اللائحه من مصالح التجار القانونيه والاقتصاديه والتجاريه فاذا أخل
                التاجر باحكام هذه الفقره اعتبر مسئولا عن ايه اضرار قد تنشأ جراء
                هذه المخالفه .
              </li>
              <li className="mb-3">
                منصة تكنولوجيا الذكاء الاصطناعي لها الحق في منع اكتمال الإجراءات
                لأي عملية دفع بالمخالفة لقواعد وأحكام إتفاقية الاستخدام أو إلغاء
                أي طلب شراء أو بيع نتيجة لخطأ تقني أو تقني في المنصة أدى إلى
                أختلاف الأسعار المعروضة على المنصة عن القيمة السوقية للمنتج بما
                في ذلك خسارة منصة تقنية الذكاء الاصطناعي منصة تقنية الذكاء
                الاصطناعي ليست مسؤولة عن هذه المبالغ .
              </li>
              <li className="mb-3">
                لإدارة منصة تكنولوجيا الذكاء الاصطناعي الحق في إلغاء أو تعديل أو
                تغيير أي من طرق الدفع التي أتاحتها على منصة تكنولوجيا الذكاء
                الاصطناعي
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                {" "}
                The AI Techplatform, through its partners, provides the payment
                system in the AI Techplatform. It can be done entirely online
                through the payment options available on the AI Techplatform or
                through any payment method provided by the AI Techplatform from
                time to time .
              </li>
              <li className="mb-3">
                {" "}
                There is no relationship to the platform of AI Techwith the
                method of payment upon receipt, as this method of payment is
                subject to the relationship between the consumer, the merchant,
                and the service provider .
              </li>
              <li className="mb-3">
                {" "}
                The artificial intelligence technology platform may stipulate at
                any time and in any case that the payment operations take place
                directly between the merchant and the consumer, and through
                their own bank accounts, and there is no relationship then to
                the artificial intelligence technology platform .
              </li>
              <li className="mb-3">
                {" "}
                The provision of an artificial intelligence technology platform
                for online payment service through the platform is for the
                purpose of facilitating and preserving the rights of merchants
                and stores .
              </li>
              <li className="mb-3">
                {" "}
                The merchant is obligated to determine the price of the service
                or goods that he displays in his store according to the
                recognized market value, and the artificial intelligence
                platform has nothing to do with the misestimation of the cost of
                the products or services offered in the stores on the artificial
                intelligence platform, as its estimation is in the commercially
                recognized form. It is an obligation on the shoulders of the
                merchant .
              </li>
              <li className="mb-3">
                {" "}
                The merchant is obligated to provide invoices, receipt vouchers,
                and receipt vouchers for all amounts and profits that arise in
                his store, and he is obligated to indicate in all these invoices
                the type of goods or service, their quantities, descriptions,
                and value. Accordingly, the merchant is obligated to provide the
                required accounting specifications in his online store, in
                application of the provisions of this agreement, and for what is
                in This regulation is in the legal, economic and commercial
                interests of the merchants, and in the event that the merchant
                violates the provisions of this clause, he is considered
                responsible for any damages that may arise as a result of this
                violation .
              </li>
              <li className="mb-3">
                {" "}
                The AI Techplatform has the right to prevent the completion of
                the procedures for any payment process in violation of the rules
                and provisions of the usage agreement or to cancel any purchase
                or sale order as a result of a technical or technical error in
                the platform that led to a difference in the prices offered on
                the platform from the market value of the product, including a
                loss for the AI Techplatform The artificial intelligence
                technology platform is not responsible for these amounts .
              </li>
              <li className="mb-3">
                {" "}
                The management of the AI Techplatform has the right to cancel,
                modify or change any of the payment methods that it made
                available on the AI Techplatform{" "}
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة التاسعة - معلوماتك الشخصية ومعلومات تفاصيل المعاملات :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article Nine - Your personal information and transaction details
              information :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                ليس لديك أي اعتراض على منح منصة AI Techan حقا غير محدود وعالمي
                ودائم وغير قابل للإلغاء، معفيا من المصاريف، ومرخصا باستخدام
                المعلومات الشخصية أو المواد أو غيرها التي قدمتها أو قدمتها إلى
                المنصة أو أعلنت عنها على المنصة من خلال الانضمام إليها أو إنشاء
                متجرك من خلال النماذج المخصصة للاتصال والتسجيل ، أو من خلال أي
                بريد إلكتروني أو أي من قنوات الاتصال المتاحة على المنصة. بهدف
                تحقيق أي من المصالح التي يراها المنبر .
              </li>
              <li className="mb-3">
                أنت الوحيد المسؤول عن المعلومات التي أرسلتها أو نشرتها، ودور
                منصة تكنولوجيا الذكاء الاصطناعي يقتصر على السماح لك بعرض هذه
                المعلومات من خلال منصة تكنولوجيا الذكاء الاصطناعي ومن خلال
                قنواتها الإعلانية .
              </li>
              <li className="mb-3">منصة تقنيات الذكاء الاصطناعي .</li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                {" "}
                You have no objection to granting the platform of AI Techan
                unlimited, global, permanent and irrevocable right, exempt from
                expenses, and licensed to use personal information or materials
                or otherwise that you provided or provided to the platform or
                announced on the platform by joining it or establishing your
                store Through the forms designated for communication and
                registration , or through any e-mail or any of the communication
                channels available on the platform. With the aim of achieving
                any of the interests that the platform sees .
              </li>
              <li className="mb-3">
                {" "}
                You are the only one responsible for the information that you
                sent or published, and the role of the AI Techplatform is
                limited to allowing you to display this information through the
                AI Techplatform and through its advertising channels .
              </li>
              <li className="mb-3">
                {" "}
                Artificial Intelligence Technologies platform .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة العاشرة: تعهد التاجر بالامتثال للقوانين والأنظمة في المملكة
              العربية السعودية :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article Ten - The merchant's undertaking to comply with the laws
              and regulations in the Kingdom of Saudi Arabia :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                يتعهد التاجر بالالتزام بجميع القوانين واللوائح المعمول بها في
                المملكة العربية السعودية فيما يتعلق بمنتجاته و/أو أثناء إستخدامه
                لمنصة تكنولوجيا الذكاء الاصطناعي ، وكذلك بالقوانين والشروط
                والأحكام المعمول بها التي تنظم إستخدام مساحة الإنترنت واتفاقية
                الاستخدام وسياسة الخصوصية وسرية المعلومات المعمول بها في منصة
                تكنولوجيا الذكاء الاصطناعي .
              </li>
              <li className="mb-3">
                في حالة انتهاك أي من التجار لما تنص عليه المادة 10، فإنه يعترف
                بحق منصة تكنولوجيا الذكاء الاصطناعي في إتخاذ أي من التدابير
                التالية، بما في ذلك على سبيل المثال لا الحصر: التنبيه، ووقف
                الخدمة وإغلاق المتجر، وإعادة أي مبالغ معلقة في المدفوعات
                الإلكترونية للمستهلكين .
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                {" "}
                The merchant undertakes to comply with all applicable laws and
                regulations within the Kingdom of Saudi Arabia regarding his
                products and/or during his use of the AI Techplatform , as well
                as the applicable laws, terms and conditions regulating the use
                of Internet space, the usage agreement, the privacy policy and
                the confidentiality of information applicable to the AI
                Techplatform .
              </li>
              <li className="mb-3">
                {" "}
                In the event that any of the merchants violates what is stated
                in Article 10, he acknowledges the right of the AI Techplatform
                to take any of the following measures, including but not limited
                to: alerting, stopping the service and closing the store,
                returning any pending amounts in electronic payments to
                consumers .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة الحادية عشرة – الحقوق :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">Article Eleven - Rights :</h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                جميع المحتويات على منصة تقنيات الذكاء الاصطناعي، الإلكترونية أو
                غيرها، مكتوبة أو غير مكتوبة، بما في ذلك على سبيل المثال لا
                الحصر: النصوص المكتوبة وغير المكتوبة - تصاميم الرسومات - الأفكار
                التقنية - الشعارات - عرض الرموز - مقاطع الصوت - البيانات
                المجموعة والبرامج الإلكترونية مملوكة من قبل منصة تقنيات الذكاء
                الاصطناعي وحقوقها محفوظة من قبل منصة تقنيات الذكاء الاصطناعي،
                ولا يحق لأحد إستخدامها بأي طريقة، سواء إستخدام مباشر أو غير
                مباشر أو من خلال طرف ثالث .
              </li>
              <li className="mb-3">
                وتشير إدارة منصة تكنولوجيا الذكاء الاصطناعي إلى أنها ستتخذ
                التدابير اللازمة للالتفاف على أي من حقوق منصة تكنولوجيا الذكاء
                الاصطناعي أو ملكيتها الفكرية وانتهاكها .
              </li>
              <li className="mb-3">
                المنصة الخاصة بشركة AI Techis غير مسؤولة في حالة انتهاك حقوق
                الملكية الفكرية التي يملكها التجار الذين هم أعضاء في المنصة أو
                المتاجر المسجلة لديها .
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                {" "}
                All content on the platform of artificial intelligence
                technologies, electronic or otherwise, written or unwritten,
                including but not limited to: written and unwritten texts –
                graphic designs – technical ideas – logos – offers – button
                icons – symbols – audio clips – data The collected and
                electronic programs are owned by the Artificial Intelligence
                Technologies Platform and its rights are reserved by the
                Artificial Intelligence Technologies Platform, and no one has
                the right to use it in any way, whether it is a direct or
                indirect use or through a third party .
              </li>
              <li className="mb-3">
                {" "}
                The management of the AI Techplatform notes that it will take
                the necessary measures to bypass and infringe any of the rights
                of the AI Techplatform or its intellectual property .
              </li>
              <li className="mb-3">
                {" "}
                The platform for AI Techis not responsible in the event of
                infringement of the intellectual property rights owned by the
                merchants who are members of the platform or the stores
                registered with it .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة الثانية عشرة - الملكية الفكرية :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article Twelve - Intellectual Property :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                إن إدارة منصة تقنية الذكاء الاصطناعي تحترم حقوق الملكية الفكرية
                للتجار الذين بنوها في محلاتهم، سواء كانوا مملوكين لهم قبل أو بعد
                إنشاء المتجر .
              </li>
              <li className="mb-3">
                يحترم التاجر حقوق الملكية الفكرية لمنصة تكنولوجيا الذكاء
                الاصطناعي، والتي تشمل منصة تكنولوجيا الذكاء الاصطناعي نفسها،
                والكلمات والشعارات والرموز الأخرى لمنصة تكنولوجيا الذكاء
                الاصطناعي أو المعروضة على منصة تكنولوجيا الذكاء الاصطناعي، مثل
                منصة تكنولوجيا الذكاء الاصطناعي، وكل حق ينتمي إلى منصة تقنيات
                الذكاء الذكاء الذكاء الاصطناعي، هي حقوق محمية بموجب قوانين حقوق
                الملكية الفكرية والعلامات التجارية وهي ملكية حصرية للملكية
                الفكرية TechPlatform ولا يحق بأي حال من الأحوال التعدي عليها أو
                إستخدامها دون ترخيص من إدارة منصة AI TechPlatform .
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                {" "}
                The management of the artificial intelligence technology
                platform respects the intellectual property rights of the
                merchants that they have built through their stores, whether
                they are owned by them before or after the establishment of the
                store .
              </li>
              <li className="mb-3">
                {" "}
                The merchant respects the intellectual property rights of the AI
                Techplatform, which includes the AI Techplatform itself, and the
                words, logos and other symbols of the AI Techplatform or
                displayed on the AI Techplatform, as the AI Techplatform, and
                every right belongs to the intelligence technologies platform
                Artificial intelligence, are rights protected by intellectual
                property rights and trademark laws and are the exclusive
                property of the AI Techplatform and are not entitled in any way
                to be infringed or used without authorization from the
                management of the AI Techplatform .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة الثالثة عشرة - المستهلك
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">Article Thirteen - Consumer</h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                أثناء إتمام عمليات الشراء، يجب على المستهلك تزويد منصة الذكاء
                الاصطناعي بالمعلومات التالية، بما في ذلك على سبيل المثال لا
                الحصر: الاسم - البريد الإلكتروني - الهاتف .
              </li>
              <li className="mb-3">
                وتحتفظ أنظمة منصة تكنولوجيا الذكاء الاصطناعي ببيانات المستهلك من
                أجل تسهيل وصولهم إلى المنصة واستكمال عمليات الشراء التي يرغبون
                فيها .
              </li>

              <p className="font-bold py-3">
                من أجل مصلحة منصة تكنولوجيا الذكاء الاصطناعي حول حقوق
                المستهلكين، على الرغم من أن المستهلك ليس طرفا في هذه الاتفاقية،
                ولتحقيق أحد أهم أهداف منصة تكنولوجيا الذكاء الاصطناعي لتوفير
                خدمة عالية الجودة للجميع، ومن أجل ضمان أن المستهلكين يتلقون جميع
                مشترياتهم بشكل صحيح، توضح منصة تكنولوجيا الذكاء الاصطناعي للمتجر
                التالي :
              </p>

              <li className="mb-3">
                إذا اشترى المستهلك سلعا بأحد طرق السداد المتاحة في منصة AI
                Techplatform ولم يتسلم بضائعه أو تسلم سلعا تختلف عن المواصفات
                المذكوره في صفحة المتجر فيحق للمستهلك إبلاغ منصة AI TechPlatform
                ويحق ل AI TechPlatform إتخاذ ما تراه مناسبا بشأن المتجر الذي
                خالف قواعد هذه الاتفاقيه وفقا لقواعد هذه الاتفاقيه. ومع ذلك، فإن
                منصة AI Techis ليست مسؤولة قانونيا أو قانونيا عن هذا الخرق من
                قبل التاجر في مواجهة المستهلك، كما أنها ليست طرفا في العلاقة بين
                المستهلك والتاجر، ولكنها تفعل ذلك لأسباب تحسين نوعية منصة AI
                Techand التجار الموجودين على المنصة .
              </li>
              <li className="mb-3">
                للمستهلك ان يراجع مشترياته قبل تسلمها للتأكد من سلامتها
                ومطابقتها لما تم شراؤه ، حيث ان منبر تقنيه الذكاء الاصطناعي ليس
                مسئولا عن العلاقه بين المستهلك والتاجر .
              </li>
              <li className="mb-3">
                المنصة الخاصة ب AI Techis ليست ملزمة بأي شكل من الأشكال، وفقا
                لهذه المادة وهذه الفقرة، أن تأخذ رد فعل على شكوى المستهلك. وبدلا
                من ذلك، فإن رد فعل المنصة على شكوى المستهلك يخضع للرغبة الخالصة
                لمنصة AI Techand وفقا لما يراه المنبر مناسبا، وفقا لقواعد هذه
                الاتفاقية. ومن أجل الحفاظ على مصلحتها التجارية والاقتصادية
                وسمعتها، على أي حال، قد تتخذ منصة آي تي تيك بعض التدابير ضد
                المتاجر التي تعمد خداع أو تضليل أو خداع المستهلك، مثل: تجميد
                عضوية المتجر أو لفت انتباه المتجر إلى عدم تكرار هذا الأمر أو
                إلزام المتجر بتعويض المستهلك وهذا يستند إلى رغبة منصة آي تي تي
                تي تي تي (AI Techplatform) في الحفاظ على حقوق جميع المستخدمين
                وحماية حقوقهم والمسجلين معه .
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                {" "}
                During the completion of purchases, the consumer shall provide
                the artificial intelligence platform with the following
                information, including but not limited to: name - email - phone
                .
              </li>
              <li className="mb-3">
                {" "}
                The systems of the artificial intelligence technology platform
                maintain consumer data in order to facilitate their access to
                the platform and the completion of the purchase operations they
                desire .
              </li>

              <p className="font-bold py-3">
                In the interest of the AI Techplatform on the rights of
                consumers, although the consumer is not a party to this
                agreement, and to achieve one of the most important goals of the
                AI Techplatform to provide high quality service to everyone, and
                in order to ensure that consumers receive all their purchases
                properly, the AI Techplatform Explain to the following store :
              </p>

              <li className="mb-3">
                {" "}
                In the event that the consumer purchased goods with one of the
                payment methods available on the AI Techplatform , and did not
                receive his goods or that he received goods that differ from the
                specifications mentioned on the store page, then the consumer
                has the right to inform the AI Techplatform and the AI
                Techplatform has the right to take What you deem appropriate
                about the store that violated the rules of this agreement, in
                accordance with the rules of this agreement. However, the
                platform for AI Techis not legally or legally responsible for
                this breach by the merchant in the face of the consumer, as it
                is not a party to the relationship between the consumer and the
                merchant, but it does so for reasons of improving the quality of
                the platform for AI Techand the merchants present on the
                platform .
              </li>
              <li className="mb-3">
                {" "}
                The consumer may review his purchases before receiving them to
                ensure that they are intact and identical to what was purchased,
                as the artificial intelligence technology platform is not
                responsible for the relationship between the consumer and the
                merchant .
              </li>
              <li className="mb-3">
                {" "}
                The platform for AI Techis not obligated in any way, according
                to this article and this clause, to take a reaction to the
                consumer complaint. Rather, the platform's reaction to the
                consumer complaint is subject to the pure desire of the platform
                for AI Techand according to what the platform deems appropriate,
                in accordance with the rules of this agreement. And in order to
                preserve its commercial and economic interest and reputation, in
                any case, the platform of AI Techmay take some measures against
                stores that deliberately deceive, mislead or deceive the
                consumer, such as: freezing the store’s membership or drawing
                the store’s attention not to repeat this matter or obliging the
                store to compensate the consumer This is based on the desire of
                the AI Techplatform to preserve and protect the rights of all
                users and those registered with it .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة الرابعة عشرة - مسؤولية منصة تكنولوجيات الذكاء الاصطناعي :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article Fourteen - Responsibility of the Artificial Intelligence
              Technologies Platform :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                المنصة ليست مسؤولة بأي شكل من الأشكال عن أي أداء غير مرض أو
                متأخر متاجر، شركات الشحن، بوابات الدفع الإلكترونية، المصارف، أو
                عجز المستهلكين عن الدفع، ولا عن أي خسائر، أو أعطال، أو تأخيرات،
                بسبب البضائع غير المتوفرة، أو تأخر التسليم، أو نقص جودة الخدمة
                التي تقدمها المتاجر .
              </li>
              <li className="mb-3">
                لا يتحمل برنامج تقنيات الذكاء الاصطناعي أية مطالبات ناجمة عن
                أخطاء أو إغفالات، سواء كانت ناجمة بشكل مباشر أو غير مباشر أو
                عرضي أو من طرف ثالث.
              </li>
              <li className="mb-3">
                لا تتحمل منصة تكنولوجيا الذكاء الاصطناعي أي مطالبات أو مسؤوليات
                تنشأ نتيجة لخسائر مالية، أو التشهير، أو الافتراء، أو أي أضرار
                ناجمة عن سوء الاستخدام، أو سوء الاستخدام، أو عدم القدرة على
                إستخدام منصة تكنولوجيا الذكاء الاصطناعي. لا تتحمل منصة تقنية
                الذكاء الاصطناعي أي مسؤوليات أو مطالبات في هذه الحالات .
              </li>
              <li className="mb-3">
                لا تتحمل منصة تقنيات الذكاء الاصطناعي ولا مسؤوليها ولا مستخدميها
                ولا اصحابها أي دعاوى أو نزاعات أو تكاليف أو اضرار أو ايه مسئوليه
                أو أي خساره مباشره أو غير مباشره لاي طرف من الاطراف بسبب فعل
                يقوم به مستخدم المنصة .
              </li>
              <li className="mb-3">
                إن منصة تقنية الذكاء الاصطناعي، والشركات التابعة لها، ومالكيها،
                ومن يمثلونهم ويمثلونهم، لا علاقة لها بأي مسؤولية تتعلق بمنتج
                سليم وشرعي ومعتمد وفقا لقوانين وأنظمة المملكة العربية السعودية،
                وتستخدم لأغراض غير قانونية وغير قانونية، ولا يحق لأي شخص أو كيان
                الرجوع إلى منصة تكنولوجيا الذكاء الاصطناعي في أي دعوى قضائية أو
                مطالبة أو تعويض فيما يتعلق بسوء الاستخدام المذكور، كما جميع
                المنتجات والخدمات المقدمة على منصة الذكاء الاصطناعي الإلكترونية
                تقدم من قبل التجار ومحلاتهم .
              </li>
              <li className="mb-3">
                لا علاقة لمنصة "أي تي" التقنية وموظفيها ومالكيها ومن يمثلها بأي
                نشاط غير قانوني أو غير نظامي يقوم به المحل، أو أي نشاط يخالف
                الأنظمة والتعليمات المعمول بها في المملكة العربية السعودية، حيث
                تقتصر مسؤولية منصة "أي إي تي" التقنية على تقديم وتقديم أدوات
                الدعم الإلكتروني المتمثلة في إنشاء المحل عبر الإنترنت وخدمات
                الدعم الإلكتروني اللاحقة له .
              </li>
              <li className="mb-3">
                تبلغ إدارة منصة تكنولوجيا الذكاء الاصطناعي جميع التجار بأنها إذا
                لاحظت أي أنشطة مشبوهة يجري التخطيط لها أو يجري إرتكابها أو تمت
                على منصة تكنولوجيا الذكاء الاصطناعي، فإنها ستتقدم لإبلاغ السلطات
                المختصة عن هذه الأنشطة. وهي ليست مسؤولة بأي شكل من الأشكال عن
                هذه الانتهاكات التي تحدث دون علم أو مراقبة من منصة تكنولوجيا
                الذكاء الاصطناعي .
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                {" "}
                platform is not responsible in any way for any unsatisfactory or
                delayed performance Stores, shipping companies, electronic
                payment gateways, banks, or consumers' failure to pay, nor for
                any losses, malfunctions, or delays, due to unavailable goods,
                delayed delivery, or lack of quality of service provided by
                stores .
              </li>
              <li className="mb-3">
                {" "}
                The Artificial Intelligence Technologies platform does not bear
                any claims arising from errors or omissions, whether caused
                directly, indirectly, incidentally, or by a third party .
              </li>
              <li className="mb-3">
                {" "}
                The artificial intelligence technology platform does not bear
                any claims or liabilities arising as a result of financial
                losses, defamation, slander, or any damages arising as a result
                of misuse, misuse, or inability to use the artificial
                intelligence technology platform. The artificial intelligence
                technology platform does not bear any responsibilities or claims
                In such cases .
              </li>
              <li className="mb-3">
                {" "}
                The Artificial Intelligence Technologies platform, its
                officials, employees, or owners are not responsible for any
                claim, dispute, costs, damages, or any liability or any direct
                or indirect loss to any party arising from an act carried out by
                a user of the platform .
              </li>
              <li className="mb-3">
                {" "}
                The AI Techplatform, its affiliates, owners, and those who
                represent and represent them have nothing to do with any
                responsibility related to a sound, legitimate , and authorized
                product in accordance with the laws and regulations of the
                Kingdom of Saudi Arabia, and it is used for illegal and
                irregular purposes, and no person or entity has the right to
                refer to the AI Techplatform in any lawsuit Or a claim or
                compensation in connection with the aforementioned misuse, as
                all products or services offered on the artificial intelligence
                electronic technologies platform are provided by merchants and
                their stores .
              </li>
              <li className="mb-3">
                {" "}
                The AI Techplatform, its employees, owners, and whoever
                represents it and represents them has nothing to do with any
                illegal or irregular activity carried out by the store, or any
                activity that violates the regulations and instructions in force
                in the Kingdom of Saudi Arabia, where the responsibility of the
                AI Techplatform is limited only to providing and providing
                electronic support tools represented in In establishing the
                online store and the subsequent electronic support services .
              </li>
              <li className="mb-3">
                {" "}
                The management of the artificial intelligence technology
                platform informs all merchants that if it notices any suspicious
                activities that are being planned, are being committed, or have
                taken place on the artificial intelligence technology platform,
                it will advance to inform the competent authorities about these
                activities. It is not responsible in any way for these
                violations that occur without the knowledge or observation of
                the AI Techplatform .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة الخامسة عشرة – سرية المعلومات:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              المادة الخامسة عشرة - سرية المعلومات :{" "}
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                تخبرك منصة تقنيات الذكاء الاصطناعي أن الشبكة العنكبوتية
                (الإنترنت) ليست وسيلة آمنة، ولا يمكن ضمان سرية المعلومات الشخصية
                بنسبة 100٪ في فضاء الإنترنت .
              </li>
              <li className="mb-3">
                تعتمد المنصة معايير الذكاء الاصطناعي (الملموسة ، التنظيمية
                والتقنية) ذات الجودة العالية لحماية المستخدمين والمستهلكين ومنع
                الوصول غير المصرح به إلى المعلومات الشخصية للمستخدمين أو
                متاجرهم، وحفظها .
              </li>
              <li className="mb-3">
                منصة AI Tech ليس لديها سيطرة على أعمال أي طرف ثالث، أو أطراف
                ثالثة، مثل صفحات الويب الأخرى المرتبطة بصلات مع المنصة أو أطراف
                ثالثة تدعي أنها تمثلك أنت والآخرين .
              </li>
              <li className="mb-3">
                أنت تعرف وتوافق على أن منصة تقنية الذكاء الاصطناعي قد تستخدم
                معلوماتك التي تقدمها، بغرض تقديم الخدمات لك في منصة تقنيات
                الذكاء الاصطناعي، ولإرسال رسائل تسويقية لك، وأن سياسة الخصوصية
                لمنصة تقنية الذكاء الاصطناعي تحكم جمع ومعالجة واستخدام ونقل
                معلومات هويتك الشخصية، وتخضع لقواعد سرية المعلومات ل "سياسة
                الخصوصية وسرية المعلومات" - حلقة الوصل بين منصة تكنولوجيات
                الذكاء الاصطناعي .
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                {" "}
                The Artificial Intelligence Technologies platform informs you
                that the World Wide Web (Internet space ) is not a secure
                medium, and the confidentiality of personal information cannot
                be guaranteed 100% in the Internet space .
              </li>
              <li className="mb-3">
                {" "}
                The platform adopts AI Tech(tangible , organizational and
                technical) standards of high quality to protect users and
                consumers and prevent unauthorized access to users' personal
                information or their stores, and save them .
              </li>
              <li className="mb-3">
                {" "}
                The platform of AI Techhas no control over the actions of any
                third party, or third parties, such as other web pages linked by
                links to the platform or third parties claiming to represent you
                and others .
              </li>
              <li className="mb-3">
                {" "}
                You know and agree that the platform of AI Techmay use your
                information that you provide it with, for the purpose of
                providing services to you in the platform of artificial
                intelligence technologies, and to send you marketing messages,
                and that the privacy policy of the platform of AI Techgoverns
                the collection, processing, use and transfer of your personal
                identification information, and is subject to Information
                confidentiality rules for the “Privacy Policy and
                Confidentiality of Information - Link” of the Artificial
                Intelligence Technologies platform .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة السادسة عشرة - سياسة الإحلال والاسترداد :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article Sixteen - Replacement and Refund Policy :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                من الممكن إلغاء الاشتراك في حزمة (الذكاء الاصطناعي زائد، الذكاء
                الاصطناعي Pro) عندما يتم تنشيطها لأول مرة، وإعادة رسوم الاشتراك
                في غضون 14 يوما من صلاحية الاشتراك، باستثناء حزمة (الذكاء
                الاصطناعي الخاص Artificial Intelligence Special Technologies).
              </li>
              <li className="mb-3">
                سيتم تجديد الحزم المدفوعة تلقائيا عند تنشيط خيار التجديد
                التلقائي وتوفر بطاقة ائتمان مضافة، أو وجود رصيد متوفر في
                المحفظة.
              </li>
              <li className="mb-3">
                عند تنشيط خيار تجديد الاشتراك التلقائي، سيتم سحب الرصيد تلقائيا
                بمجرد إصدار فاتورة الاشتراك الجديدة. (الذكاء الاصطناعي زائد
                والذكاء الاصطناعي Pro) يمكن تعليقها لمدة أقصاها 30 يوما دون
                رسوم، شريطة ألا يتجاوز عدد حالات التعليق مرتين في السنة.
              </li>
              <li className="mb-3">
                عند إلغاء الاشتراك في إحدى الحزم المدفوعة، يمكنك الاستمرار في
                إستخدام الحزمة والاستفادة من فوائدها حتى تاريخ انتهاء الاشتراك.
              </li>
              <li className="mb-3">
                في حالة إلغاء أي حزمة لأول مرة، سيتم فرض رسوم تحويل على حسابك
                البنكي تصل إلى 8.05 ريالات، بما في ذلك الضريبة، بالإضافة إلى أي
                رسوم أخرى تفرض لتحقيق ذلك.
              </li>
              <li className="mb-3">
                في حالة سحب رصيد من المحفظة، سيتم فرض رسوم تحويل على حسابك
                البنكي تصل إلى 8.05 ريال، بما في ذلك الضريبة، بالإضافة إلى أي
                رسوم أخرى تفرض لتحقيق ذلك.
              </li>
              <li className="mb-3">يمكنك سحب الأموال من محفظتك كل 14 يوما.</li>
              <li className="mb-3">
                لا يمكن سحب نقاط الولاء إلى حسابك الشخصي. يمكنك إستخدام نقاط
                الولاء لتجديد الحزم.
              </li>
              <li className="mb-3">
                تاريخ إستحقاق نقاط الولاء هو 14 يوما بعد إستخدام التاجر الثاني
                للقسيمة.
              </li>
              <li className="mb-3">
                لا يمكن إلغاء قوالب تصميم المتجر أو إستبدالها. بالنسبة لحسابات
                المستخدمين وفروعهم المشتراة، فهي نفس فترة صلاحية الاشتراك. عند
                شراء حساب مستخدم أو فرع، سيتم حساب تكلفة الحساب وفقا للفترة
                المتبقية من اشتراك الحزمة.
              </li>
              <li className="mb-3">
                مدة صلاحية الرسائل التي تم شراؤها من المتجر هي سنة واحدة من
                تاريخ الشراء.
              </li>
              <li className="mb-3">
                في حالة عدم التجديد في غضون 5 أيام من انتهاء الحزمة، لن يكون
                التاجر قادرا على الاستفادة من أي من خدمات المنصة حتى يتم إعادة
                تنشيط الحزمة.
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                {" "}
                It is possible to cancel the subscription to the (Artificial
                Intelligence Plus, Artificial Intelligence Pro ) package when it
                is activated for the first time, and to refund the subscription
                fee within 14 days of the subscription’s validity, with the
                exception of the (Artificial Intelligence Special Technologies )
                package.
              </li>
              <li className="mb-3">
                {" "}
                The paid packages will be automatically renewed when the
                auto-renew option is activated and an added credit card is
                available, or there is an available balance in the wallet.
              </li>
              <li className="mb-3">
                {" "}
                When activating the automatic subscription renewal option, the
                balance will be withdrawn automatically as soon as the new
                subscription invoice is issued. ( Artificial Intelligence Plus
                and Artificial Intelligence Pro ) can be suspended for a maximum
                period of 30 days without fees, provided that the number of
                suspensions does not exceed two times per year.
              </li>
              <li className="mb-3">
                {" "}
                When you unsubscribe from one of the paid packages, you can
                continue to use the package and benefit from its benefits until
                the subscription end date.
              </li>
              <li className="mb-3">
                {" "}
                In the event that any package is canceled for the first time, a
                transfer fee will be charged to your bank account amounting to
                8.05 riyals, including tax, in addition to any other fees
                imposed in order to achieve this.
              </li>
              <li className="mb-3">
                {" "}
                In the event that a balance is withdrawn from the wallet, a
                transfer fee will be charged to your bank account amounting to
                8.05 riyals, including tax, in addition to any other fees
                imposed in order to achieve this.
              </li>
              <li className="mb-3">
                {" "}
                You can withdraw funds from your wallet every 14 days.
              </li>
              <li className="mb-3">
                {" "}
                Loyalty points cannot be withdrawn to your personal account. You
                can use loyalty points to renew packages.
              </li>
              <li className="mb-3">
                {" "}
                The loyalty points maturity date is 14 days after the second
                merchant used the coupon.
              </li>
              <li className="mb-3">
                {" "}
                Shop design templates cannot be canceled or replaced. for
                purchased user accounts and branches is the same as the
                subscription validity period. When purchasing a user account or
                branch, the cost of the account will be calculated according to
                the remaining period of the package subscription.
              </li>
              <li className="mb-3">
                {" "}
                The validity period for messages purchased for the store is one
                year from the date of purchase.
              </li>
              <li className="mb-3">
                {" "}
                In the event of non-renewal within 5 days of expiry of the
                package, the merchant will not be able to benefit from any of
                the platform's services until the package is reactivated.
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة السابعة عشرة - إلغاء العضوية أو المتجر :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article Seventeen - Cancellation of Membership or Store :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <p className="my-3 font-semibold">
              يجوز لمنصة تقنيات الذكاء الاصطناعي، وفقا لاتفاقية الاستخدام ووفقا
              للقوانين والأنظمة المعمول بها في المملكة العربية السعودية، أن تلجأ
              إلى التعليق المؤقت أو الدائم للمتجر، وسحب عضوية التاجر وإلغائها،
              أو الحد من إمكانيات التجار للوصول إلى خدمات منصة تقنيات الذكاء
              الاصطناعي، في حال :
            </p>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">مخالفه قواعد واحكام إتفاق الاستخدام .</li>
              <li className="mb-3">
                إذا تعذر على النظام الأساسي ل AI مصادقة أي من معلومات المستخدم
                المرسلة إليه .
              </li>
              <li className="mb-3">
                إذا قررت منصة الذكاء الاصطناعي أن أنشطة المستخدم تنتهك القانون
                أو أن أنشطة المستخدم قد تتسبب في مشاكل للمستخدمين الآخرين أو
                منصة تقنية الذكاء الاصطناعي أو في مخالفات قانونية .
              </li>
              <li className="mb-3">
                قد يلجأ برنامج AI Techor "وفقا لتقييمه" إلى إعادة تنشيط
                المستخدمين المعلقين، لأن المستخدم الذي تم تعليق نشاطه بشكل دائم
                أو تم إلغاء عضويته قد لا يتمكن من تسجيل أو إستعادة حسابه في منصة
                AI Techor يستخدم المنصة بأي طريقة مهما كانت الظروف. حتى يسمح لها
                بإعادة تنشيطها على منصة تقنية الذكاء الاصطناعي من قبل إدارة منصة
                تكنولوجيا الذكاء الاصطناعي. ومع ذلك، في حالة انتهاك إتفاق
                الاستخدام هذا، تحتفظ منصة تقنية الذكاء الاصطناعي بحق إسترداد أو
                المطالبة بأي مبالغ مستحقة ل AI Techplatform من المستخدم وأي
                خسائر أو أضرار يتسبب فيها التاجر إلى منصة تقنيات الذكاء
                الاصطناعي. من حق منصة آي تي تيك إتخاذ إجراءات قانونية أو اللجوء
                إلى السلطات القضائية في المملكة العربية السعودية ضد المستخدم
                باعتباره المنصة المناسبة لتكنولوجيا أي إي .
              </li>
              <li className="mb-3">
                لا تتنازل منصة تقنية الذكاء الاصطناعي عن حقها في إتخاذ الإجراءات
                المناسبة فيما يتعلق بأي انتهاك يحدث لقواعد وأحكام إتفاقية
                الاستخدام وغيرها من الأفعال المشابهة للانتهاك، فضلا عن أن منصة
                AI Techis ليست ملزمة باتخاذ أي تدابير ضد أي انتهاك لاتفاقية
                الاستخدام يحدث، ولكن هذا الأمر يخضع لتقدير إدارة منصة تقنية
                الذكاء الاصطناعي وإدارتها القانونية
              </li>
            </ul>
          </>
        ) : (
          <>
            <p className="my-3 font-semibold">
              The platform of artificial intelligence technologies, according to
              the usage agreement and in accordance with the laws and
              regulations in force in the Kingdom of Saudi Arabia, may resort to
              temporary or permanent suspension of the store, withdrawal and
              cancellation of the merchant’s membership, or limiting the
              merchants’ capabilities to access the services of the platform of
              artificial intelligence technologies, in the event of :
            </p>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                {" "}
                Violation of the rules and provisions of the usage agreement .
              </li>
              <li className="mb-3">
                {" "}
                If the AI platform cannot authenticate any of the user
                information submitted to it .
              </li>
              <li className="mb-3">
                {" "}
                If the artificial intelligence platform determines that the
                user's activities are in violation of the law or that the user's
                activities may cause other users or the artificial intelligence
                technology platform in trouble or legal violations .
              </li>
              <li className="mb-3">
                {" "}
                The platform of AI Techmay resort “according to its assessment”
                to reactivate the suspended users, as the user whose activity
                has been permanently suspended or whose membership has been
                canceled may not be able to register or restore his account in
                the platform of AI Techor use the platform in any way
                whatsoever, whatever the circumstances. Until it is allowed to
                re-activate it on the AI Techplatform by the management of the
                AI Techplatform. Nevertheless, the user, in the event of a
                violation of this usage agreement, the AI Techplatform reserves
                the right to recover or claim any amounts due to the AI
                Techplatform from the user and any losses or Damages caused by
                the merchant to the platform of artificial intelligence
                technologies. The platform of AI Techhas the right to take legal
                action or resort to the judicial authorities in the Kingdom of
                Saudi Arabia against the user as the platform of AI Techdeems
                appropriate .
              </li>
              <li className="mb-3">
                {" "}
                The platform of AI Techdoes not waive its right to take
                appropriate action regarding any violation that occurs of the
                rules and provisions of the usage agreement and other similar
                acts of violation, as well as that the platform of AI Techis not
                obligated to take any measures against any violation of the
                usage agreement that occurs, but this matter is subject to To
                appreciate the management of the AI Techplatform and its legal
                management
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة الثامنة عشرة - عمليات الدفع والبيع والشراء :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article Eighteen - Payment, Sale and Purchase Operations :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                التاجر ملزم بإدارة محله جيدا بطريقة تحفظ المستهلك ولا تخلق خلافا
                بينه وبين المستهلك .
              </li>
              <li className="mb-3">
                التاجر ملزم بادارة عمليات الدفع والبيع والشراء التي تتم في متجره
                عن طريق طرق الدفع المحدده في هذه الاتفاقيه .
              </li>
              <li className="mb-3">
                منصة تكنولوجيا الذكاء الاصطناعي لها الحق في رفض أو إلغاء
                المشتريات، سواء تم الدفع أم لا، مع التزام منصة تكنولوجيا الذكاء
                الاصطناعي بإبلاغ المستخدم في غضون خمسة أيام عن أي من طرق الاتصال
                المتاحة ل AI TechPlatform .
              </li>
              <li className="mb-3">
                منصة تكنولوجيا الذكاء الاصطناعي تلاحظ أنها لا تمت بصلة إلى أي
                نزاع قد ينشأ بين المستهلك والتاجر، منصة تقنية الذكاء الاصطناعي
                لا علاقة لها بأي تخلف من المستهلك تجاه المتجر، سواء كان افتراضيا
                أو غير ذلك .
              </li>
              <li className="mb-3">
                التاجر يوافق ويقر بانه لن يقوم بمشتريات زائفة أو متلاعب علي منبر
                تكنولوجيا الذكاء الاصطناعي ، ولن يستخدم اسما زائفا أو ايه
                معلومات شخصيه مزوره ، أو يستخدم بطاقه ائتمان لا تخصه دون اذن
                شراء ، حيث ان منبر تقنيه الذكاء الاصطناعي له الحق في إتخاذ
                الاجراءات القانونيه المناسبه ضد أي شخص يقوم بعمليات احتياليه من
                هذا النوع .
              </li>
              <li className="mb-3">
                ويعترف جميع التجار والمحلات بعلمهم التام والجهل بأن إدارة منصة
                أي تي كبانت في تحويل الأموال لنشاطهم التجاري ضمن منصة أي تي باد
                تخضع لسلطة وصلاحيات مؤسسة النقد في المملكة العربية السعودية،
                ونتيجة لذلك، تحذر منصة أي أي تي كبنج جميع المحال والتجار من أنها
                قد تتأخر في تحويل بعض الأموال، نتيجة لقيود النقد الوكالة ووزارة
                التجارة والاستثمار وأنظمة التجارة الإلكترونية المفروضة على
                عمليات البيع والشراء الإلكتروني .
              </li>
              <li className="mb-3">
                وتحتفظ منصة AI Tech بالحق في فرض أي رسوم على التجار أو المتاجر
                نتيجة لعملياتهم ضمن منصة AI Tech، وقد تكون الرسوم، على سبيل
                المثال لا الحصر، على سبيل المثال: رسوم الوكالات الحكومية،
                والرسوم المصرفية، والرسوم الإدارية، أو أي رسوم أخرى، والتي تطالب
                بها منصة الدفع AI Techartificial نتيجة لنشاط التاجر أو المتجر .
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                The merchant is obligated to manage his store well , in a way
                that preserves the consumer and does not create a dispute
                between him and the consumer .
              </li>
              <li className="mb-3">
                The merchant is obligated to manage the payment, sale and
                purchase operations that take place in his store through the
                payment methods specified in this agreement .
              </li>
              <li className="mb-3">
                The AI Techplatform has the right to refuse or cancel purchases,
                whether payment has been made or not, with the commitment of the
                AI Techplatform to inform the user within five days of any of
                the communication methods available to the AI Techplatform .
              </li>
              <li className="mb-3">
                The artificial intelligence technology platform notes that it
                has nothing to do with any dispute that may arise between the
                consumer and the merchant, and the artificial intelligence
                technology platform has nothing to do with any default from the
                consumer towards the store, whether by default or otherwise .
              </li>
              <li className="mb-3">
                The merchant agrees and acknowledges that he will not make false
                or manipulative purchases on the artificial intelligence
                technology platform , and will not use a false name or any false
                personal information, or use a credit card that does not belong
                to him without authorization to purchase, as the artificial
                intelligence technology platform has the right to Take
                appropriate legal measures against anyone who carries out
                fraudulent operations of this kind .
              </li>
              <li className="mb-3">
                All merchants and all stores acknowledge their full and ignorant
                knowledge that the management of the AI Techplatform in
                transferring funds for their commercial activities within the AI
                Techplatform is subject to the authority and powers of the
                Monetary Agency in the Kingdom of Saudi Arabia, and as a result,
                the AI Techplatform warns all stores and merchants that it may
                be delayed In transferring some funds, as a result of the
                restrictions of the Monetary Agency, the Ministry of Commerce
                and Investment, and the e-commerce regulations imposed on
                electronic buying and selling operations .
              </li>
              <li className="mb-3">
                The platform of AI Techreserves the right to impose any fees on
                merchants or stores as a result of their operations within the
                platform of AI Tech, and the fees may be, for example, but not
                limited to: government agency fees, bank fees, administrative
                fees, or any other fees, which are claimed by the platform of AI
                Techartificial payment as a result of the activity of the
                merchant or store .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة التاسعة عشرة - المحتويات والمنتجات المحظورة :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article Nineteen - Prohibited Contents and Products :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                كتاجر على منصة تقنيات الذكاء الاصطناعي، أنت ملزم بعدم السماح
                للإعلان أو النشر على مخزنك لأي محتوى ينتهك سياسة الخصوصية وسرية
                المعلومات أو ينتهك قواعد وأحكام إتفاقية الاستخدام هذه .
              </li>
              <li className="mb-3">
                تحظر منصة تقنيات الذكاء الاصطناعي بشدة بيع المنتجات والخدمات
                التالية، بما في ذلك على سبيل المثال لا الحصر: أي منتج/خدمة تحتوي
                على القمار، أو صناديق الحظ أو منتج عشوائي، أو أي منتج/خدمة قد
                تؤدي إلى أي ضرر جسدي أو نفسي بأي طريقة، أو أي محتويات مقرصنة أو
                اشتراكات، أو أي شيء ينتهك حقوق الملكية الفكرية وحقوق النشر، أو
                أي منتج/خدمة جنسية أو إباحية، أو يدعو إلى ذلك .
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                As a merchant on the platform of artificial intelligence
                technologies, you are obligated not to allow advertising or
                publication on your store of any contents that violate the
                privacy policy and confidentiality of information or violate the
                rules and provisions of this usage agreement .
              </li>
              <li className="mb-3">
                The Artificial Intelligence Technologies platform strictly
                prohibits the sale of the following products and services,
                including but not limited to: any product/service that contains
                gambling, lucky boxes or random product, any product/service
                that may lead to any physical or psychological harm in any way,
                any Pirated contents or subscriptions and everything that
                violates intellectual property and copyright rights, any sexual
                or pornographic product/service or calls for that .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              القانون السعودي لجرائم المعلومات :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Saudi Information Crimes Law :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                التجار ملزمون بعدم مخالفة أي من أحكام قانون جرائم المعلومات
                السعودي، وفي حال مخالفتهم أحكام قانون جرائم المعلومات السعودي،
                فإن ذلك يخضع لمسؤوليتهم وإدارتهم البحتة، ولا مسؤولية على منصة
                تكنولوجيا المعلومات نتيجة لانتهاك المتجر للوائح المعمول بها، حيث
                أن التاجر مسؤول عن متجره وتعامله مع المستهلكين، و منصة AI
                Techalways لها الحق في إتخاذ ما تراه مناسبا لأي متجر أو تاجر
                ينتهك أحكام قانون جرائم المعلومات السعودي، سواء من خلال إبلاغ
                السلطات الرسمية أو ببساطة إغلاق متجر الإنترنت أو إلغاء عضوية
                التاجر مع المنصة .
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                {" "}
                Merchants are obligated not to violate any of the provisions of
                the Saudi Information Crimes Law, and in the event that they
                violate the provisions of the Saudi Information Crimes Law, this
                is subject to their pure responsibility and management, and
                there is no responsibility for the AI Techplatform as a result
                of the store’s violation of the applicable regulations, as the
                merchant is responsible He is fully responsible for his store
                and his dealings with consumers, and the platform for AI
                Techalways has the right to take what it deems appropriate for
                any store or merchant that violates the provisions of the Saudi
                Information Crimes Law, whether by informing the official
                authorities or simply closing the online store or canceling the
                merchant’s membership with the platform .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة الحادية والعشرون - تقييد الوصول أو العضوية :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article Twenty-One - Restriction of Access or Membership :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                مع عدم الإخلال بحقوق التجار الآخرين، فإن منصة AI Techcan توقف أو
                تلغي عضوية التاجر أو تقيد وصول التاجر إلى خدمات المنصة في أي وقت
                ومن دون إشعار ولأي سبب ومن دون حدود .
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                {" "}
                Without prejudice to the rights of other merchants, the platform
                of AI Techcan suspend or cancel the membership of the merchant
                or restrict the merchant's access to the services of the
                platform at any time and without notice and for any reason, and
                without limitation .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة الثانية والعشرون - الضمان :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article Twenty Two - Warranty :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                ولا تضمن منصة تقنية الذكاء الاصطناعي إصلاح العيوب ولا تضمن خلو
                المنتجات التي يقدمها التجار من أي عيوب أخرى. وانما يضمن لهم
                المتجر أو التاجر في حالة ورود الضمان ومدته في وصف المنتوج ويجب
                علي التاجر الالتزام بالجودة الراقية في محله الموجود علي الشبكة .
              </li>
              <li className="mb-3">
                يلتزم المتجر بتحديد سياسة الرجوع والتبادل التي تتعلق بالمتجر
                وحده، كما يجب عليه الالتزام بأحكام نظام التجارة الإلكترونية
                وغيرها من الأنظمة المعمول بها المتعلقة بالضمان أو التجارة
                الإلكترونية .
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                The platform of AI Techdoes not guarantee the repair of faults
                and does not guarantee that the products offered by merchants
                are free from any other defects. Rather, the store or the
                merchant guarantees them in the event that the guarantee and its
                duration are mentioned in the description of the product, and
                the merchant must adhere to good quality in his online store .
              </li>
              <li className="mb-3">
                The store is committed to setting the exchange and return policy
                that pertains to its store alone, and it must also comply with
                the provisions of the electronic commerce system and other
                applicable regulations related to guarantee or electronic
                commerce .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة الثالثة والعشرون - مسؤولية التاجر :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article Twenty Three - Merchant's Responsibility :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                يوافق التاجر على تحمل المسئولية وحماية منصة تقنيات الذكاء
                الاصطناعي وموظفيها ومالكيها والمنتسبين إليها من أي ضرر قد يقع
                على منصة AI Techas نتيجة لانتهاكات التاجر، كما أنه ملزم بإزالة
                أي ضرر أو منع أي ضرر قد يلحق بمنصة AI Techor لموظفيها أو بأحدهم
                نتيجة للمطالبات أو الخسائر أو الأعطال أو التكاليف أو النفقات أو
                الرسوم الناتج عن سوء إستخدام التاجر أو سوء إستخدامه مما نتج عنه
                مخالفه إتفاق الاستخدام أو الانظمه والقوانين المعمول بها في
                المملكه العربيه السعوديه أو مخالفه لحقوق التاجر أو الغير أو شكوي
                من مستخدم أو الغير .
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                The merchant agrees to bear the responsibility and protect the
                platform of artificial intelligence technologies, its employees,
                owners or affiliates from any damage that may occur to the
                platform of AI Techas a result of the merchant's violations, and
                he is also obligated to remove any damage or prevent any damage
                that may be caused to the platform of AI Techor its employees or
                One of them as a result of claims, losses, breakdowns, costs,
                expenses, or fees resulting from the misuse of the merchant or
                the misuse of the user, which resulted in a breach of the usage
                agreement or the regulations and laws in force in the Kingdom of
                Saudi Arabia, or an infringement of the rights of a merchant or
                third parties, or a complaint from a user or third parties .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة الرابعة والعشرون - العلاقة والملاحظات بين منصة تكنولوجيا
              الذكاء الاصطناعي والتجار :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article Twenty Four - Relationship and Notices between the
              Artificial Intelligence Technologies Platform and Merchants :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                لا تتضمن أي من قواعد وأحكام إتفاقية الاستخدام هذه إشارة إلى وجود
                شراكة بين أي تاجر وشركة AI Techplatform، ولا تسمح منصة AI
                TechPlatform لأي تاجر بأي طريقة للإشارة بشكل مباشر أو غير مباشر
                أو تملي وجود علاقة من أي نوع، سواء مباشرة أو غير مباشرة بينه
                كتاجر ومنصة AI Techplatform لإدارته، وأي إشعارات يرغب التاجر في
                إرسالها إلى على منصة تكنولوجيا الذكاء الاصطناعي أن يرسلها عبر
                البريد الإلكتروني و منصة تكنولوجيا الذكاء الاصطناعي تستجيب
                للرسالة الإلكترونية. وأنت، كتاجر، توافق على أن أي إشعارات مرسلة
                إليك من منصة تكنولوجيا الذكاء الصناعي سيتم تسليمها لك عبر البريد
                الإلكتروني الذي زودتك به منصة تكنولوجيا الذكاء الاصطناعي خلال
                عملية التسجيل .
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                None of the rules and provisions of this usage agreement include
                a reference to the existence of a partnership between any
                merchant and the AI Techplatform, and the AI Techplatform does
                not allow any merchant in any way to indicate directly or
                indirectly or dictate the existence of a relationship of any
                kind, whether direct Or indirectly between him as a merchant and
                the platform of AI Techor its management, and that any notices
                that the merchant wishes to send to the platform of artificial
                intelligence technologies, he must send them via e-mail and the
                platform of AI Techresponds to the electronic message. And you,
                as a merchant, agree that any notices sent to you from the AI
                Techplatform will be delivered to you via the e-mail that you
                provided the AI Techplatform with during the registration
                process .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة الخامسة والعشرون - نقل الحقوق والالتزامات :{" "}
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article Twenty Five - Transfer of Rights and Obligations :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                أنت، كتاجر، ليس لديك الحق للاعتراض على إجراءات إدارة منصة
                تكنولوجيا الذكاء الاصطناعي فيما يتعلق بمنصة تقنيات الذكاء
                الاصطناعي، سواء كانت هذه الإجراءات، بما في ذلك على سبيل المثال
                لا الحصر: تؤثر على كيان منصة تكنولوجيا الذكاء الاصطناعي،
                والتزاماتها، وملكيتها أو مسؤولياتها، والإجراءات الفنية أو
                الإجراءات الإدارية المتعلقة بمنصة الذكاء الاصطناعي، ومنصة AI
                Techis غير ملزمة بإبلاغك، ومنصة تقنيات الذكاء الإصطناعي ، إذا
                وجدت أنه من المهم إبلاغك بأي من هذه السلوكيات، لإبلاغك وفقا
                لإرادته الخالدة، طبقا لقواعد وأحكام إتفاقية الاستخدام .
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                You, as a merchant, do not have the right to object to the
                actions of the management of the AI Techplatform regarding the
                platform of the artificial intelligence technologies, whether
                these actions, including but not limited to: affect the entity
                of the AI Techplatform, its obligations, ownership or
                responsibilities, technical actions or administrative actions
                related to the platform Artificial intelligence, and the
                platform of AI Techis not obligated to inform you, and the
                platform of artificial intelligence technologies, if it deems it
                important to inform you of any of these behaviors, to inform you
                according to its pure will, according to the rules and
                provisions of the usage agreement .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة السادسة والعشرون - القوانين والتشريعات المنطبقة :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article Twenty Six - Applicable Law and Legislation :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                يتم تنظيم وصياغة إتفاقية المستخدم هذه وفقا للقوانين والأنظمة
                والتشريعات المعمول بها في المملكة العربية السعودية، وتخضع بشكل
                كامل وكامل للتشريعات المعمول بها مع السلطات في المملكة العربية
                السعودية.
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                This User Agreement is governed and drafted in accordance with
                the laws, regulations and legislation in force in the Kingdom of
                Saudi Arabia , and it is completely and completely subject to
                the legislation in force with the authorities in the Kingdom of
                Saudi Arabia .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة السابعة والعشرون - القواعد التي يجب على المتجر اتباعها عند
              التعامل مع المستهلك :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article Twenty Seven - Rules that the store must follow when
              dealing with the consumer :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                التاجر عندما يتعامل مع المستهلك يكون ملتزما بالنزاهة و الثقة و
                النزاهة .
              </li>
              <li className="mb-3">
                التاجر عندما يتعامل مع المستهلك يكون ملزما بالاخلاق الجيدة .
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                The merchant, when dealing with the consumer, is committed to
                honesty, trustworthiness and integrity .
              </li>
              <li className="mb-3">
                The merchant, when dealing with the consumer, is bound to adhere
                to good morals .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة الثامنة والعشرون - منشورات وعروض ترويجية ورقية وإلكترونية
              لمنصة تكنولوجيا الذكاء الاصطناعي :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article Twenty Eight - Paper and electronic promotional
              publications and presentations of the AI Techplatform :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                تنطبق قواعد وأحكام إتفاقية الاستخدام هذه على جميع المنشورات
                الترويجية الورقية والإلكترونية من خلال منصات النشر المختلفة ومن
                خلال وسائل التواصل الاجتماعي المختلفة .
              </li>
              <li className="mb-3">منصة تقنية الذكاء الاصطناعي.</li>
              <li className="mb-3">
                المطبوعات عرضة للتغيير، وغير ملزمة على منصة تكنولوجيا الذكاء
                الاصطناعي فيما يتعلق باستقرار أسعار الخدمات، الطرود أو العروض،
                وتغير الأسعار عرضة للتغيرات التي تحدث على منصة تكنولوجيا الذكاء
                الاصطناعي، أو المتاجر، أو التجار .
              </li>
              <li className="mb-3">
                إن أي عروض يقدمها AI TechPlatform هي عروض مؤقتة يتم تحديدها
                بواسطة فترة زمنية محددة، و AI TechPlatform غير ملزم بتمديد
                الفترة الزمنية أو الاستمرار في الفترة الزمنية المحددة لأنه لديه
                الحق في الاكتفاء أو تحديد إستحقاق أي مستخدم لهذا العرض أو إلغاء
                هذا العرض في أي وقت .
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                {" "}
                The rules and provisions of this usage agreement apply to all
                paper and electronic promotional publications through various
                publishing platforms and through various social media .
              </li>
              <li className="mb-3"> AI Tech platform .</li>
              <li className="mb-3">
                {" "}
                Publications are subject to change and are not binding on the
                platform of AI Techwith regard to the stability of prices for
                services, packages or offers, and changing prices is subject to
                changes that occur on the platform of artificial intelligence
                technologies, stores or merchants .
              </li>
              <li className="mb-3">
                {" "}
                Any offers placed by the AI Techplatform are temporary offers
                and are determined by a specific period of time, and the AI
                Techplatform is not obligated to extend the time period or to
                continue in the specified time period as it has the right to
                suffice or determine the entitlement of any user to this offer
                or cancel this offer at any time .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة التاسعة والعشرون - الخدمات الاستراتيجية واللوجستية (خدمات
              الأطراف الثالثة):
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article Twenty Nine - Strategic and Logistics Services ( Third
              Party Services ):
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                وفقا للقواعد والأحكام الواردة في إتفاقية الاستخدام هذه، قد تقدم
                منصة تقنية الذكاء الاصطناعي بعض الخدمات الاستراتيجية أو
                اللوجستية من خلال طرف ثالث أو طرف ثالث، وقد تكون هذه الخدمات،
                على سبيل المثال، غير أنها لا تقتصر على: خدمات شركات الشحن وتسليم
                المنتجات والبضائع .
              </li>
              <li className="mb-3">
                إن منصة تكنولوجيا الذكاء الاصطناعي تخبركم بأن تقديمها للخدمات
                الاستراتيجية أو اللوجستية ليس سوى تيسير وتعاون منها ومساعدة
                مستخدمي منصة تكنولوجيا الذكاء الاصطناعي.
              </li>
              <li className="mb-3">
                تخبرك منصة تقنيات الذكاء الاصطناعي أنها ليست مسؤولة بشكل كامل،
                بشكل مباشر أو غير مباشر، عن أي أفعال يقوم بها أي طرف ثالث، وأن
                ما تقوم به هو مجرد حلقة وصل بين المستخدم ومقدم الخدمة (الطرف
                الثالث).
              </li>
              <li className="mb-3">
                منصة تكنولوجيا الذكاء الاصطناعي تخبرك بأن طلب هذه الخدمة ليس
                إلزاميا، بل أن هذا الأمر يرجع إلى رغبة المستخدم وحاجته، وعندما
                يستخدم التاجر خدمات الطرف الثالث المتاحة في منصة تقنيات الذكاء
                الاصطناعي، منصة تقنية الذكاء الاصطناعي تخلي مسؤوليتها عن هذه
                العلاقة وهذه العلاقة لها أحكامها المستقلة التي تتم بين التاجر
                والطرف الثالث .
              </li>
              <li className="mb-3">
                يحدد بعض مقدمي الخدمات الاستراتيجية واللوجستية متطلباتهم أو
                تكاليفهم الخاصة، ولا تملك منصة تكنولوجيا الذكاء الاصطناعي سلطة
                على هذه المتطلبات أو هذه التكاليف. لذلك تنصح منصة تقنية الذكاء
                الاصطناعي التجار المسجلين لديها بضرورة الاطلاع على شروط مزود
                الخدمة (الطرف الثالث) وتكاليف خدماتها، وذلك قبل التأكد من طلب
                الخدمة .
              </li>
              <li className="mb-3">
                في حالة تقديم المستخدم طلبا للحصول على خدمة مقدمة من (طرف ثالث)،
                يأذن المستخدم لمنصة الذكاء الاصطناعي ويمنحها الإذن بتزويد مزود
                الخدمة (الطرف الثالث) بالبيانات الشخصية للمستخدم التي يطلبها،
                والبيانات الأخرى التي يحتاج إليها مزود الخدمة (الطرف الثالث).
                يجب أن يكون ذلك وفقا لقواعد وأحكام سياسة الخصوصية وسرية
                المعلومات المطبقة على منصة الذكاء الاصطناعي .
              </li>
              <li className="mb-3">
                بعض الخدمات التي تقدمها (أطراف ثالثة) على منصة تكنولوجيا الذكاء
                الاصطناعي تفيد التاجر من خلال سياسات صادرة بالاتفاق بين مزود
                الخدمة ومنصة تقنيات الذكاء الاصطناعي، بحيث يكون إستخدامها من
                خلال منصة تقنيات الذكاء الاصطناعي، ومنصة تكنولوجيا الذكاء
                الاصطناعي قد تستقطع بعض التكاليف منها تنفيذا للاتفاق بين منصة
                تكنولوجيا الذكاء الاصطناعي ومقدم الخدمة (أطراف ثالثة)، وعلى منصة
                تكنولوجيا الذكاء الاصطناعي عدم تحمل أي مسؤولية ناتجة عن عدم
                تقديم مزود الخدمة لخدماته
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                According to the rules and provisions of this usage agreement,
                the AI Techplatform may provide some strategic or logistical
                services through a third party or third parties, and these
                services may be, for example, but not limited to: shipping
                companies services and delivery of products and goods .
              </li>
              <li className="mb-3">
                The platform of AI Techinforms you that its provision of
                strategic or logistical services is nothing but a facilitation
                and cooperation from it and to assist users of the platform of
                AI Tech.
              </li>
              <li className="mb-3">
                The Artificial Intelligence Technologies platform informs you
                that it is not fully responsible, directly or indirectly, for
                any actions of any third party, and that what it is doing is
                just a link between the user and the service provider (the third
                party ).
              </li>
              <li className="mb-3">
                The platform of AI Techinforms you that the request for this
                service is not mandatory, but rather this matter is due to the
                desire and need of the user, and when the merchant uses the
                services of the third party available in the platform of
                artificial intelligence technologies, the platform of AI
                Techdisclaims its responsibility for this relationship and this
                relationship has its own independent provisions that are made
                between the merchant and the third party .
              </li>
              <li className="mb-3">
                Some providers of strategic and logistical services set their
                own requirements or costs, and the artificial intelligence
                technology platform has no authority over these requirements or
                these costs. Therefore, the artificial intelligence technology
                platform advises the merchants registered with it to view the
                terms of the service provider (the third party) and the costs of
                its services. before confirming the service request .
              </li>
              <li className="mb-3">
                In the event that the user submits a request for a service
                provided by a ( third party), the user authorizes the artificial
                intelligence platform and grants it permission to provide the
                service provider (the third party) with the user's personal data
                that he requests, and other data that the service provider (the
                third party) needs. This shall be in accordance with the rules
                and provisions of the privacy policy and confidentiality of
                information applicable to the AI Techplatform .
              </li>
              <li className="mb-3">
                Some of the services provided by ( third parties) on the
                platform of AI Techbenefit the merchant through policies issued
                by agreement between the service provider and the platform of
                artificial intelligence technologies, so their use is through
                the platform of artificial intelligence technologies, and the
                platform of AI Techmay deduct some costs from them in
                implementation of the agreement between The AI Techplatform and
                the service provider (third parties), and the AI Techplatform
                shall not bear any responsibility resulting from the failure of
                the service provider to provide its services
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">المادة 30 - الدعم التقني :</h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article Thirty - Technical Support :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <p className="py-2 font-semibold">
              توفر منصة تقنية الذكاء الاصطناعي بموجب أحكام وقواعد إتفاقية
              الاستخدام هذه بعض خدمات الدعم الفني للمحلات والتجار وذلك وفقا
              لامتيازات الحزمة التي يشارك فيها المتجر على سبيل المثال :
            </p>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                لوحة تحكم مجانية للمتاجر، وتتضمن لوحة التحكم بعض الخدمات
                المجانية .
              </li>
              <li className="mb-3">
                في حالة رغبة التاجر في إضافة بعض الخدمات أو الخدمات المختلفة أو
                الخدمات المتعددة فإن ذلك يخضع لسياسة الحزم والعروض من منصة AI
                Techplatform والتي غالبا ما تكون خاضعة للرسوم المالية .
              </li>
              <li className="mb-3">
                منصة AI Techallows المخازن للاتصال مع فريق الدعم الفني لمنصة
                تقنيات الذكاء الاصطناعي، التي تساعد المخازن العمل من خلال المنبر
                وحل المسائل التقنية الإلكترونية التي قد تنشأ في المخازن .
              </li>
            </ul>
          </>
        ) : (
          <>
            <p className="py-2 font-semibold">
              Under the rules and provisions of this usage agreement, the
              artificial intelligence technology platform provides some
              technical support services to stores and merchants, and this is in
              accordance with the package privileges in which the store
              participates, for example :
            </p>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                {" "}
                Free control panel for stores, and the control panel includes
                some free services .
              </li>
              <li className="mb-3">
                {" "}
                In the event that the merchant wishes to add some services,
                different services, or multiple services, this is subject to the
                policy of packages and offers of the AI Techplatform, which are
                often subject to financial fees .
              </li>
              <li className="mb-3">
                {" "}
                The platform of AI Techallows stores to communicate with the
                technical support team of the platform of artificial
                intelligence technologies, which helps stores work through the
                platform and solve electronic technical issues that may arise in
                stores .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة الحادية والثلاثون - تسوية المنازعات :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article Thirty One - Dispute Resolution :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                وفقا لقواعد وأحكام إتفاقية الاستخدام هذه، في حالة النزاع يسوي
                النزاع عن طريق التوفيق أو التفاوض أو التسوية الودية، وفي حالة
                إستمرار النزاع يسوي عن طريق السلطات المختصة في المملكة العربية
                السعودية .
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                According to the rules and provisions of this usage agreement,
                in the event of a dispute, the dispute shall be resolved through
                conciliation, negotiations, or amicable settlement, and in the
                event that the dispute persists, it shall be resolved through
                the competent authorities in the Kingdom of Saudi Arabia .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة الثانية والثلاثون - سياسة تلقي شكاوى المستهلكين وتسوية
              المنازعات بين المستهلك والمتجر :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article Thirty Two - Policy for receiving consumer complaints and
              settling disputes between the consumer and the store :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                وتأمل منصة تقنية الذكاء الاصطناعي أنه في حالة تقديم شكوى ضد أحد
                المتاجر، ينبغي اتباع الخطوات التالية :
              </li>
              <ol className="list-disc mr-4">
                <li className="mb-3">
                  الخطوة الأولى: تقديم بلاغ الشكوى عبر الرابط المخصص لذلك على
                  منصة آي تي التكنولوجية ، على أن يتضمن البيان: نص الشكوى
                  والمستندات المؤيدة - اسم المتجر المخالف. ثم تقوم منصة
                  تكنولوجيا الذكاء الاصطناعي بتحويل الشكوى إلى المتجر المعني من
                  أجل حلها .
                </li>
                <li className="mb-3">
                  الخطوة الثانية: في حال عدم إستجابة المتجر للمستهلك في غضون
                  سبعة أيام، يتم إبلاغ المستهلك بذلك .
                </li>
                <li className="mb-3">
                  الخطوة الثالثة: للمستهلك الحق في إتخاذ ما يراه مناسبا بعد
                  مراجعة محتوى رد المتجر على الشكوى .
                </li>
              </ol>
              <li className="mb-3">
                وتحتفظ منصة تكنولوجيا الذكاء الاصطناعى أيضا بحق اغلاق المحال
                المخالفة إذا ثبت إرتكابها المخالفة .
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                The artificial intelligence technology platform hopes that, in
                the event of a complaint against a store, follow the following
                steps :
              </li>
              <ol className="list-disc ml-4">
                <li className="mb-3">
                  <span className="font-semibold"> The first step :</span>{" "}
                  Submit a complaint statement via the dedicated link on the AI
                  Techplatform , provided that the statement includes: the text
                  of the complaint and supporting documents - the name of the
                  violating store. Then the AI technology platform will refer
                  the complaint to the concerned store in order to solve it .
                </li>
                <li className="mb-3">
                  <span className="font-semibold"> The second step :</span> In
                  the event that the store does not respond to the consumer
                  within seven days, the consumer will be notified of that .
                </li>
                <li className="mb-3">
                  <span className="font-semibold"> Third step :</span> The
                  consumer has the right to take whatever he deems appropriate
                  after reviewing the content of the store's response to the
                  complaint .
                </li>
              </ol>
              <li className="mb-3">
                The artificial intelligence technology platform also reserves
                the right to close the violating store if it is proven to have
                committed the violation .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة الثالثة والثلاثون - أحكام عامة :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article Thirty Three - General Provisions :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ol className="list-disc mr-4 my-3">
              <li className="mb-3">
                في حالة إلغاء أي مادة أو بند وارد في إتفاقية المستخدم هذه أو
                وجود أي مواد واردة أو أي بند في إتفاقية الاستخدام لم يعد قابلا
                للتنفيذ، فإن مثل هذا الأمر لا يبطل صلاحية بقية المواد والبنود
                والقواعد والأحكام الواردة في إتفاقية الاستخدام ويظل ساريا حتى
                إشعار آخر إدارة منصة أخرى ل AI Tech.
              </li>
              <li className="mb-3">
                إتفاق الاستخدام هذا - والذي يتم تعديله من وقت لآخر حسب الضرورة -
                يشكل آلية العمل والتفاهم والاتفاق والعقد بين التاجر ومتجره ومنصة
                آي آي تي تكول، والتجار والمتجر ملزمون بالالتزام بأحكام هذه
                الاتفاقية، كما يوافق التاجر على الأخذ في الاعتبار ما يلي :
              </li>
            </ol>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-3">
                تنطبق إتفاقية الاستخدام هذه على جميع مستخدمي منصة AI
                Techplatform، وتنظم العلاقة، التي هي العقد بين التاجر و AI
                TechPlatform فقط، بغض النظر عن أي شكل قانوني أو مؤسسي أو تجاري
                أو خيري يقوم به التاجر أو المتجر. أما العلاقة بين التاجر
                والمستهلك فهي علاقة قانونية مستقلة لها ضوابطها الحالية الخاصة .
              </li>
              <li className="mb-3">
                يجب علي المحل ان يضع اتفاقيه إستعماله الخاصه به علي ان يتم ذلك
                وفقا لاحكام القوانين والانظمه المعمول بها في المملكه العربيه
                السعوديه وعلي الاخص نظام المعاملات الالكترونيه ونظام التجاره
                الالكترونيه وغيرها من الانظمه المتعلقه بنشاط التاجر .
              </li>
              <li className="mb-3">
                التاجر الذي يلتحق لمنصة آي تكنعبر ( شركاء النجاح - الرابط موضوع
                ) وبذلك يعطي شريك النجاح الذي سجل معه الحق في الاطلاع على
                معلومات التاجر و محله .
              </li>
              <li className="mb-3">
                لا يحق لأحد - بخلاف إدارة منصة تكنولوجيا الذكاء الاصطناعي - فرض
                أي مواد أو شروط أو أحكام في إتفاقية إستخدام منصة تكنولوجيا
                الذكاء الاصطناعي، وقد تتلقى منصة تكنولوجيا الذكاء الاصطناعي
                اقتراحات من التجار فيما يتعلق بهذه الاتفاقية .
              </li>
              <li className="mb-3">
                إذا تمت ترجمة إتفاقية الاستخدام إلى أي لغة أخرى، سواء كانت على
                منصة AI Techor خلاف ذلك، فإن النص العربي لاتفاقية الاستخدام يبقى
                هو الأصل في جميع المعاملات .
              </li>
              <li className="mb-3">منصة تقنية الذكاء الاصطناعي.</li>
            </ul>
          </>
        ) : (
          <>
            <ol className="list-disc ml-4 my-3">
              <li className="mb-3">
                In the event that any incoming material or clause in this User
                Agreement is canceled or there is any incoming material or any
                clause in the Use Agreement that is no longer enforceable, such
                an order does not invalidate the validity of the rest of the
                articles, clauses, rules and provisions contained in the Use
                Agreement and remains in effect until notice Another platform
                management of AI Tech.
              </li>
              <li className="mb-3">
                This usage agreement - which is amended from time to time as
                necessary - constitutes the mechanism of work, understanding,
                agreement and contract between the merchant and his store and
                the platform of AI Techonly, and the merchant and the store are
                obligated to abide by the provisions of this agreement, and the
                merchant also agrees to take into account the following :
              </li>
            </ol>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                This usage agreement is applicable to all users of the AI
                Techplatform, and it regulates the relationship, which is the
                contract between the merchant and the AI Techplatform only,
                regardless of any legal, institutional, commercial or charitable
                form that the merchant or store takes. As for the relationship
                between the merchant and the consumer, it is subject to an
                independent legal relationship and has its own current controls
                between them .
              </li>
              <li className="mb-3">
                The store must draw up its own usage agreement, provided that it
                is in accordance with the provisions of the laws and regulations
                in force in the Kingdom of Saudi Arabia, in particular the
                electronic transactions system, the electronic commerce system,
                and other systems related to the merchant's activity .
              </li>
              <li className="mb-3">
                The merchant who joins the platform of AI Techthrough ( Success
                Partners - the link is placed), thus granting the success
                partner who registered with him the right to view the
                information of the merchant and his store .
              </li>
              <li className="mb-3">
                No one has the right - other than the management of the AI
                Techplatform - to impose any articles, terms or provisions in
                the usage agreement of the AI Techplatform, and the AI
                Techplatform may receive suggestions from merchants in relation
                to this agreement .
              </li>
              <li className="mb-3">
                If the usage agreement is translated into any other language,
                whether on the platform of AI Techor otherwise, the Arabic text
                of the usage agreement remains the original in all transactions
                .
              </li>
              <li className="mb-3"> AI Techplatform .</li>
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default AgrementPage;
