import React, { useEffect, useState } from "react";
import { createContext } from "react";

export const domainProvider = createContext();
const DomainContext = ({ children }) => {
  const [customdomain, setCustomdomain] = useState("");
  const [domainstatus, setDomainstatus] = useState("");
  const [titleName, setTitleName] = useState("");
  const [orgDomain, setOrgDomain] = useState("");
  var url = window.location.href;
  const urlArray = url.split("/");
  useEffect(() => {
    setCustomdomain(urlArray[2]);
    const name = urlArray[2].split(".");
    setTitleName(name[0]);
    localStorage.setItem("customdomain", urlArray[2]);
    // setCustomdomain("uat.aitech.com.sa");
    // localStorage.setItem("customdomain", "uat.aitech.com.sa");
    // setTitleName("demo");
  }, []);

  // setCustomdomain("demo.com")

  const info = {
    customdomain,
    setCustomdomain,
    titleName,
    domainstatus,
    setDomainstatus,
    orgDomain,
    setOrgDomain,
  };
  return (
    <domainProvider.Provider value={info}>{children}</domainProvider.Provider>
  );
};

export default DomainContext;
