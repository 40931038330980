import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ApiLoadingSpinner from '../../../components/utils/ApiLoadingSpinner';
import getDataById from '../../../hooks/getDataById';
import updateData from '../../../hooks/updateData';

const EditRoles = () => {
    const { id } = useParams();

    const [loading, setLoading] = useState(true);

    const [role, setRole] = useState([]);
    const [roleName, setroleName] = useState("");

    const [view, setView] = useState({})
    const [edit, setEdit] = useState({})
    const [dlt, setDlt] = useState({})
    const [add, setAdd] = useState({})
    const user = JSON.parse(localStorage.getItem('user'))


    useEffect(() => {

        const getRoleAccess = async () => {
            const res = await fetch(`${process.env.REACT_APP_APIURL}/admin/getbyidroleaccess/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            });
            const response = await res.json();
            if (response.data) {

                setRole(response.data.role_access);
                setroleName(response.data.role.role_name)
            }
            setLoading(false);
        }


        // const getRolePermission = async () =>{
        //     const response = await getDataById("getbyidroleaccess",id);
        // }

        getRoleAccess();
        // getRolePermission();

    }, [id])


    const handlePermission = (event, module_id, key) => {
        const checked = event.target.checked;

        switch (key) {
            case 'view':
                if (checked) {
                    const newView = { ...view };
                    newView[module_id] = 1;
                    setView(newView);
                } else {
                    const newView = { ...view }
                    delete newView[module_id];
                    setView(newView);
                }

                break;
            case 'edit':
                if (checked) {
                    const newEdit = { ...edit };
                    newEdit[module_id] = 1;

                    setEdit(newEdit);
                } else {
                    const newEdit = { ...edit }
                    delete newEdit[module_id];
                    setEdit(newEdit);
                }


                break;
            case 'delete':
                if (checked) {

                    const newDelete = { ...dlt };
                    newDelete[module_id] = 1;
                    setDlt(newDelete);

                } else {
                    const newdlt = { ...dlt }
                    delete newdlt[module_id];
                    setDlt(newdlt);
                }
                break;
            case 'add':
                if (checked) {

                    const newAdd = { ...add };
                    newAdd[module_id] = 1;
                    setAdd(newAdd);

                } else {
                    const newAdd = { ...add }
                    delete newAdd[module_id];
                    setAdd(newAdd);
                }
                break;
            default:

        }
    }
    const changeAccess = (event, module_id, key) => {
        const checked = event.target.checked;

        const newRole = role.map(access => {
            if (access.module_id === module_id) {
                const newAccess = { ...access }
                newAccess[key] = access[key] ? 0 : 1;
                return newAccess;
            }
            return access;
        })
        setRole(newRole);
        // switch (key) {
        //     case 'view':
        //         if (checked) {
        //             const newView = { ...view };
        //             newView[module_id] = 1;
        //             setView(newView);
        //         } else {
        //             const newView = { ...view }
        //             delete newView[module_id];
        //             setView(newView);
        //         }

        //         break;
        //     case 'edit':
        //         if (checked) {
        //             const newEdit = { ...edit };
        //             newEdit[module_id] = 1;

        //             setEdit(newEdit);
        //         } else {
        //             const newEdit = { ...edit }
        //             delete newEdit[module_id];
        //             setEdit(newEdit);
        //         }


        //         break;
        //     case 'delete':
        //         if (checked) {

        //             const newDelete = { ...dlt };
        //             newDelete[module_id] = 1;
        //             setDlt(newDelete);

        //         } else {
        //             const newdlt = { ...dlt }
        //             delete newdlt[module_id];
        //             setDlt(newdlt);
        //         }
        //         break;
        //     case 'add':
        //         if (checked) {

        //             const newAdd = { ...add };
        //             newAdd[module_id] = 1;
        //             setAdd(newAdd);

        //         } else {
        //             const newAdd = { ...add }
        //             delete newAdd[module_id];
        //             setAdd(newAdd);
        //         }
        //         break;
        //     default:

        // }
    }


    const updateRoleAccess = async () => {
        setLoading(true);
        const view = {}
        const edit = {}
        const add = {}
        const dlt = {}
        role.forEach(i => {
            view[i.module_id] = i.view || 0;
            edit[i.module_id] = i.edit || 0;
            add[i.module_id] = i.add || 0;
            dlt[i.module_id] = i.delete || 0;
        })

        const data = {
            role_id: parseInt(id),
            role_name: roleName,
            module_ids: role.map(i => i.module_id),
            view: view,
            edit: edit,
            delete: dlt,
            add: add,
            upload: {},
            download: {},
        }

        const res = await updateData("admin/updateroleaccess", data);
        if (res.status_code === 200) {
            toast.success("Updated successfully");
        } else {
            toast.error("something went wrong");

        }

        setLoading(false)
    }

    return (

        <div>

            {
                loading && <ApiLoadingSpinner />
            }
            <div className="mb-20">
                <div className="mx-auto border bg-white">
                    <div className="card-title border-b p-4">
                        <h3 className="text-base font-medium">Role information</h3>
                    </div>



                    <div className="py-5 px-4">

                        <div className="max-w-lg mx-auto">

                            <div className="my-5">
                                <div className="form-group mb-5">
                                    <div className="grid grid-cols-1 md:grid-cols-12 items-center">
                                        <div className="col-span-3">
                                            <label className="text-[13px]" htmlFor="role-name">Role Name<span className="text-error">*</span></label>
                                        </div>
                                        <div className="col-span-9">
                                            <input value={roleName} onChange={(e) => setroleName(e.target.value)} className="w-full h-[40px] input input-bordered rounded" placeholder="Role Name" />

                                        </div>
                                    </div>

                                </div>

                            </div>


                            <h5 className="border-b p-4 font-bold">Permissions</h5>

                            {
                                role.map((access, i) => (
                                    <div key={i} className="border rounded mx-5 my-3">
                                        <div className="card-title border-b p-3">
                                            <h3 className="text-base font-medium">{access?.module_name}</h3>
                                        </div>
                                        <div className="p-4">
                                            <div className="flex gap-4 max-w-lg flex-wrap">

                                                {
                                                    access.module_name !== 'Dashboard' ?
                                                        <>

                                                            <div className="grow border rounded p-3">
                                                                <p className="text-sm mb-2">View</p>
                                                                <input checked={access?.view} onChange={(e) => changeAccess(e, access?.module_id, "view")} type="checkbox" className="toggle toggle-sm toggle-secondary " />
                                                            </div>
                                                            <div className="grow border rounded p-3">
                                                                <p className="text-sm mb-2">Add</p>
                                                                <input checked={access?.add} onChange={(e) => changeAccess(e, access?.module_id, "add")} type="checkbox" className="toggle toggle-sm toggle-secondary " />
                                                            </div>
                                                            <div className="grow border rounded p-3">
                                                                <p className="text-sm mb-2">Edit</p>
                                                                <input checked={access?.edit} onChange={(e) => changeAccess(e, access?.module_id, "edit")} type="checkbox" className="toggle toggle-sm toggle-secondary " />
                                                            </div>
                                                            <div className="md:grow border rounded p-3">
                                                                <p className="text-sm mb-2">Delete</p>
                                                                <input checked={access?.delete} onChange={(e) => changeAccess(e, access?.module_id, "delete")} type="checkbox" className="toggle toggle-sm toggle-secondary " />
                                                            </div>

                                                        </>

                                                        :
                                                        <>

                                                            <div className="grow border rounded p-3">
                                                                <p className="text-sm mb-2">View</p>
                                                                <input checked={access?.view} onChange={(e) => changeAccess(e, access?.module_id, "view")} type="checkbox" className="toggle toggle-sm toggle-secondary " />
                                                            </div>

                                                        </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }


                        </div>


                        <div className="text-center mx-auto pb-5">
                            <button onClick={updateRoleAccess} className="py-1 px-3 bg-primary text-white rounded cursor-pointer">Update</button>

                        </div>

                    </div>
                </div>

            </div>
        </div>

    );
};

export default EditRoles;