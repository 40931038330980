import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { BsSearch, BsCart2 } from "react-icons/bs";
import { FiUser } from "react-icons/fi";
import { useContext } from "react";
import { customerProvider } from "../../../contexts/CustomerContext";
import { cartProvider } from "../../../contexts/CartContext";
import { storeInformation } from "../../../contexts/StoreInformationContext";
import ChangeLanguage from "../../../locales/ChangeLanguage";
import { useTranslation } from "react-i18next";
import logo_demo from "../../../assets/demo_logo.png";
import Div from "../../../components/utils/Div";
import { Helmet } from "react-helmet-async";
import Favicon from "react-favicon";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import { toast } from "react-toastify";
import { domainProvider } from "../../../contexts/DomainContext";
import i18next from "i18next";
import { Close } from "@mui/icons-material";
import axios from "../../../components/utils/CustomerInterceptor";

const ShopNavbar = () => {
  const selectLanguage = i18next.language;
  const { t } = useTranslation();
  const page = window.location.pathname.split("/")[2];
  const navBar = useRef(null);
  const overlay = useRef(null);
  const { customer, setCustomer } = useContext(customerProvider);
  const { cartCount } = useContext(cartProvider);

  const [loading, setLoading] = useState(false);
  const { storeDetails } = useContext(storeInformation);
  const { data } = storeDetails;
  const user = JSON.parse(localStorage.getItem("customer"));
  const { customdomain, titleName } = useContext(domainProvider);
  const storeName = storeDetails?.data?.storeDetails?.store_name
    ? storeDetails?.data?.storeDetails?.store_name
    : "";

  useEffect(() => {
    const storeId = data?.storeDetails?.store_id;

    if (customer != null && customer != undefined && !customer) {
      setLoading(true);
      if (!(storeId === customer?.data?.store_id)) {
        setCustomer(null);
      }
      setLoading(false);
    }
  }, [data, customer]);

  const handleEnableNav = () => {
    navBar.current.style.left = "0px";
    overlay.current.style.display = "block";
  };
  const handleDisableNav = () => {
    navBar.current.style.left = "-992px";
    overlay.current.style.display = "none";
  };
  const navigate = useNavigate();
  const handleLogout = async () => {
    const res2 = await axios.post(
      `${process.env.REACT_APP_APIURL}/vendor/store/customer/logout`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const response2 = await res2.data;
    if (response2.status_code != 200) {
      return toast.error(response2.message);
    }
    toast.success(response2.message);
    localStorage.removeItem("customer");
    // navigate(`/${storeName}`)
    window.location.reload();
  };

  const searchProduct = (event) => {
    event.preventDefault();
    const form = event.target;
    const searchValue = form.searchValue.value;
    if (customdomain === "uat.aitech.com.sa")
      navigate(`/${storeName}/shop`, { state: { searchValue: searchValue } });
    else {
      navigate(`/shop`, { state: { searchValue: searchValue } });
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <header className="w-full px-4 2xl:px-10 py-3 bg-white border-b border-gray-500 z-50 shadow flex items-center">
      <Helmet>
        <title>Home Page - {storeName}</title>
      </Helmet>
      {storeDetails?.data?.storeDetails?.store_favicon && (
        // <link rel="icon" href={icon} />
        <Favicon url={storeDetails?.data?.storeDetails?.store_favicon} />
      )}
      <Div className="">
        <div className="flex justify-between items-center">
          <div
            className="flex flex-col justify-center items-center cursor-pointer p-1 lg:hidden"
            onClick={handleEnableNav}
          >
            <div className="w-5 h-[2px] bg-black mb-1"></div>
            <div className="w-5 h-[2px] bg-black mb-1"></div>
            <div className="w-5 h-[2px] bg-black"></div>
          </div>
          <div class="w-6/12 lg:w-2/12 flex justify-center lg:inline">
            <Link
              to={`${customdomain === "uat.aitech.com.sa" ? `/${storeName}` : `/`}`}
            >
              {data?.storeDetails?.store_logo ? (
                <img
                  src={`${data?.storeDetails?.store_logo}`}
                  alt="company name"
                  loading="lazy"
                  width="80"
                  height="45"
                />
              ) : (
                <img
                  src={logo_demo}
                  alt="logo"
                  loading="lazy"
                  width="80"
                  height="45"
                />
              )}
            </Link>
          </div>
          <div className="flex items-center">
            <nav
              className={`fixed -left-[992px] w-[55%] top-0 md:w-[40%] h-full flex items-start flex-col transition-all lg:pt-0 lg:flex-row lg:static lg:w-full font-domain bg-gray-50 border-r shadow-lg border-gray-300 z-50 lg:border-0 lg:shadow-none lg:bg-white`}
              ref={navBar}
            >
              <div className="relative w-full p-3 pb-6 lg:hidden">
                <div
                  className="w-full text-right my-3 font-bold cursor-pointer text-red-600 text-xl"
                  onClick={handleDisableNav}
                >
                  <Close />{" "}
                </div>
                <input
                  type="text"
                  placeholder="Search Products..."
                  className="bg-gray-100 w-full pl-4 pr-7 py-2"
                />
                <BsSearch className="text-xl absolute right-6 top-[73px] cursor-pointer" />
              </div>
              <div className="block lg:py-6 xl:pr-6 md:pr-5 pl-4 lg:pl-0 lg:border-0 border-b-2 border-dashed w-full py-4 lg:w-auto">
                <NavLink
                  end
                  to={`${
                    customdomain === "uat.aitech.com.sa" ? `/${storeName}` : `/`
                  }`}
                  className="capitalize font-normal hover:text-orange transition-all text-base navlink text-gray-900 duration-200"
                >
                  {t("Home")}
                </NavLink>
              </div>
              <div className="block lg:py-6 xl:pr-6 md:pr-5 pl-2 lg:pl-0 lg:border-0 border-b-2 border-dashed w-full py-4 lg:w-auto">
                <NavLink
                  to={`${
                    customdomain === "uat.aitech.com.sa"
                      ? `/${storeName}/shop`
                      : `/shop`
                  }`}
                  className="capitalize font-normal hover:text-orange transition-all text-base navlink text-gray-900 duration-200 px-2"
                >
                  {t("Shop")}
                </NavLink>
              </div>
              <div className="block lg:py-6 xl:pr-6 md:pr-5 pl-4 lg:pl-0 lg:border-0 border-b-2 border-dashed w-full py-4 lg:w-auto">
                <NavLink
                  to={`${
                    customdomain === "uat.aitech.com.sa"
                      ? `/${storeName}/track_order`
                      : `/track_order`
                  }`}
                  className="capitalize font-normal hover:text-orange transition-all text-base navlink text-gray-900 duration-200"
                >
                  {t("Track")}
                </NavLink>
              </div>
              <div className="block lg:py-6 xl:pr-6 md:pr-5 pl-4 lg:pl-0 lg:border-0 border-b-2 border-dashed w-full py-4 lg:w-auto">
                <NavLink
                  to={`${
                    customdomain === "uat.aitech.com.sa"
                      ? `/${storeName}/contact_us`
                      : `/contact_us`
                  }`}
                  className="capitalize font-normal hover:text-orange transition-all text-base navlink text-gray-900 duration-200"
                >
                  {t("HNav_contact")}
                </NavLink>
              </div>
            </nav>
            {/* overlay to disable nav */}
            {/* <div
              className={` w-full h-full fixed top-0 left-0 z-10 cursor-pointer hidden lg:hidden`}
              onClick={handleDisableNav}
              ref={overlay}
            ></div> */}
            <div className="flex items-center">
              <div className="lg:mr-8">
                <ChangeLanguage />
              </div>
              {page === "shop" ? (
                <></>
              ) : (
                <div
                  className={`hidden lg:block py-1 px-2 hover:text-blue-600`}
                >
                  <label htmlFor="my-modal-4" className="cursor-pointer">
                    <BsSearch className="text-xl hover:text-[#F4900C] transition-all text-gray-900" />
                  </label>
                </div>
              )}
              <Link
                to={`${
                  customdomain === "uat.aitech.com.sa"
                    ? `/${storeName}/cart`
                    : `/cart`
                }`}
                className="py-1 px-2 cursor-pointe relative ease-in duration-200 hover:text-[#f4900c]"
              >
                <div className="absolute bg-gray-800 top-0 right-0 w-[15px] h-[15px] flex justify-center items-center rounded-full text-white text-[12px]">
                  {cartCount ? cartCount : "0"}
                </div>
                <BsCart2 className="text-[22px] hover:text-[#F4900C] transition-all text-gray-900" />
              </Link>
              {!customer ? (
                <div className="group pt-1 px-2 relative">
                  <button
                    class="text-gray-900 hover:text-[#F4900C] transition-all toggle-menu"
                    aria-label="icon-settings"
                  >
                    <FiUser className="text-[22px]" />
                    <ul
                      class={`submenu bg-white py-3 px-8 shadow transition-all absolute ${
                        selectLanguage === "ar" ? "left-0" : "right-0"
                      } mt-6 top-full opacity-0 group-hover:opacity-100 invisible group-hover:visible group-hover:-translate-y-3 transform z-10 min-w-max`}
                    >
                      <li class="my-3">
                        <Link
                          to={`${
                            customdomain === "uat.aitech.com.sa"
                              ? `/${storeName}/register-login`
                              : `/register-login`
                          }`}
                          class="text-gray-700 font-normal text-base capitalize transition-all hover:text-orange navlink "
                        >
                          {t("regi_btn")} / {t("HNav_login")}
                        </Link>
                      </li>
                    </ul>
                  </button>
                </div>
              ) : (
                <div className="dropdown dropdown-end flex items-center gap-x-1">
                  <label
                    tabIndex="0"
                    className="btn btn-ghost btn-circle avatar"
                  >
                    <div className="w-10 rounded-full">
                      {user?.data?.profile_image ? (
                        <img src={user?.data?.profile_image} alt="userlogo" />
                      ) : (
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                          alt="userlogo"
                        />
                      )}
                    </div>
                  </label>
                  <ul
                    tabIndex="0"
                    className="menu menu-compact dropdown-content mt-40 p-2 shadow bg-base-100 rounded-box w-52 text-black"
                  >
                    <li>
                      <Link
                        to={`${
                          customdomain === "uat.aitech.com.sa"
                            ? `/${storeName}/user/profile`
                            : `/user/profile`
                        }`}
                        className="justify-between"
                      >
                        {t("Profile")}
                      </Link>
                    </li>
                    {/* <li><a>Settings</a></li> */}
                    <li>
                      <p className="text-[12px]" onClick={handleLogout}>
                        {t("Logout")}
                      </p>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </Div>
      {/* search modal start */}
      <input type="checkbox" id="my-modal-4" className="modal-toggle" />
      <label htmlFor="my-modal-4" className="modal cursor-pointer items-start">
        <label className="modal-box relative top-[40px] rounded" htmlFor="">
          <span className="text-md font-bold mb-1">{t("Type_To_Search")}</span>
          <form onSubmit={searchProduct} className="relative">
            <input
              name="searchValue"
              type="text"
              placeholder={t("To_Search")}
              className="w-full py-2 pl-5 pr-9"
            />
            <button type="submit">
              <BsSearch size={20} className="absolute right-2 top-3" />
            </button>
          </form>
        </label>
      </label>
      {/* search modal end */}
    </header>
  );
};

export default ShopNavbar;
