import React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import ApiLoadingSpinner from '../../../components/utils/ApiLoadingSpinner';

const AdminAddPaymentMethods = () => {
    const { t } = useTranslation()
    const { control, register, setValue, handleSubmit, watch, formState: { errors } } = useForm();
    const user = JSON.parse(localStorage.getItem("user"))
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    const [error, setError] = useState("");

    const onSubmit = async (data) => {
        setLoading(true)
        const form = new FormData();
        form.append("gateway_name", data.gateway_name);
        form.append("action_url", data.action_url);
        form.append("gateway_image", data.gateway_image[0]);
        form.append("description", data.description);

        const res = await fetch(`${process.env.REACT_APP_APIURL}/admin/createpaymentgateway`, {
            method: 'POST',
            body: form,
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        const response = await res.json();
        if (response?.status_code === 200) {
            toast.success(`${t("Add_Gateway_success")}`);
            setLoading(false)
            navigate("/admin/payment-gateway");
        }
        else {
            toast.error(response?.message || response?.err)
        }
        setLoading(false)
    }

    if (loading) {
        return <ApiLoadingSpinner />
    }

    return (
        <>
            <h2 className="text-title text-lg md:text-2xl pb-2">{t("Add_Gateway2")}</h2>
            <div className="p-4 border border-gray-200 bg-white shadow-sm col-span-2 max-w-lg mx-auto">

                <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
                    <div className="form-control w-full max-w-md">
                        <label className="label">
                            <span className="label-text">{t("Gateway_Name")}</span>

                        </label>
                        <input
                            {...register("gateway_name")}
                            type="text"
                            placeholder={t("Gateway_Name")}
                            required
                            className="input input-bordered w-full max-w-md rounded-none" />

                    </div>
                    <div className="form-control w-full max-w-md">
                        <label className="label">
                            <span className="label-text">{t("Action_Url")}</span>

                        </label>
                        <input
                            {...register("action_url")}
                            type="text"
                            placeholder={t("Action_Url")}
                            required
                            className="input input-bordered w-full max-w-md rounded-none" />

                    </div>

                    <div className="form-control w-full max-w-md">
                        <label className="label">
                            <span className="label-text">{t("Gateway_Image")}</span>

                        </label>
                        <input
                            {...register("gateway_image")}
                            type="file"
                            className="input w-full max-w-md rounded-none -ml-3" required
                        />
                    </div>
                    <div className="form-control w-full max-w-md">

                        <div className="form-control rounded-none">



                            <label className="label">
                                <span className="label-text">{t("Description")}</span>

                            </label>
                            <textarea
                                {...register("description")}
                                rows="30"
                                cols="30"
                                placeholder={t("Description")}
                                className="input input-bordered w-full max-w-md rounded-none">
                            </textarea>
                        </div>
                    </div>


                    <small className="text-red-500">
                        {error && error}
                    </small>
                    <div className="flex justify-center gap-x-4">

                        <Link to={'/admin/payment-gateway'} className="btn btn-sm btn-error max-w-md text-white rounded">{t("Cancel")}</Link>
                        <button className="btn btn-sm btn-primary max-w-md text-white rounded">{t("Save")}</button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default AdminAddPaymentMethods;