import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import DataTable from 'react-data-table-component';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import deleteDataById from '../../../hooks/deleteDataById';
import { Helmet } from 'react-helmet-async';
import LoadingSpinner from '../../../components/utils/LoadingSpinner';
import { useTranslation } from 'react-i18next';



const GeneralServices = () => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const [services, setServices] = useState([]);
    const user = JSON.parse(localStorage.getItem('user'))
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchQueries = async () => {
            const baseUrl = process.env.REACT_APP_APIURL;
            const res = await fetch(`${baseUrl}/admin/getallservice`, {
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            });
            const response = await res.json();
            if (response.status_code === 200) {
                setServices(response.data);
            }
        }
        fetchQueries();
    }, [])

    const selectItemToEdit = async (id) => {
        navigate('/admin/settings/general_services/edit',
            {
                state: id
            }
        )
    }

    const deleteItem = async (id) => {
       
        confirmAlert({
            title: `${t("delete_alert_title")}`,
            message: `${t("delete_alert_des")}`,
            buttons: [
                {
                    label: `${t("yes")}`,
                    onClick: async () => {
                        setLoading(true);

                        const response = await deleteDataById('admin/deleteservice', id);

                        if (response?.status_code === 200) {
                            const newdataList = services.filter(item => item.id !== id);
                            setServices(newdataList);
                        } else {
                            toast.error(response?.message || response?.err)
                        }
                        setLoading(false);
                    }
                },
                {
                    label: `${t("no")}`,
                    onClick: () => { }
                }
            ]
        });

    }

    const columns = [
        {
            name: `${t("Image")}`,
            cell: (row) => (
                <div className="w-16 my-1">
                    <img src={row.image} alt="services_image" />
                </div>
            ),

        },
        {
            name: `${t("Title")}`,
            selector: row => row.title,

        },
        {
            name: `${t("Description")}`,
            selector: row => row.description,

        },
        {
            name: `${t("Options")}`,
            cell: (row) => (<div className="flex gap-2">
                <span onClick={() => selectItemToEdit(row.id)} className="p-2 flex items-center border border-error hover:bg-error rounded-full text-error hover:text-white cursor-pointer duration-300" title={t("Edit")}>
                    <AiOutlineEdit />
                </span>
                <span onClick={() => deleteItem(row.id)} className="p-2 flex items-center border border-error hover:bg-error rounded-full text-error hover:text-white cursor-pointer duration-300" title={t("Delete")}>
                    <AiOutlineDelete />
                </span>
            </div>),
        },
    ]

    if (loading) {
        return <LoadingSpinner />
    }

    return (
        <div>
            <Helmet>
                <title>{t('Admin')} - {t('All_Service')}</title>
            </Helmet>
            <div className='flex justify-between'>
                <h1 className="text-md font-medium">{t('All_Service')}</h1>
                <Link to={'/admin/settings/general_services/add'}>
                    <button className='btn btn-primary text-white btn-sm'>
                        {t("Add_Service")}
                    </button>
                </Link>
            </div>
            <div className="my-5 bg-white border rounded">

                <DataTable
                    columns={columns}
                    data={services}
                    pagination
                />
            </div>
        </div>
    );
};

export default GeneralServices;