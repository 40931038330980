import React from 'react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import ApiLoadingSpinner from '../../../components/utils/ApiLoadingSpinner';

const AddVendor = () => {
    const { t } = useTranslation()
    const { control, register, handleSubmit, formState: { errors } } = useForm();
    const user = JSON.parse(localStorage.getItem("user"))
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("");


    const onSubmit = async (data) => {
        if (data.vendor_password !== data.vendor_password2) {
            setError(`${t("pass_err")}`);
            return
        }
        setLoading(true)
        const res = await fetch(`${process.env.REACT_APP_APIURL}/admin/createvendor`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }

        })
        const response = await res.json();
        // const response = undefined


        if (response?.status_code === 200) {
            toast.success(`${t("add_vendor_text")}`);
            setLoading(false)
            navigate("/admin/plan/vendors");

        }
        else {
            toast.error(response?.message || response?.err)
        }
        setLoading(false)
    }

    if (loading) {
        return <ApiLoadingSpinner />
    }

    return (
        <>
            <h2 className="text-title text-lg md:text-2xl pb-2">{t("add_vendor_title")}</h2>
            <div className="p-4 border border-gray-200 bg-white shadow-sm col-span-2 max-w-lg mx-auto">

                <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
                    <div className="form-control w-full max-w-md">
                        <label className="label">
                            <span className="label-text">{t("Vendor_Name")}</span>

                        </label>
                        <input
                            {...register("vendor_name")}
                            type="text"
                            placeholder={t("Vendor_Name")}
                            required
                            className="input input-bordered w-full max-w-md rounded-none" />
                    </div>


                    <div className="form-control w-full max-w-md">
                        <label className="label">
                            <span className="label-text">{t("form_email")}</span>

                        </label>
                        <input
                            {...register("vendor_email")}
                            type="email"
                            placeholder={t("form_email")}
                            className="input input-bordered w-full max-w-md rounded-none" />
                    </div>
                    <div className="form-control w-full max-w-md">

                        <div className="form-control rounded-none">
                            <div>
                                <Controller
                                    name="vendor_contact"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) =>
                                        <PhoneInput
                                            {...field}
                                            inputProps={{
                                                name: 'Contact',
                                                required: true,



                                            }}
                                            containerclassName="max-w-md"
                                            country={'sa'}
                                        />
                                    }
                                />

                            </div>
                        </div>


                    </div>


                    <div className="form-control w-full max-w-md">
                        <label className="label">
                            <span className="label-text">{t("l_p")}</span>

                        </label>
                        <input
                            {...register("vendor_password")}
                            type="password" placeholder={t("l_p_p")} className="input input-bordered w-full max-w-md rounded-none" required />


                    </div>
                    <div className="form-control w-full max-w-md">
                        <label className="label">
                            <span className="label-text">{t("l_c_p_p")}</span>

                        </label>
                        <input {...register("vendor_password2")} type="password" placeholder={t("l_p_p")} className="input input-bordered w-full max-w-md rounded-none" required />
                    </div>
                    <small className="text-red-500">
                        {error && error}
                    </small>
                    <div className="flex justify-center gap-x-4">

                        <Link to={'/admin/vendors'} className="btn btn-sm btn-error max-w-md text-white rounded">{t("Cancel")}</Link>
                        <button className="btn btn-sm btn-primary max-w-md text-white rounded">{t("Save")}</button>
                    </div>
                </form>


            </div>
        </>
    );
};

export default AddVendor;