import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaArrowCircleLeft } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import getDataById from '../../../hooks/getDataById';
import ImageUploading from "react-images-uploading";
import ApiLoadingSpinner from '../../../components/utils/ApiLoadingSpinner';

const AdminEditPlan = () => {
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]);
    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
    };

    const user = JSON.parse(localStorage.getItem('user'))
    const navigate = useNavigate()
    const { register, setValue, handleSubmit, formState: { errors } } = useForm();
    const { id } = useParams();

    useEffect(() => {
        const getSinglePlan = async () => {
            const response = await getDataById("admin/getbyidplan", id);
            if (response.status_code === 200) {
                const { plan_name, plan_description, plan_price, product_limit, customer_limit, storage_limit, no_of_days } = response.data
                setValue("plan_name", plan_name)
                setValue("plan_description", plan_description)
                setValue("plan_price", plan_price)
                setValue("product_limit", product_limit)
                setValue("customer_limit", customer_limit)
                setValue("storage_limit", storage_limit)
                setValue("no_of_days", no_of_days)

            }
        }
        getSinglePlan();
    }, [id])

    const onSubmit = async (data) => {
        setLoading(true)
        const planInfo = new FormData();
        if (images[0]?.file) {
            planInfo.append("plan_logo", images[0]?.file)
        }
        planInfo.append("id", id)
        planInfo.append("plan_name", data.plan_name)
        planInfo.append("plan_description", data.plan_description)
        planInfo.append("plan_price", data.plan_price)
        planInfo.append("product_limit", data.product_limit)
        planInfo.append("customer_limit", data.customer_limit)
        planInfo.append("storage_limit", data.storage_limit)
        planInfo.append("no_of_days", data.no_of_days)

        const res = await fetch(`${process.env.REACT_APP_APIURL}/admin/updateplan`, {
            method: 'PATCH',
            // body: JSON.stringify(data),
            body: planInfo,
            headers: {
                // 'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }

        })
        const response = await res.json();
        // const response = undefined
        if (response?.status_code === 200) {
            toast.success("Plan updated Successfully");
            setLoading(false)
            navigate("/admin/plan/all-plans");
        }
        else {
            toast.error("Something went Wrong")
        }
    }
    if (loading) {
        return <ApiLoadingSpinner />
    }

    return (
        <>
            <div className="flex gap-x-4">
                <h2 className="text-title text-lg md:text-2xl pb-2 uppercase">Edit Plan</h2>
                <Link to={'/admin/plan/all-plans'}>
                    <button className="btn btn-primary btn-xs text-white mb-5 flex justify-center items-center gap-x-2 mt-1 rounded">
                        <FaArrowCircleLeft size={15} />
                        Go Back
                    </button>
                </Link>
            </div>
            <div className="mt-2 mb-16">
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">

                        <div className="md:col-span-2">

                            <div className="border bg-white">

                                <div className="py-5 px-5">

                                    <div className="form-group mb-5">
                                        <div className="grid grid-cols-1 gap-y-3">
                                            <div>
                                                <label className="text-[13px]" htmlFor="planName">Plan Name <span className="text-error">*</span></label>
                                            </div>
                                            <div>
                                                <input className="w-full h-[40px] input input-bordered rounded" placeholder="Plan Name" {...register("plan_name", { required: true })} />
                                                {errors.planName?.type === 'required' && "Plan name is required"}
                                            </div>

                                            <div className="border bg-white my-5">
                                                <div className="card-title border-b p-4">
                                                    <h3 className="text-sm font-medium">
                                                        Plan Logo{" "}
                                                    </h3>
                                                </div>
                                                <div className="py-5 px-5">
                                                    <div className="col-span-8">
                                                        <ImageUploading
                                                            value={images}
                                                            onChange={onChange}
                                                            dataURLKey="data_url"
                                                        >
                                                            {({
                                                                imageList,
                                                                onImageUpload,
                                                                onImageRemoveAll,
                                                                onImageUpdate,
                                                                onImageRemove,
                                                                isDragging,
                                                                dragProps,
                                                            }) => (
                                                                // write your building UI
                                                                <div className="upload__image-wrapper">
                                                                    <p
                                                                        className="text-xs cursor-pointer py-2 px-3"
                                                                        style={isDragging ? { color: "red" } : undefined}
                                                                        onClick={onImageUpload}
                                                                        {...dragProps}
                                                                    >
                                                                        Click or Drop here to Upload Image
                                                                    </p>
                                                                    &nbsp;
                                                                    {imageList.map((image, index) => (
                                                                        <div key={index} className="image-item">
                                                                            <div className="image-item__btn-wrapper">
                                                                                {/* <button onClick={() => onImageUpdate(index)}>Update</button> */}
                                                                                <button
                                                                                    className="text-red-600"
                                                                                    onClick={() => onImageRemove(index)}
                                                                                >
                                                                                    X
                                                                                </button>
                                                                            </div>
                                                                            <img
                                                                                className="w-20 h-20"
                                                                                src={image["data_url"]}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    ))}


                                                                </div>
                                                            )}
                                                        </ImageUploading>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="mt-2">
                                                <label className="text-[13px]" htmlFor="planDescription">Plan Description <span className="text-error">*</span></label>
                                            </div>
                                            <div>
                                                <input className="w-full h-[40px] input input-bordered rounded" placeholder="description"{...register("plan_description", { required: true })} />
                                                {errors.planDescription?.type === 'required' && "Plan name is required"}
                                            </div>


                                            <div className="mt-2">
                                                <label className="text-[13px]" htmlFor="planPrice">Plan Price <span className="text-error">*</span></label>
                                            </div>
                                            <div>
                                                <input type="number" className="w-full h-[40px] input input-bordered rounded" placeholder="Price"{...register("plan_price", { required: true })} />
                                                {errors.planPrice?.type === 'required' && "Plan name is required"}
                                            </div>

                                            <div className="mt-2">
                                                <label className="text-[13px]" htmlFor="productLimit">Product Limit <span className="text-error">*</span></label>
                                            </div>
                                            <div>
                                                <input type="number" className="w-full h-[40px] input input-bordered rounded" placeholder="Product Limit" {...register("product_limit", { required: true })} />
                                                {errors.productLimit?.type === 'required' && "Plan name is required"}
                                            </div>


                                            <div className="mt-2">
                                                <label className="text-[13px]" htmlFor="storageLimit">Storage Limit <span className="text-error">*</span></label>
                                            </div>
                                            <div>
                                                <input type="number" className="w-full h-[40px] input input-bordered rounded" placeholder="Storage Limit" {...register("storage_limit", { required: true })} />
                                                {errors.storageLimit?.type === 'required' && "Plan name is required"}
                                            </div>

                                            <div className="mt-2">
                                                <label className="text-[13px]" htmlFor="customerLimit">Customer Limit <span className="text-error">*</span></label>
                                            </div>
                                            <div>
                                                <input type="number" className="w-full h-[40px] input input-bordered rounded" placeholder="Customer limit"{...register("customer_limit", { required: true })} />
                                                {errors.customerLimit?.type === 'required' && "required"}
                                            </div>
                                            <div className="mt-2">
                                                <label className="text-[13px]" htmlFor="customerLimit">Number of Days <span className="text-error">*</span></label>
                                            </div>
                                            <div>
                                                <input type="number" className="w-full h-[40px] input input-bordered rounded" placeholder="Validity"{...register("no_of_days")} required />

                                            </div>

                                            <input className="py-1 mt-2 px-3 bg-primary text-white rounded cursor-pointer" type="submit" value="Update" />


                                        </div>
                                    </div>


                                </div>
                            </div>






                        </div>





                    </div>




                </form>





            </div>
        </>
    );
};

export default AdminEditPlan;