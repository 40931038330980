import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AddToCart from "./StoreComponents/AddToCart";
import ShopNavbar from "./StoreComponents/ShopNavbar";
import StoreFooter from "./StoreComponents/StoreFooter";
import ProductSlider from "./StoreComponents/productSlider/ProductSlider";
import StoreTab from "./StoreComponents/StoreTab";
import LoadingSpinner from "../../components/utils/LoadingSpinner";
import { Helmet } from "react-helmet-async";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { customerProvider } from "../../contexts/CustomerContext";
import { storeInformation } from "../../contexts/StoreInformationContext";
import { domainProvider } from "../../contexts/DomainContext";
import instance from "../../components/utils/CustomerInterceptor"
import axios from "axios"

const ProductDetails = () => {
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const [productQuantity, setProductQuantity] = useState(0);
  const [cartQuantity, setCartQuantity] = useState(0);
  const { id } = useParams();
  const [refresh, setRefresh] = useState(false);
  const { t } = useTranslation();
  const selectLanguage = i18next.language;
  const { customer } = useContext(customerProvider);
  const { storeDetails } = useContext(storeInformation);
  const storeName = storeDetails?.data?.storeDetails?.store_name
    ? storeDetails?.data?.storeDetails?.store_name
    : "";
  const { titleName } = useContext(domainProvider);

  useEffect(() => {
    const getAllProducts = async () => {
      var res;
      if (customer) {
        res = await instance.get(
          `${process.env.REACT_APP_APIURL}/vendor/store/getbyidstoreproduct/${id}`,
          {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${customer?.token}`,
            },
          }
        );
      } else {
        res = await axios.get(
          `${process.env.REACT_APP_APIURL}/vendor/store/getbyidstoreproduct/${id}`
        );
      }
      const response = await res.data;
      if (response.status_code === 200 && response.data) {
        setProduct(response.data);
        // changes start
        setProductQuantity(response.data.product_details[0].cartQuantity);
        setCartQuantity(
          response.data.product_details[0].product_stock -
            response.data.product_details[0].cartQuantity
        );
      }
      setLoading(false);
    };

    getAllProducts();
  }, [id, refresh, customer]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="">
      <ShopNavbar />
      <Helmet>
        <title>Product Details - {titleName}</title>
      </Helmet>
      <div className="Container mx-auto bg-gray-100">
        <div className="px-5 py-10">
          <div className="">
            <div className="grid grid-cols-1 lg:grid-cols-2">
              <div className="h-[635px] px-4">
                <ProductSlider images={product && product?.images} />
              </div>
              <div className="w-11/12 mx-auto">
                <h1 className="font-domain text-3xl mb-2 capitalize text-gray-800">
                  {selectLanguage === "ar" &&
                  product &&
                  product.product_details &&
                  product.product_details[0].product_name_ar
                    ? product.product_details[0].product_name_ar
                    : product && product.product_details
                    ? product.product_details[0].product_name
                    : ""}
                </h1>
                <p className="mb-4">
                  <span className="text-2xl text-gray-500 font-semibold">
                    {product && product.product_details
                      ? product.product_details[0].product_price
                      : 0}{" "}
                    ر.س
                  </span>
                </p>
                {/* changes start */}
                {product &&
                product.product_details &&
                productQuantity < product?.product_details[0].product_stock ? (
                  <>
                    <AddToCart
                      id={product?.product_details[0]?.product_id}
                      p_price={product?.product_details[0].product_price}
                      colors={product?.colors}
                      sizes={product?.sizes}
                      product={product?.product_details[0]}
                      maxQuantity={cartQuantity}
                    />
                  </>
                ) : (
                  <p className="text-red-400 font-semibold text-2xl mt-0 mb-4">
                    {selectLanguage == "ar" ? "نفدت المخزون" : "Out of Stock"}
                  </p>
                )}

                {/* changes end */}
                <div
                  style={{
                    direction: `${selectLanguage === "ar" ? "rtl" : "ltr"}`,
                  }}
                >
                  <p className="text-lg">
                    <span className="text-gray-500">
                      {t("vd_pc_Category")}{" "}
                    </span>
                    :{" "}
                    <span className="text-gray-800">
                      {selectLanguage === "ar" &&
                      product &&
                      product.product_details &&
                      product.product_details[0].category_name_ar
                        ? product.product_details[0].category_name_ar
                        : product && product.product_details
                        ? product.product_details[0].category_name
                        : ""}
                    </span>
                  </p>
                </div>
                <div>
                  <p className="text-lg">
                    <span className="text-gray-500">{t("vd_pt_Tags")} </span>:{" "}
                    <span className="text-gray-800">
                      {product &&
                        product.tags &&
                        product.tags.map(
                          (tag) =>
                            `${
                              selectLanguage == "ar" && tag.tag_name_ar
                                ? tag.tag_name_ar
                                : tag.tag_name
                            }, `
                        )}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-8 px-12">
        <div className="p-4 min-h-[320px]">
          <StoreTab
            productDetails={product}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </div>
      </div>
      <StoreFooter />
    </div>
  );
};

export default ProductDetails;
