import React, { useState } from "react";
import { Markup } from "interweave";
import { StarIcon } from "@heroicons/react/solid";
import StarRatingComponent from "react-star-rating-component";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Modal from "react-responsive-modal";
import axios from "../../../components/utils/CustomerInterceptor";

const StoreTab = ({ productDetails, refresh, setRefresh }) => {
  const [rating, setRating] = useState(0);
  const [tempRating, setTempRating] = useState(0);
  const [toggle, setToggle] = useState(1);
  const [show, setShow] = useState(false);
  const tabToggle = (idx) => {
    setToggle(idx);
  };
  const customerInfo = JSON.parse(localStorage.getItem("customer"));

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleToReview = async (data) => {
    if (!customerInfo?.token) {
      toast.info("Please Login to write a review");
      return;
    }
    const reviewData = {
      ...data,
      rating,
      product_id: productDetails.product_details[0].product_id,
    };
    const res = await axios.post(
      `${process.env.REACT_APP_APIURL}/vendor/store/customer/createproductreview`,
      JSON.stringify(reviewData),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${customerInfo.token}`,
        },
      }
    );

    const response = await res.data;
    if (response.err) {
      toast.error(response.message);
      toast.error(response.err);
      return;
    }
    toast.success(response.message);
    reset();
    setRefresh(!refresh);
    setShow(false);
  };

  const { t } = useTranslation();
  const selectLanguage = i18next.language;

  return (
    <>
      <div className="flex items-center border border-gray-300">
        <h1
          className={`mx-5 text-xl text-gray-400  py-3 px-2 cursor-pointer ${
            toggle === 1 && "text-blue-600"
          }`}
          onClick={() => tabToggle(1)}
        >
          {t("Description")}
        </h1>
        |
        <h1
          className={`mx-5 text-xl text-gray-400 py-3 px-2 cursor-pointer ${
            toggle === 2 && "text-blue-600"
          }`}
          onClick={() => tabToggle(2)}
        >
          {t("Reviews")}
        </h1>
      </div>
      <div className={`mt-8 px-16 ${toggle === 1 ? "block" : "hidden"}`}>
        <div className="text-gray-700 text-base mb-10 capitalize">
          <Markup
            content={
              selectLanguage === "ar" &&
              productDetails &&
              productDetails.product_details &&
              productDetails?.product_details[0]?.product_description_ar
                ? productDetails?.product_details[0]?.product_description_ar
                : productDetails && productDetails.product_details
                ? productDetails?.product_details[0]?.product_description
                : ""
            }
          />
        </div>
      </div>
      <div
        className={`mt-10 md:px-20 ${
          toggle === 2 ? "block" : "hidden"
        } text-gray-400`}
      >
        <div className="text-gray-900 relative">
          <div>
            <p className="text-xl text-gray-700">
              {selectLanguage === "ar"
                ? "أضف تقييمك هنا"
                : "Add Your Review Here"}
            </p>
          </div>
          {!show && (
            <div className="mt-3 mb-6">
              <button
                onClick={() => setShow(true)}
                className="btn text-white btn-primary"
              >
                {t("Add Review")}
              </button>
            </div>
          )}
          {productDetails.reviews?.length > 0 ? (
            <>
              <div>
                <p className="text-2xl text-gray-700 font-semibold">
                  {" "}
                  {t("Customer Reviews")}
                </p>
                {productDetails.reviews.reverse().map((review, idx) => (
                  <div key={idx}>
                    <div className="flex px-4 my-3 items-center bg-gray-100 shadow-md">
                      <figure className="w-20 h-20 rounded-full md:w-28 md:h-28">
                        <img
                          className=" rounded-full"
                          src={
                            review.profile_image ||
                            "https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                          }
                          alt={review.cust_fullname}
                        />
                      </figure>
                      <div className="card-body">
                        <h2 className="card-title capitalize">
                          {review.cust_fullname}
                        </h2>
                        <p className="flex items-center text-gray-600">
                          <span className="text-xl font-semibold">
                            {t("Rating")} :-
                          </span>{" "}
                          <span className="flex ml-2 font-bold">
                            <StarIcon className="w-6 text-orange-600" />
                            <span className="text-lg font-semibold">
                              {review.rating}+
                            </span>
                          </span>
                        </p>
                        <p className="capitalize">
                          {selectLanguage === "ar" && review.review_ar
                            ? review.review_ar
                            : review.review}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <div>
                <h1 className="mb-5">
                  {selectLanguage === "ar"
                    ? "لا توجد مراجعة بعد"
                    : "There are no review yet"}
                </h1>
              </div>
            </>
          )}
        </div>

        <Modal
          open={show}
          onClose={() => {
            setShow(false);
          }}
          center
          className="w-full"
          // styles={{"direction" selectLanguage:"ar" ? }}
        >
          <div>
            <form onSubmit={handleSubmit(handleToReview)}>
              <h4 className="my-2">Add Product Review</h4>
              <div className="flex flex-col my-4 w-72 sm:w-80 md:w-96">
                <span>
                  {t("Your Rating")} <span className="text-red-600">*</span>
                </span>
                <div>
                  <StarRatingComponent
                    className="text-2xl"
                    name="stars"
                    value={tempRating ? tempRating : rating}
                    starCount={5}
                    onStarClick={(nextValue, prevValue, name) => {
                      setRating(nextValue);
                    }}
                    onStarHover={(nextValue, prevValue, name) => {
                      setTempRating(nextValue);
                    }}
                    onStarHoverOut={(nextValue, prevValue, name) => {
                      setTempRating(nextValue);
                    }}
                    renderStarIcon={<StarIcon />}
                    starColor={"#FBBF24"}
                    emptyStarColor={"#989a9e"}
                    editing={true}
                  />
                </div>
              </div>

              <div className="flex flex-col my-4 w-72 sm:w-80 md:w-96">
                <span>
                  {t("Your Review")} <span className="text-red-600">*</span>
                  <textarea
                    type="text"
                    name="reviewText"
                    className="input input-bordered focus:outline-none bg-gray-100 focus:bg-sky-100 focus:border-gray-300 rounded-none w-full h-[80px] resize-none p-2"
                    {...register("review", { required: true })}
                  />
                  {errors.review?.type === "required" && (
                    <small className="text-[12px] text-error">
                      {t("r_t_r")}
                    </small>
                  )}
                </span>
              </div>

              <div className="flex flex-col my-4 w-72 sm:w-80 md:w-96">
                <span>
                  {t("Your Review")} {t("in Arabic")}{" "}
                  <textarea
                    type="text"
                    name="reviewText"
                    className="input input-bordered focus:outline-none bg-gray-100 focus:bg-sky-100 focus:border-gray-300 rounded-none w-full h-[80px] resize-none p-2"
                    {...register("review_ar")}
                  />
                </span>
              </div>

              <button
                type="submit"
                className="btn btn-primary h-[32px] my-4 w-full rounded text-white"
              >
                Submit
              </button>
            </form>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default StoreTab;
