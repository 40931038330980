import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Timeline, Grid, Row, Col } from "rsuite";
import { BiPhoneCall } from "react-icons/bi";
import { FaLessThan } from "react-icons/fa";
import LoadingSpinner from "../../components/utils/LoadingSpinner";
import Div from "../../components/utils/Div";
import { storeInformation } from "../../contexts/StoreInformationContext";
import axios from "../../components/utils/Interceptors"

const TrackPageVendor = () => {
  const { state } = useLocation();
  const { storeDetails } = useContext(storeInformation);
  const { data } = storeDetails;
  const storeName = storeDetails?.data?.storeDetails?.store_name
    ? storeDetails?.data?.storeDetails?.store_name
    : "";
  const { id, token } = state;
  const [loading, setLoading] = useState(true);
  const [trackItem, setTrackItem] = useState([]);

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_APIURL;
    const fetchAllCustomerOrder = async () => {
      const res = await axios.get(
        `${baseUrl}/vendor/trackorder?tracking_id=${id}`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const response = await res.data;
      if (response.tracking_data) {
        setTrackItem(response);
      } else {
        toast.error("Tracking Id is not Correct");
        setTrackItem(null);
      }
      setLoading(false);
    };

    fetchAllCustomerOrder();
  }, [id]);

  const AlignTimeline = () => (
    <Timeline align="alternate">
      {trackItem?.tracking_data.length > 0 ? (
        trackItem?.tracking_data?.reverse().map((i, idx) => (
          <Timeline.Item key={idx}>
            <p className="font-bold">{i.status_code}</p>
            <p>{i.event_time}</p>
            <p>{i.event_desc}</p>
          </Timeline.Item>
        ))
      ) : (
        <Timeline.Item>
          <p className="font-bold">Order Shipped</p>
          <p>Waiting for progress</p>
        </Timeline.Item>
      )}
    </Timeline>
  );

  const back = () => {
    window.history.go(-1);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="bg-gray-100 font-Roboto ">
      <div className="py-5">
        <Div>
          <div className="bg-white ">
            <div className="border-b-2">
              <h2 className="pt-3 pb-2 px-6 text-title text-lg">
                Tracking order
              </h2>
            </div>
            {trackItem ? (
              <div className="">
                <div className="my-6 px-6">
                  <div className="border-2 grid grid-cols-1  md:grid-cols-4 gap-5 px-5 py-3">
                    <div className="flex items-center">
                      <div>
                        <p className="font-semibold text-[18px]">
                          Estimated Delivery time :
                        </p>

                        {trackItem?.estimated_delivery_date ? (
                          <p>{trackItem?.estimated_delivery_date}</p>
                        ) : (
                          <p className="text-gray-600">
                            This order is cancelled
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div>
                        <p className="font-semibold text-[18px]">
                          Shipping BY :
                        </p>

                        {
                          <>
                            <p className="flex items-center gap-2">
                              {storeName}
                            </p>
                            <p className="flex items-center gap-2">
                              <BiPhoneCall /> +{data?.storeDetails?.store_phone}
                            </p>
                          </>
                        }
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div>
                        <p className="font-semibold text-[18px]">Status :</p>

                        {<p>{trackItem?.current_status}</p>}
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div>
                        <p className="font-semibold text-[18px]">
                          Tracking ID :
                        </p>

                        {<p>{id}</p>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="min-h-[20vh]">
                  <div className=" py-5">
                    <div className="w-11/12 mx-auto">
                      <Grid fluid>
                        <Row>
                          <Col xs={20}>
                            <AlignTimeline />
                          </Col>
                        </Row>
                      </Grid>
                    </div>
                  </div>
                </div>
                {/* window.history.go(-1) */}
                <div className="flex items-center gap-3 px-6 pb-5">
                  <button
                    onClick={() => back()}
                    className="btn btn-primary btn-sm rounded-sm text-white flex items-center"
                  >
                    <FaLessThan /> <span className="ml-1">Back</span>
                  </button>
                  <a href={trackItem.aws_label}>
                    <button className="btn btn-primary btn-sm rounded-sm text-white flex items-center">
                      Download Receipt
                    </button>
                  </a>
                </div>
              </div>
            ) : (
              <>
                <div className="min-h-[50vh] flex justify-center items-center bg-white">
                  <p className="text-xl md:text-3xl font-semibold md:font-bold text-gray-600">
                    No Order Found
                  </p>
                </div>
                <div className="flex items-center gap-3 px-6 pb-5">
                  <button
                    onClick={() => back()}
                    className="btn btn-primary btn-sm rounded-sm text-white flex items-center"
                  >
                    <FaLessThan /> <span className="ml-1">Back</span>
                  </button>
                </div>
              </>
            )}
          </div>
        </Div>
      </div>
    </div>
  );
};

export default TrackPageVendor;
