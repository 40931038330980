import React, { useContext } from "react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Rating } from "react-simple-star-rating";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./StoreStyle/StoreStyle.css"

import client_1 from "../../assets/client-1.jpg";
import { storeInformation } from "../../contexts/StoreInformationContext";
import i18next from "i18next";

const StoreReviews = () => {
  const { storeDetails } = useContext(storeInformation);
  const selectLanguage = i18next.language;

  SwiperCore.use([Autoplay, Pagination]);

  return (
    <>
      <div className="mx-1 hidden md:block">
        <Swiper
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          slidesPerView={2}
          spaceBetween={20}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
          style={{ paddingTop: "16px" }}
        >
          {storeDetails?.data?.reviews.map((review, idx) => {
            if (review.review) {
              return (
                <SwiperSlide
                  className="bg-red-400 shadow rounded"
                  id="reviewDiv"
                  key={idx}
                >
                  <div className="flex flex-row px-4 gap-x-4 w-full items-center justify-center">
                    <img
                      src={client_1}
                      alt="client"
                      className="w-16 h-16"
                      id="reviewImage"
                    />
                    <div className="flex flex-col items-start justify-start w-full">
                      <div className="flex flex-col flex-1">
                        <p className="text-black text-xl capitalize">
                          {selectLanguage === "ar" && review.review_ar
                            ? review.review_ar.slice(0, 100)
                            : review.review.slice(0, 100)}
                          ...
                        </p>
                        <p className="text-left mt-0">
                          <Rating
                            readonly={true}
                            initialValue={
                              selectLanguage === "ar" && review.rating_ar
                                ? review.rating_ar
                                : review.rating
                            }
                            size={18}
                          />
                        </p>
                      </div>
                      <div className="bottom-0">
                        <h1 className="text-xl text-left capitalize font-bold text-gray-800">
                          {review.cust_fullname}
                        </h1>
                        <p className="text-sm text-left text-gray-700">
                          {review?.created_on?.slice(0, 10)}
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            }
          })}
        </Swiper>
      </div>

      <div className="md:hidden">
        <Swiper
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          slidesPerView={1}
          spaceBetween={20}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          style={{ paddingTop: "16px" }}
          className="mySwiper"
        >
          {storeDetails?.data?.reviews.map((review, idx) => {
            if (review.review) {
              return (
                <SwiperSlide
                  className="bg-red-400 h-48"
                  id="reviewDiv"
                  key={idx}
                >
                  <div className="flex flex-row px-4 gap-x-4 w-full items-center justify-center">
                    <img
                      src={client_1}
                      alt="client"
                      className="w-20 h-20"
                      id="reviewImage"
                    />
                    <div className="flex flex-col items-start justify-start w-full">
                      <div className="flex flex-col flex-1">
                        <p className="text-black text-xl capitalize">
                          {selectLanguage === "ar" && review.review_ar
                            ? review.review_ar.slice(0, 100)
                            : review.review.slice(0, 100)}
                          ...
                        </p>
                        <p className="text-left mt-0">
                          <Rating
                            readonly={true}
                            initialValue={
                              selectLanguage === "ar" && review.rating_ar
                                ? review.rating_ar
                                : review.rating
                            }
                            size={18}
                          />
                        </p>
                      </div>
                      <div className="bottom-0">
                        <h1 className="text-xl text-left capitalize font-bold text-gray-800">
                          {review.cust_fullname}
                        </h1>
                        <p className="text-sm text-left text-gray-700">
                          {review?.created_on?.slice(0, 10)}
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            }
          })}
        </Swiper>
      </div>
    </>
  );
};

export default StoreReviews;
