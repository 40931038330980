import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { AiFillCheckCircle } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { FiEye } from "react-icons/fi";
import FilterComponent from "../../../components/utils/FilterComponent";

const columns = [
  {
    name: "Id",
    selector: (row) => row.id,
    sortable: true,
  },
  {
    name: "Domain Name",
    selector: (row) => row.domain_name,
    sortable: true,
  },

  {
    name: "Full Domain",
    selector: (row) => row.full_domain_name,
    sortable: true,
  },

  {
    name: "Store Id",
    selector: (row) => row.store_id,
    sortable: true,
  },
  // {
  //     name: 'User',
  //     selector: row => row.user,
  //     sortable: true,
  // },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: true,
  },

  {
    name: "Created At",
    selector: (row) => row.created_at,
    sortable: true,
  },
  {
    name: "Action",
    key: "action",
    text: "Action",
    className: "action",
    width: 100,
    align: "left",
    sortable: false,
    cell: (record) => {
      return (
        <>
          <div className="flex gap-2">
            <span
              className="p-2 flex items-center border border-primary bg-primary rounded-full text-white hover:bg-white hover:text-primary cursor-pointer duration-300 shadow-md"
              title="View"
            >
              <FaEdit />
            </span>
          </div>
        </>
      );
    },
  },
];

const data = [
  {
    id: 2,
    domainName: "rihan.aitech.sa",
    fullDomain: "http://rihan.aitech.sa",
    user: "rihan",
    status: "Pending",
    created_at: "2 Days ago",
  },
];

const AllDomain = () => {
  const [selectedData, setSelectedData] = useState();
  const user = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  const [domains, setDomains] = useState([]);

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  let filteredItems;
  if (filterText) {
    filteredItems = domains?.filter((item) =>
      item.domainName?.toLowerCase().includes(filterText?.toLowerCase())
    );
  } else {
    filteredItems = domains;
  }

  const getAllDomains = async (status_id) => {
    let url = `${process.env.REACT_APP_APIURL}/admin/getalldomain`;
    const res = await fetch(url, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });
    const response = await res.json();

    if (response.status_code === 200) {
      setDomains(response.data.reverse());
    } else {
      setDomains([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllDomains();
  }, []);

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className="flex items-center  gap-2 flex-wrap my-5">
        <div className="justify-self-start flex items-center">
          <select className="select select-sm rounded select-bordered">
            <option disabled selected>
              Choose Action
            </option>
            <option>Publish</option>
            <option>Suspend</option>
            <option>Move to Trash</option>
          </select>
          <button className="ml-5 btn-xs btn btn-primary text-white font-normal md:btn-sm rounded">
            Process
          </button>
        </div>

        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder="Filter by Domain name"
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const handleChange = (state) => {
    setSelectedData(state.selectedRows);
  };

  return (
    <>
      <h2 className="text-title text-lg md:text-2xl pb-2">All Domains</h2>
      <div className="dashboard-box">
        <DataTable
          columns={columns}
          data={filteredItems}
          // selectableRows
          pagination
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          onSelectedRowsChange={handleChange}
        />
      </div>
    </>
  );
};

export default AllDomain;
