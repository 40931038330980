import React, { useContext } from "react";
import product1 from "../../assets/demo_product1.jpg";
import product2 from "../../assets/demo_product2.jpg";
import product3 from "../../assets/demo_product3.jpg";
import product4 from "../../assets/demo_product4.jpg";
import product5 from "../../assets/demo_product5.jpg";
import product6 from "../../assets/demo_product6.jpg";
import i18next from "i18next";
import { domainProvider } from "../../contexts/DomainContext";
import { Link } from "react-router-dom";
import { BsCart2 } from "react-icons/bs";

const demoProductArray = [
  {
    product_price: "100",
    thumbnail_image: product1,
    product_name: "Man Puffer Jacket",
  },
  {
    product_price: "300",
    thumbnail_image: product2,
    product_name: "Black Jacket",
  },
  {
    product_price: "500",
    thumbnail_image: product3,
    product_name: "Short Sleeve Green Tshirt",
  },
  {
    product_price: "1000",
    thumbnail_image: product4,
    product_name: "Short Sleeve Tshirt",
  },
  {
    product_price: "1000",
    thumbnail_image: product5,
    product_name: "Man's Brown Blazer",
  },
  {
    product_price: "1000",
    thumbnail_image: product6,
    product_name: "Man's Blazer",
  },
  {
    product_price: "1000",
    thumbnail_image: product4,
    product_name: "Short Sleeve Tshirt",
  },
  {
    product_price: "1000",
    thumbnail_image: product4,
    product_name: "Short Sleeve Tshirt",
  },
];

const FeatherProduct = ({ products, storeName }) => {
  const selectLanguage = i18next.language;
  const { customdomain } = useContext(domainProvider);
  return (
    <>
      {products.length > 0 ? (
        <div className="Container mb-12">
          <div className="px-5">
            <p className="text-violet-700 text-center mb-3 font-bold">
              {selectLanguage == "ar"
                ? "أهم مبيعات هذا الأسبوع"
                : "TOP SALE ON THIS WEEK"}
            </p>
            <h2 className="text-2xl md:text-4xl font-bold font-domain text-pink-500 text-center mb-6">
              {selectLanguage == "ar"
                ? "المنتجات المميزة"
                : "Featured Products"}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 my-12 font-domain justify-items-center lg:justify-items-start">
              {products.map((item, idx) => (
                <div
                  className="single_product relative cursor-pointer bg-gray-200 pb-4 shadow-sm rounded-sm hover:shadow-md hover:scale-[1.01] ease-linear duration-300 lg:min-w-fit max-w-[360px] w-full align-self-center"
                  key={idx}
                >
                  <div className="absolute top-0 right-0 p-3 bg-gray-200 hover:bg-black hover:text-primary heart ease-in-out transition-all duration-300">
                    <p>{item.product_price} ر.س</p>
                  </div>
                  <div className="w-full h-[320px]">
                    <Link
                      to={`${
                        customdomain === "uat.aitech.com.sa"
                          ? `/${storeName}/product_details/${item.product_id}`
                          : `/product_details/${item.product_id}`
                      }`}
                      className="text-center text-gray-400"
                    >
                      <img
                        className="w-full h-full object-cover"
                        src={item.thumbnail_image}
                        alt={
                          selectLanguage == "ar" && item.product_name_ar
                            ? item.product_name_ar
                            : item.product_name
                        }
                      />
                    </Link>
                  </div>
                  <div className="">
                    <h2 className="card-title justify-center text-gray-400 hover:text-blue-500 text-[14px] my-3 capitalize">
                      <Link
                        to={`${
                          customdomain === "uat.aitech.com.sa"
                            ? `/${storeName}/product_details/${item.product_id}`
                            : `/product_details/${item.product_id}`
                        }`}
                        className="text-gray-600 text-base capitalize"
                      >
                        {selectLanguage == "ar" && item.product_name_ar
                          ? item.product_name_ar
                          : item.product_name}
                      </Link>
                    </h2>
                    <div className="card-actions justify-center items-center flex-col lg:overflow-hidden lg:h-[20px]">
                      <div className="price text-center">
                        <p>{item.product_price} ر.س</p>
                        <Link
                          to={`${
                            customdomain === "uat.aitech.com.sa"
                              ? `/${storeName}/product_details/${item.product_id}`
                              : `/product_details/${item.product_id}`
                          }`}
                          className="transition-all ease-in duration-300 text-blue-700 hover:text-blue-700 flex items-center"
                        >
                          <BsCart2
                            className={`inline mr-2 ${
                              selectLanguage == "ar" ? "ml-3" : "ml-0"
                            } `}
                          />
                          {selectLanguage == "ar"
                            ? "إضافة إلى السلة"
                            : "Add to cart"}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex justify-center content-center mt-12">
              <Link
                to={`${
                  customdomain === "uat.aitech.com.sa"
                    ? `/${storeName}/shop`
                    : `/shop`
                }`}
              >
                <button
                  className={`bg-violet-600 text-white px-10 py-3 rounded-full text-xl hover:bg-violet-900 transition duration-300 ease-in-out `}
                >
                  {selectLanguage == "ar" ? "تسوق الآن" : "Shop Now"}
                </button>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="Container mb-12">
          <div className="px-5">
            <p className="text-violet-700 text-center mb-3 font-bold">
              {selectLanguage == "ar" ? "منتج افتراضي" : "Default Product"}
            </p>
            <h2 className="text-2xl md:text-4xl font-bold font-domain text-pink-500 text-center mb-6">
              {selectLanguage == "ar" ? "المنتجات التجريبية" : "Demo products"}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 my-12 font-domain">
              {demoProductArray.map((item, idx) => (
                <div
                  className="single_product relative cursor-pointer"
                  key={idx}
                >
                  <div className="absolute top-0 right-0 p-3 bg-white hover:bg-black hover:text-primary heart">
                    <p>{item.product_price} ر.س</p>
                  </div>
                  <div className="w-full h-[367px]">
                    <Link
                      to={`${
                        customdomain === "uat.aitech.com.sa"
                          ? `/${storeName}/product_details/sample-product`
                          : `/product_details/sample-product`
                      }`}
                      className="text-center text-gray-400"
                    >
                      <img
                        className="w-full h-full object-cover"
                        src={item.thumbnail_image}
                        alt={item.product_name}
                      />
                    </Link>
                  </div>
                  <div className="">
                    <h2 className="card-title justify-center hover:text-blue-500 text-[14px] my-3 text-gray-600 text-base capitalize">
                      {item.product_name}
                    </h2>
                    <div className="card-actions justify-center items-center flex-col hidden hover:block lg:overflow-hidden lg:h-[20px]">
                      <div className="transition-all ease-in duration-300 text-blue-700 hover:text-blue-700 flex items-center price text-center">
                        <p>{item.product_price} ر.س</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FeatherProduct;
