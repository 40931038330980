import React, { useEffect } from "react";
import { AiOutlineFundView, AiFillEye } from 'react-icons/ai'
import { GrDomain } from 'react-icons/gr'
import moment from "moment";
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { TbReportSearch } from 'react-icons/tb'
import { BiTargetLock } from 'react-icons/bi'
import avatarImage from '../../assets/images/avatar_male.png'
import dashboardImg from '../../assets/images/dashboard.png'
import { Line, Bar, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,

} from "chart.js";
import { FaMoneyCheckAlt, FaRev, FaUserFriends } from "react-icons/fa";
import { MdWeb } from "react-icons/md";
import { RiStore3Fill } from "react-icons/ri";
import getAll from "../../hooks/getAll";
import { useState } from "react";
import { Link } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
);





const options221 = {
  legend: {
    display: false,
  },

  maintainAspectRatio: false
};

const data312 = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
  datasets: [
    {
      label: 'Resolved',
      fill: true,
      lineTension: 0.1,
      backgroundColor: 'rgba(38, 166, 154,0.8)',
      fillColor: 'rgba(38, 166, 154,0.8)',
      borderColor: 'rgba(38, 166, 154,0.8)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: '#fafafa',
      pointBackgroundColor: 'rgba(38, 166, 154,0.8)',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(38, 166, 154,0.8)',
      pointHoverBorderColor: '#eeeeee',
      pointHoverBorderWidth: 1,
      pointRadius: 4,
      pointHitRadius: 10,
      data: [65, 59, 50, 65, 56, 50, 60]
    },
    {
      label: 'New',
      fill: true,
      lineTension: 0.1,
      backgroundColor: 'rgba(255, 138, 101,0.8)',
      borderColor: 'rgba(255, 138, 101,0.8)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: '#fafafa',
      pointBackgroundColor: 'rgba(255, 138, 101,0.8)',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(255, 138, 101,0.8)',
      pointHoverBorderColor: '#eeeeee',
      pointHoverBorderWidth: 1,
      pointRadius: 4,
      pointHitRadius: 10,
      data: [45, 74, 60, 65, 56, 65, 70]
    }
  ]
};

const options312 = {
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  tooltips: {
    bodySpacing: 4,
    mode: "nearest",
    intersect: 0,
    position: "nearest",
    xPadding: 10,
    yPadding: 10,
    caretPadding: 10
  },
  responsive: 1,
  scales: {
    yAxes: [{
      categoryPercentage: 0.8,
      barPercentage: 0.6,
      maxBarThickness: 12,
      display: 1,
      gridLines: {
        color: "rgba(0, 0, 0, 0.01)",
      },
      ticks: {
        display: true
      }
    }],
    xAxes: [{
      display: 1,
      gridLines: {
        color: "rgba(0, 0, 0, 0.01)",
      },
      ticks: {
        display: false
      },

    }]
  },
  layout: {
    padding: { left: 0, right: 0, top: 0, bottom: 0 }
  }
};


const primaryColor = "#26a69a";
const accentColor = "#ff8a65";
const purpleColor = "#7f8ff4";


const targetData = {
  labels: ["12pm,", "3pm", "6pm", "9pm", "12am", "3am", "6am", "9am"],
  datasets: [{
    label: "Leads",
    borderColor: primaryColor,
    pointBorderColor: "#FFF",
    pointBackgroundColor: primaryColor,
    pointBorderWidth: 2,
    pointHoverRadius: 2,
    pointHoverBorderWidth: 2,
    pointRadius: 4,
    fill: true,
    backgroundColor: 'rgba(255, 99, 132, 0.5)',
    borderWidth: 2,
    data: [1140, 660, 1100, 720, 790, 1232, 860, 1120]
  },
  {
    label: "Customers",
    borderColor: accentColor,
    pointBorderColor: "#FFF",
    pointBackgroundColor: accentColor,
    pointBorderWidth: 2,
    pointHoverRadius: 2,
    pointHoverBorderWidth: 2,
    pointRadius: 4,
    fill: true,
    backgroundColor: 'rgba(255, 99, 132, 0.5)',
    borderWidth: 2,
    data: [540, 760, 700, 620, 690, 732, 660, 720]
  },
  {
    label: "Vendors",
    borderColor: purpleColor,
    pointBorderColor: "#FFF",
    pointBackgroundColor: purpleColor,
    pointBorderWidth: 2,
    pointHoverRadius: 2,
    pointHoverBorderWidth: 2,
    pointRadius: 4,
    fill: true,
    backgroundColor: 'rgba(255, 99, 132, 0.5)',
    borderWidth: 2,
    data: [740, 760, 800, 720, 690, 832, 760, 820]
  }]
}

const targetOptions = {
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  elements: {
    line: {
      tension: .4 // disables bezier curves
    }
  },
  responsive: true,
  tooltips: {
    bodySpacing: 4,
    mode: "nearest",
    intersect: 0,
    position: "nearest",
    xPadding: 10,
    yPadding: 10,
    caretPadding: 10
  },


  scales: {
    xAxes: [{
      display: 1,
      gridLines: {
        color: "rgba(0, 0, 0, 0.01)",
      },
      ticks: {
        display: false
      },

    }],
    yAxes: [{
      categoryPercentage: 0.5,
      barPercentage: 0.5,
      maxBarThickness: 6,
      display: 1,
      gridLines: {
        color: "rgba(0, 0, 0, 0.01)",
      },
      ticks: {
        display: true
      }
    }]
  },
  layout: {
    padding: { left: 0, right: 0, top: 0, bottom: 0 }
  }
};



const data2 = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  datasets: [
    {
      label: 'Invoice',
      backgroundColor: 'rgba(38, 166, 154,1)',
      borderColor: 'rgba(38, 166, 154,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(38, 166, 154,0.8)',
      hoverBorderColor: 'rgba(38, 166, 154,1)',
      data: [165, 219, 180, 221, 216, 205, 290, 280, 221, 216, 245, 130]
    }
  ]
};


const options2 = {
  legend: {
    display: false,
  },
  scales: {
    xAxes: [{
      categoryPercentage: 0.6,
      barPercentage: 0.6,
      maxBarThickness: 9,
      display: 1,
      gridLines: {
        color: "rgba(0, 0, 0, 0.01)",
      },
      ticks: {
        display: false
      },

    }],
    yAxes: [{
      categoryPercentage: 0.6,
      barPercentage: 0.6,
      maxBarThickness: 9,
      display: 1,
      gridLines: {
        color: "rgba(0, 0, 0, 0.01)",
      },
      ticks: {
        display: true
      }
    }]
  },
  maintainAspectRatio: false
};

const totalSalesOfEarningOption = {
  maintainAspectRatio: false,

  plugins: {
    legend: {
      display: false
    },
  },
  elements: {
    line: {
      tension: .4 // disables bezier curves
    }
  },
  responsive: true,



  scales: {

    xAxes: [{

      gridLines: {

        display: false,
      },

      ticks: {
        display: false
      },

    }],
  },
  layout: {
    padding: { left: -50, right: 0, top: 0, bottom: -1 }
  }
}


const totalSalesOfEarningData = {
  labels: [",", "", "", "", "", "", "", ""],
  datasets: [{
    label: "",
    borderColor: 'rgba(63,82,227,1)',
    pointBorderColor: "transparent",
    pointBackgroundColor: 'transparent',
    pointBorderWidth: 2,
    pointHoverRadius: 2,
    pointHoverBorderWidth: 2,
    pointRadius: 4,
    backgroundColor: 'rgba(255, 99, 132, 0.5)',
    borderWidth: 3,
    data: [1140, 660, 1100, 720, 790, 1232, 860, 1120]
  }]

}


const earningPerformanceOptions = {
  maintainAspectRatio: true,
  plugins: {
    legend: {
      display: false
    },
  },
  elements: {
    line: {
      tension: .4 // disables bezier curves
    }
  },
  responsive: true,


  scales: {
    xAxes: [{
      display: 1,
      gridLines: {
        color: "#FF8264",
      },
      ticks: {
        display: false
      },

    }],
    yAxes: [{
      categoryPercentage: 0.5,
      barPercentage: 0.5,
      maxBarThickness: 6,
      display: 1,
      gridLines: {
        color: "rgba(0, 0, 0, 0.01)",
      },
      ticks: {
        display: true
      }
    }]
  },
  layout: {
    padding: { left: 0, right: 0, top: 0, bottom: 0 }
  }
}

const earningPerformanceData = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      fill: true,
      label: 'Dataset 2',
      data: [100, 200, 300, 45, 480, 60, 700],
      borderColor: '#FF8264',
      backgroundColor: '#FF8264',
      borderWidth: 2,

      pointBorderWidth: 1,
      pointRadius: 4,

    },
  ],
}


const AdminDashboard = () => {
  const { t } = useTranslation()
  const [data, setData] = useState([]);
  const [getAllData, setGetAllData] = useState([])
  const [latestOrders, setLatestOrders] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));


  useEffect(() => {
    const baseUrl = process.env.REACT_APP_APIURL;
    const fetchLatestOrders = async () => {
      const res = await fetch(`${baseUrl}/vendor/getallorder`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const response = await res.json();

      if (response.status_code === 200) {
        setLatestOrders(response.data.slice(-3));
      }
    };
    const allData = async () => {
      const res = await getAll('admin/getdashboarddetails')
      if (res.status_code === 200) {
        setGetAllData(res)
      }
    }
    fetchLatestOrders()
    allData();
  }, [])

  useEffect(() => {

    const getAllPlans = async () => {

      let res = await getAll("admin/getallvendorplan");
      if (res.status_code === 200) {
        setData(res.data)
      }

    }
    getAllPlans();

  }, [])


  const columns = [


    {
      name: `${t("Order_Id")}`,
      selector: row => row.id,

    },
    {
      name: `${t("Vendor")}`,
      selector: row => row.store_name ? row.store_name : '',

    },

    {
      name: `${t("Plan")}`,
      selector: row => row.plan_name,

    },
    {
      name: `${t("Transaction_id")}`,
      selector: row => row.transaction_id,

    },


    {
      name: `${t("Status")}`,
      selector: row => (

        row.status === 1 ? <span className="badge badge-success text-white text-xs">{t("Active")}</span> : <span className="badge badge-error text-white text-xs">{t("Pending")}</span>

      ),

    },
  ]




  const data221 = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', "SEPT", "OCT", "NOV", "DEC",],
    datasets: [
      {
        label: `${t("Subscriptions")}`,
        backgroundColor: 'rgba(127, 143, 244,1)',
        borderColor: 'rgba(127, 143, 244,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(127, 143, 244,0.8)',
        hoverBorderColor: 'rgba(127, 143, 244,1)',
        // data: [265, 119, 280, 121, 316, 255, 279, 140]
        data: getAllData?.monthWiseSubscriptions_arr
      },
    ]
  };
  const data222 = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', "SEPT", "OCT", "NOV", "DEC",],
    datasets: [
      {
        label: `${t("Earnings")}`,
        backgroundColor: 'rgba(255, 138, 101,1)',
        borderColor: 'rgba(255, 138, 101,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255, 138, 101,0.8)',
        hoverBorderColor: 'rgba(255, 138, 101,1)',
        // data: [255, 279, 140, 211, 116, 265, 119, 280]
        data: getAllData?.monthWiseEarnings_arr
      },
    ]
  };

  return (
    <>
      <div>
        {/* firstRow */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 gap-y-4 mb-8 ">

          <div className="p-3 dashboard-box border border-gray-200 bg-white shadow-sm flex items-center gap-x-4 hover:scale-105 ease-in duration-500">
            <div className="bg-primary rounded p-5 text-white inline-block">
              <FaUserFriends size="30px" />
            </div>
            <div>
              <p className="text-gray-400 text-xs">{t("Vendors")}</p>
              <span className="text-xl font-medium">{getAllData?.vendors?.count}</span>
            </div>
          </div>

          <div className="p-3 dashboard-box border border-gray-200 bg-white shadow-sm flex items-center gap-x-4 hover:scale-105 ease-in duration-500">
            <div className="bg-primary rounded p-5 text-white inline-block">
              <MdWeb size="30px" />
            </div>
            <div>
              <p className="text-gray-400 text-xs">{t("Domain_Requests")}</p>
              <span className="text-xl font-medium">{getAllData?.domainrequests?.count}</span>
            </div>
          </div>
          <div className="p-3 dashboard-box border border-gray-200 bg-white shadow-sm flex items-center gap-x-4 hover:scale-105 ease-in duration-500">
            <div className="bg-primary rounded p-5 text-white inline-block">
              <FaMoneyCheckAlt size="30px" />
            </div>
            <div>
              <p className="text-gray-400 text-xs">{t("Total_Earnings")}</p>
              <span className="text-xl font-medium">${getAllData?.earnings?.total}</span>
            </div>
          </div>
          <div className="p-3 dashboard-box border border-gray-200 bg-white shadow-sm flex items-center gap-x-4 hover:scale-105 ease-in duration-500">
            <div className="bg-primary rounded p-5 text-white inline-block">
              <FaRev size="30px" />
            </div>
            <div>
              <p className="text-gray-400 text-xs">{t("Expired_Subscription")}</p>
              <span className="text-xl font-medium">{getAllData?.expiredsubscriptions?.count}</span>
            </div>
          </div>


        </div>

        {/* secondRow */}
        <div className=" mb-8">
          <div className="p-4 dashboard-box  flex flex-col border border-gray-200 bg-white shadow-sm md:col-span-2">
            <div className="flex justify-between items-center">
              <p className="text-black text-base font-bold self-start">
                {t("Latest_Orders")}
              </p>
              <Link
                to="/admin/orders"
                className="btn btn-primary btn-sm rounded text-white"
              >
                {t("View_All")}
              </Link>
            </div>
            <DataTable
              className="mt-[-25px]"
              columns={columns}
              data={data.slice(0, 3)}
              subHeader
            />
          </div>


        </div>


        {/* thirdRow */}
        {/* <div className="grid grid-cols-1  lg:grid-cols-3 gap-8  mb-8">
          <div className="p-4 px-6 dashboard-box border border-gray-200 bg-white shadow-sm gap-x-4 col-span-2">
            <div className="flex items-center justify-between gap-2">
              <h6 className="text-sm font-medium text-primary">Earning Performance </h6>
              <select className="select select-xs select-bordered rounded-full select-ghost focus:outline-none">
                <option selected>Last 7 Days</option>
                <option>15 Days</option>
                <option>30 Days</option>
                <option>365 Days</option>
              </select>
            </div>

            <div className="mt-5">
              <Line options={earningPerformanceOptions} data={earningPerformanceData} />
            </div>
          </div>

          <div className="p-4 px-6 dashboard-box border border-gray-200 bg-white shadow-sm gap-x-4">
            <div className="flex items-center justify-between gap-2">
              <h6 className="text-sm font-medium text-primary">Recent Requests</h6>

            </div>
          </div>
        </div> */}





        {/* Subscription */}
        <div className="grid grid-cols-1 md:grid-cols-2  gap-5 mb-8">
          <div className="p-4 dashboard-box flex flex-col justify-center items-center border border-gray-200 bg-white shadow-sm col-span-2">
            <p className="text-gray-500 text-sm md:text-md self-start uppercase">{t("Subscriptions")}</p>

            <div className="chart w-full md:p-5">
              <Bar
                data={data221}
                height={350}
                options={options221}
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2  gap-5">
          <div className="p-4 dashboard-box flex flex-col justify-center items-center border border-gray-200 bg-white shadow-sm col-span-2">
            <p className="text-gray-500 text-sm md:text-md self-start uppercase">{t("Earnings")}</p>

            <div className="chart w-full md:p-5">
              <Bar
                data={data222}
                height={350}
                options={options221}
              />
            </div>
          </div>
        </div>


      </div>
    </>
  );
};

export default AdminDashboard;
