import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { cartProvider } from "../../contexts/CartContext";
import { customerProvider } from "../../contexts/CustomerContext";
import ShopNavbar from "./StoreComponents/ShopNavbar";
import StoreFooter from "./StoreComponents/StoreFooter";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { domainProvider } from "../../contexts/DomainContext";
import { storeInformation } from "../../contexts/StoreInformationContext";
import axios from "../../components/utils/CustomerInterceptor";

const StoreCart = () => {
  const { customer } = useContext(customerProvider);
  const { cartRefetch, cartCount } = useContext(cartProvider);
  const [cartItems, setCartItems] = useState([]);
  const [cartTotal, setCartTotal] = useState([]);
  const [cartTotalWight, setCartTotalWight] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [hasError, setError] = useState(false);
  const navigate = useNavigate();
  const [cuppon, setCuppon] = useState("");
  const [couponAmount, setCouponAmount] = useState(0);
  const userInfo = JSON.parse(localStorage.getItem("customer"));
  const selectLanguage = i18next.language;
  const { t } = useTranslation();
  const { customdomain, titleName } = useContext(domainProvider);
  const { storeDetails } = useContext(storeInformation);
  const storeName = storeDetails?.data?.storeDetails?.store_name
    ? storeDetails?.data?.storeDetails?.store_name
    : "";

  // var sumOfTraveler = sumFromArray('Amount', traveler);
  async function fetchCart() {
    const res = await axios
      .get(
        `${process.env.REACT_APP_APIURL}/vendor/store/customer/getallcartitem`,
        {
          headers: {
            Authorization: `Bearer ${userInfo?.token}`,
          },
        }
      )
      .then((res) => {
        setCartItems(res.data.data);
        setOrderTotal(res.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  }

  useEffect(() => {
    fetchCart();
  }, []);

  const setOrderTotal = (cartItems) => {
    const subTotal = cartItems.reduce((total, product) => {
      return total + product.product_price * +product.quantity;
    }, 0);
    const totalWight = cartItems.reduce((total, product) => {
      return total + product.product_weight * +product.quantity;
      // return total + 100 * +product.quantity;
    }, 0);
    setCartTotalWight(totalWight / 1000);
    // cartItems.forEach()
    setCartTotal(subTotal);
  };

  const handleItemDelete = (ID) => {
    axios
      .delete(
        `${process.env.REACT_APP_APIURL}/vendor/store/customer/deletecartitem/${ID}`,
        {
          headers: {
            Authorization: `Bearer ${customer?.token}`,
          },
        }
      )
      .then((res) => {
        if (res.data) {
          cartRefetch();
          fetchCart();
        }
      });
  };

  const handleDec = async (ID, quantity) => {
    if (quantity > 1) {
      var newQuantity;
      const updatedItems = cartItems.map((item) => {
        if (item.id === ID) {
          newQuantity = item.quantity - 1;
          if (newQuantity < 1) {
            newQuantity = 1;
          }
          return { ...item, quantity: newQuantity };
        }
        return item;
      });

      setCartItems(updatedItems);

      handleCart(ID, newQuantity);
    }
    // fetchCart();
  };

  const handleInc = async (ID, quantity, stock) => {
    if (quantity < stock) {
      var newQuantity;
      const updatedItems = cartItems.map((item) => {
        if (item.id === ID) {
          newQuantity = item.quantity + 1;
          if (newQuantity >= item.product_stock) {
            newQuantity = item.product_stock;
          }
          return { ...item, quantity: newQuantity };
        }
        return item;
      });

      await setCartItems(updatedItems);

      handleCart(ID, newQuantity);
    }
  };

  async function handleCart(ID, newQuantity) {
    var item = cartItems.find((item) => item.id === ID);

    const updateCartItem = {
      id: item.id,
      // size: item.size_id,
      // color:item?.color_id,
      quantity: newQuantity,
    };
    await axios
      .patch(
        `${process.env.REACT_APP_APIURL}/vendor/store/customer/updatecart`,
        JSON.stringify(updateCartItem),
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${userInfo?.token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.data.status_code === 200) {
          toast.success(res.data.data.message);
          cartRefetch();
          // window.location.reload()
        }
      });
  }

  const handleCheckout = () => {
    const cartDetailObj = cartItems.map((e) => {
      return {
        id: e.product_id,
        name: e.product_name,
        quantity: e.quantity,
        size: e.size_id,
        color: e.color_id,
        price: e.product_price,
      };
    });

    let total = cartItems.reduce((total, product) => {
      return total + product.product_price * +product.quantity;
    }, 0);

    const cartDetails = {
      order_total: total,
      total_weight: cartTotalWight,
      no_of_items: cartCount,
      itemDetails: cartDetailObj,
      coupon_discount: couponAmount,
      coupon_code: cuppon,
    };
    localStorage.setItem("cart", JSON.stringify(cartDetails));
    if (customdomain === "uat.aitech.com.sa") navigate(`/${storeName}/checkout`);
    else navigate(`/checkout`);
  };

  // to show the updated price in summary
  useEffect(() => {
    if (cartItems || cartItems != undefined) {
      let total = cartItems.reduce((total, product) => {
        return total + product.product_price * +product.quantity;
      }, 0);

      setCartTotal(total);
    }
  }, [cartItems]);

  return (
    <div className="flex flex-col min-h-[100vh]">
      <ShopNavbar />

      <Helmet>
        <title>Shopping Cart - {titleName}</title>
      </Helmet>
      <div
        className="px-5 mx-auto container max-w-[1280px] md:px-10"
        style={{ flexGrow: 1 }}
      >
        <div className="py-4 container flex gap-3 items-center">
          <a
            href={storeName ? `/${storeName}` : "/"}
            className="text-primary text-base"
          >
            <i className="fas fa-home"></i>{" "}
          </a>
          <span className="text-sm text-gray-400">
            <i className="fas fa-chevron-right"></i>
          </span>
          <p className="text-gray-600 font-medium uppercase">
            {t("Shopping Cart")}
          </p>
        </div>

        <div className="container lg:grid grid-cols-12 gap-6 items-start pb-16 pt-4">
          <div className="xl:col-span-9 lg:col-span-8">
            <div className="bg-gray-200 py-2 pl-12 pr-20 xl:pr-28 mb-4 hidden md:flex">
              <p className="text-gray-600 text-center">
                {t("all_product_dataTable1")}
              </p>
              <p className="text-gray-600 text-center ml-auto mr-16 xl:mr-24 mt-0">
                {t("Quantity")}
              </p>
              <p className="text-gray-600 text-center mt-0">{t("Total")}</p>
            </div>

            <div className="space-y-4">
              {!!cartItems ? (
                cartItems?.map((item) => (
                  <div
                    className="flex items-center md:justify-between gap-4 md:gap-6 p-4 border border-gray-200 rounded flex-wrap md:flex-nowrap"
                    key={item.id}
                  >
                    <div className="w-32 flex-shrink-0">
                      <img
                        src={item.thumbnail_image}
                        className="w-full"
                        alt="product"
                      />
                    </div>

                    <div className="md:w-1/3 w-full">
                      <h2 className="text-gray-800 mb-3 xl:text-xl textl-lg font-medium uppercase">
                        {selectLanguage == "ar" && item.product_name_ar
                          ? item.product_name_ar
                          : item.product_name}
                      </h2>
                      <p className="text-primary font-semibold">
                        {t("all_product_dataTable2")} : &nbsp;{" "}
                        {item.product_price} ر.س{" "}
                      </p>
                      <p className="text-gray-500">
                        {t("add_new_product_Sizes")} : &nbsp;
                        {selectLanguage == "ar" && item.size_name_ar
                          ? item.size_name_ar
                          : item.size_name}
                      </p>
                      <p className="text-gray-500">
                        {t("add_new_product_Colors")} : &nbsp;
                        {selectLanguage == "ar" && item.color_name_ar
                          ? item.color_name_ar
                          : item.color_name}
                      </p>
                    </div>

                    <div className="flex border border-gray-300 text-gray-600 divide-x divide-gray-300">
                      <div
                        className={`h-8 w-8 text-xl flex items-center justify-center cursor-pointer select-none ${
                          selectLanguage === "ar" ? "border-l" : "border-l-0"
                        } `}
                        onClick={() => handleDec(item.id, item.quantity)}
                      >
                        -
                      </div>
                      <div className="h-8 w-10 flex items-center justify-center">
                        {item.quantity}
                      </div>
                      <div
                        className="h-8 w-8 text-xl flex items-center justify-center cursor-pointer select-none"
                        onClick={() =>
                          handleInc(item.id, item.quantity, item.product_stock)
                        }
                      >
                        +
                      </div>
                    </div>

                    <div className="ml-auto md:ml-0">
                      <p className="text-primary text-lg font-semibold">
                        {parseFloat(item.product_price * item.quantity)} ر.س{" "}
                      </p>
                    </div>
                    <div
                      className="text-gray-600 hover:text-primary cursor-pointer"
                      onClick={() => handleItemDelete(item.id)}
                    >
                      <i className="fas fa-trash"></i>
                    </div>
                  </div>
                ))
              ) : (
                <p>
                  {selectLanguage === "ar"
                    ? "لم يتم العثور على أي منتج"
                    : "No product found"}
                </p>
              )}
            </div>
          </div>

          {!!cartItems ? (
            <div className="xl:col-span-3 lg:col-span-4 border border-gray-200 px-4 py-4 rounded mt-6 lg:mt-0">
              <h4 className="text-gray-800 text-lg mb-4 font-medium uppercase">
                {cartItems.length}{" "}
                {selectLanguage === "ar" ? "ملخص الطلب" : "ORDER SUMMARY"}
              </h4>
              <div className="space-y-1 text-gray-600 pb-3 border-b border-gray-200">
                <div className="flex justify-between font-medium">
                  <p>{t("Total_Price")}</p>
                  <p>ر.س {cartTotal}</p>
                </div>
              </div>

              <button
                type="button"
                onClick={() => handleCheckout()}
                className="btn btn-primary w-full rounded text-white"
              >
                {selectLanguage === "ar"
                  ? "عملية الدفع"
                  : "Process to checkout"}
              </button>
            </div>
          ) : (
            <p></p>
          )}
        </div>
      </div>
      <StoreFooter />
    </div>
  );
};

export default StoreCart;
