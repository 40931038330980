import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Div from "../components/utils/Div";
import PolicyPage from "./PolicyPage";
import AgrementPage from "./AgrementPage";
import Footer2 from "../components/Footer/Footer2";
import Header from "../components/Header/Header";
import { domainProvider } from "../contexts/DomainContext";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  let selectLanguage = i18next.language;
  const { titleName } = useContext(domainProvider);

  return (
    <>
      <Helmet>
        <title>
          {t("login_p_policy")} - {titleName}
        </title>
      </Helmet>
      <Header navigation={true} />
      <Div>
        <PolicyPage />
        <AgrementPage />
      </Div>
      <Footer2 />
    </>
  );
};

export default PrivacyPolicy;
