import { Helmet } from "react-helmet-async";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useContext, useState } from "react";
import { domainProvider } from "../../contexts/DomainContext";
import { ArrowDropDown } from "@mui/icons-material";
import { NavLink, Outlet } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { FaBars } from "react-icons/fa";

function Guide() {
  const { t } = useTranslation();
  let selectLanguage = i18next.language;
  const { titleName } = useContext(domainProvider);

  const heading = localStorage.getItem("guideHeader");
  const [header, setHeader] = useState(heading ? heading : "");
  const [show, setShow] = useState(false);
  function handleChange(text) {
    setHeader(text);
    localStorage.setItem("guideHeader", text);
    const dd = document.getElementById("guideDropdown");
    dd.classList.remove("left-0");
    dd.classList.add("left-[-100%]");
  }

  function handleToggle() {
    setShow((prev) => !prev);
  }

  function handleShowNav() {
    const dd = document.getElementById("guideDropdown");
    dd.classList.add("left-0");
    dd.classList.remove("left-[-100%]");
  }

  function handleCloseNav() {
    const dd = document.getElementById("guideDropdown");
    dd.classList.remove("left-0");
    dd.classList.add("left-[-100%]");
  }

  return (
    <>
      <Helmet>
        <title>Guide Page - {titleName}</title>
      </Helmet>
      <Header navigation={true} />

      <div className="bg-[#F7F7F7]">
        <div className="w-full bg-[#236260] h-40 px-10 md:px-12 lg:px-16">
          <p className="flex flex-row items-center gap-x-2 text-white font-semibold text-2xl sm:text-4xl relative top-28 sm:top-24">
            <FaBars
              className="sm:hidden"
              onClick={handleShowNav}
              cursor="pointer"
            />
            {header}
          </p>
        </div>
        <div className="flex flex-row mx-auto max-w-[1280px] sm:px-8 md:px-12 lg:px-16">
          <ul
            id="guideDropdown"
            className={`bg-base-100 sm:bg-[#F7F7F7] px-8 left-[-100%] sm:left-0 sm:static w-1/2 sm:w-1/4 absolute ${
              selectLanguage === "ar" ? "border-l-2" : "border-r-2"
            } sm:px-4 h-full pt-12 z-10`}
          >
            <AiOutlineClose
              className="sm:hidden absolute right-2 top-8"
              size={24}
              onClick={handleCloseNav}
              cursor="pointer"
            />
            <li className="text-lg py-4 border-b">
              <NavLink
                className="guideLink"
                to="/how-to-create-new-online-store-with-AiTech/create-store"
                onClick={() => {
                  handleChange(`${t("Create your store")}`);
                }}
              >
                {t("Create your store")}
              </NavLink>
            </li>
            <li className="text-lg py-4 border-b">
              <NavLink
                className="guideLink"
                to="/how-to-create-new-online-store-with-AiTech/dashboard"
                onClick={() => {
                  handleChange(`${t("Dashboard")}`);
                }}
              >
                {t("Dashboard")}
              </NavLink>
            </li>
            <li className="text-lg py-4 border-b w-full">
              <span
                tabIndex="0"
                className="flex flex-row justify-between w-full items-center"
              >
                {t("products")}
                {/* <input type="checkbox" id="guideDropIcon" className="hidden" /> */}
                {/* <label for="guideDropIcon" > */}
                <ArrowDropDown
                  className="text-2xl cursor-pointer"
                  onClick={handleToggle}
                />
                {/* </label> */}
              </span>
              {show && (
                <ul
                  tabIndex={0}
                  className="z-20 text-sm rounded w-48 mt-4 px-2 transition-all ease-in duration-300"
                >
                  <li className="pb-2 border-b w-full">
                    <NavLink
                      className="guideLink"
                      to="/how-to-create-new-online-store-with-AiTech/product/list"
                      onClick={() => {
                        handleChange(`${t("Product List")}`);
                      }}
                    >
                      {t("Product List")}
                    </NavLink>
                  </li>
                  <li className="py-2 border-b w-full">
                    <NavLink
                      className="guideLink"
                      to="/how-to-create-new-online-store-with-AiTech/product/add"
                      onClick={() => {
                        handleChange(`${t("Add_Product")}`);
                      }}
                    >
                      {t("Add_Product")}
                    </NavLink>{" "}
                  </li>
                  <li className="pt-2 w-full">
                    <NavLink
                      className="guideLink"
                      to="/how-to-create-new-online-store-with-AiTech/product/manage"
                      onClick={() => {
                        handleChange(`${t("vd_pc_Category")}`);
                      }}
                    >
                      {t("vd_pc_Category")}
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
            <li className="text-lg py-4 border-b">
              <NavLink
                className="guideLink"
                to="/how-to-create-new-online-store-with-AiTech/orders"
                onClick={() => {
                  handleChange(`${t("Orders")}`);
                }}
              >
                {t("Orders")}
              </NavLink>
            </li>
            <li className="text-lg py-4 border-b">
              {" "}
              <NavLink
                className="guideLink"
                to="/how-to-create-new-online-store-with-AiTech/coupons"
                onClick={() => {
                  handleChange(`${t("Coupons")}`);
                }}
              >
                {t("Coupons")}
              </NavLink>
            </li>
            <li className="text-lg py-4 border-b">{t("Domain_Settings")}</li>
          </ul>

          <div className="py-12 w-full md:max-w-[1280px] px-8 md:pl-12">
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Guide;
