import React, { useContext, useEffect, useState } from "react";
import "./Sidebar.css";
import i18next from "i18next";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  BsCardChecklist,
  BsFillCreditCard2FrontFill,
  BsFillGearFill,
  BsSpeedometer2,
} from "react-icons/bs";
import { FcServices } from "react-icons/fc";
import { HiOutlineTemplate } from "react-icons/hi";
import {
  FaClipboardList,
  FaLanguage,
  FaTachometerAlt,
  FaUserLock,
  FaUsers,
} from "react-icons/fa";
import AdminDashboard from "../../../pages/Admin/AdminDashboard";
import Invoices from "../../../pages/Admin/Billing/Billing";
import Vendors from "../../../pages/Admin/Vendors/Vendors";
import queryIcon from "../../../assets/icons/query.svg";
import VendorSidebar from "./VendorSidebar";
import { AiOutlineUserAdd } from "react-icons/ai";
import { BsGear } from "react-icons/bs";
import { SiWebflow } from "react-icons/si";
import { domainProvider } from "../../../contexts/DomainContext";
const iconSize = 20;

const Sidebar = ({ role, sidebarToggle }) => {
  const { t } = useTranslation();
  let selectLanguage = i18next.language;
  const location = useLocation();
  const currentPath = location.pathname.split("/")[1];
  const [side, setSide] = useState("");
  const { customdomain } = useContext(domainProvider);

  useEffect(() => {
    if (selectLanguage === "ar") {
      setSide("lg:mr-[-500px]");
    } else {
      setSide("lg:ml-[-250px]");
    }
  }, [selectLanguage]);

  const userSidebarRoutes = [
    {
      title: `${t("Orders")}`,
      icon: <BsSpeedometer2 size={iconSize} />,
      path: `${customdomain === "uat.aitech.com.sa" ? "/user/orders" : "/orders"}`,
      component: "",
    },
    {
      title: `${t("Profile")}`,
      icon: <AiOutlineUserAdd size={iconSize} />,
      path: `${
        customdomain === "uat.aitech.com.sa" ? "/user/profile" : "/profile"
      }`,
      component: "",
    },
    {
      title: `${t("Change_Password")}`,
      icon: <BsGear size={iconSize} />,
      path: `${
        customdomain === "uat.aitech.com.sa"
          ? "/user/change-password"
          : "/change-password"
      }`,
      component: "",
    },
  ];
  const adminDashboardRoutes = [
    {
      title: `${t("Dashboard")}`,
      icon: <FaTachometerAlt size={iconSize} />,
      path: "/admin/dashboard",
      component: <AdminDashboard />,
    },
    {
      title: `${t("Orders")}`,
      icon: <FaClipboardList size={iconSize} />,
      path: "/admin/orders",
      component: "",
    },
    {
      title: `${t("Plans")}`,
      icon: <BsCardChecklist size={iconSize} />,
      submenu: [
        {
          title: `${t("Create")}`,
          path: "/admin/plan/create",
          component: "",
        },
        {
          title: `${t("All_Plans")}`,
          path: "/admin/plan/all-plans",
          component: "",
        },
      ],
    },
    {
      title: `${t("Vendors")}`,
      icon: <FaUsers size={iconSize} />,
      submenu: [
        {
          title: `${t("All_Vendors")}`,
          path: "/admin/vendors",
          component: <Vendors />,
        },
      ],
    },
    {
      title: `${t("Payment_Gateways")}`,
      icon: <BsFillCreditCard2FrontFill size={iconSize} />,
      path: "/admin/payment-gateway",
      component: "",
    },
    {
      title: `${t("Templates")}`,
      icon: <HiOutlineTemplate size={iconSize} />,
      path: "/admin/templates",
      component: "",
    },
    {
      title: `${t("General_Queries")}`,
      icon: (
        <img
          className="queryIcon"
          src={queryIcon}
          width={iconSize}
          height={iconSize}
          alt="query"
        />
      ),
      path: "/admin/general_query",
      component: "",
    },
    {
      title: `${t("Languages")}`,
      icon: <FaLanguage size={iconSize} />,
      submenu: [
        {
          title: `${t("Create_Language")}`,
          path: "/admin/language/create",
          component: <Invoices />,
        },
        {
          title: `${t("Manage_Language")}`,
          path: "/admin/language/manage",
          component: <hi>add invoice</hi>,
        },
      ],
    },
    {
      title: "Domains",
      icon: <SiWebflow size={iconSize} />,
      submenu: [
        {
          title: "All Domains",
          path: "/admin/domain/",
          component: <hi>visitors report</hi>,
        },
      ],
    },
    {
      title: `${t("Services")}`,
      icon: <FcServices size={iconSize} />,
      path: "/admin/settings/general_services",
    },

    {
      title: `${t("Settings")}`,
      icon: <BsFillGearFill size={iconSize} />,
      path: "/admin/settings/general-settings",
    },
  ];

  if (role === "user") {
    return (
      <div
        id="sidebar"
        className="drawer-side shadow-xl overflow-x-hidden lg:mt-2"
        dir={selectLanguage === "ar" ? "rtl" : "ltr"}
      >
        <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
        <ul className="menu w-60 bg-base-100 text-base-content px-2">
          {userSidebarRoutes.map((menu, i) => {
            if (menu?.submenu) {
              return (
                <div
                  key={i}
                  className="hover:text-primary collapse collapse-arrow"
                >
                  <input type="checkbox" className="peer" />

                  <span className="collapse-title flex items-center">
                    {menu.icon}
                    <span className="pl-3">{menu.title}</span>
                  </span>

                  <div className="collapse-content p-0 text-primary-content peer-checked:bg-gray-200 peer-checked:text-secondary-content">
                    <ul className="w-full rounded-none px-2">
                      {menu.submenu.map((submenu, i) => {
                        return (
                          <li key={i}>
                            <NavLink
                              className="navlink hover:text-primary"
                              to={submenu.path}
                            >
                              {submenu.title}
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              );
            } else {
              return (
                <li key={i}>
                  <NavLink
                    className="hover:text-black navlink hover:bg-white"
                    to={`/${currentPath}${menu.path}`}
                  >
                    {menu.icon}
                    {menu.title}
                  </NavLink>
                </li>
              );
            }
          })}
        </ul>
      </div>
    );
  } else if (role === "vendor") {
    return <VendorSidebar side={side} sidebarToggle={sidebarToggle} />;
  } else {
    return (
      <div
        id="sidebar"
        className={`drawer-side shadow-xl overflow-x-hidden duration-300 pb-12 md:pb-5 ${
          sidebarToggle && side
        }`}
      >
        {/* mt-[67px] md:mt-[89px] */}
        <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
        <ul className="menu w-64 bg-base-100 text-base-content">
          <div className="m-2 py-2 border rounded border-[#dee2e6]">
            {adminDashboardRoutes.map((menu, i) => {
              if (menu?.submenu) {
                return (
                  <div
                    key={i}
                    className="parent-dropdown rounded py-0 collapse collapse-arrow mb-1 mx-2 text-xs font-semibold"
                  >
                    <input type="checkbox" className="peer" />

                    <div className="sidemenu-parent hover:bg-primary hover:text-white  collapse-title flex gap-x-2 items-center rounded-md">
                      <span className="icon text-[#D2D2D2]">{menu.icon}</span>
                      <span className="pl-3 hover:bg-primary ">
                        {menu.title}
                      </span>
                    </div>

                    <div className="collapse-content p-0 text-primary-content  peer-checked:text-secondary-content mt-1">
                      <ul className="w-full rounded-none">
                        {menu.submenu.map((submenu, i) => {
                          return (
                            <li key={i}>
                              <NavLink
                                className="rounded-md mb-1 hover:bg-primary hover:text-white duration-200 pl-8"
                                to={submenu.path}
                              >
                                {/* <AiOutlineArrowRight className="text-secondary" /> */}
                                <div className="sidebar-circle"></div>
                                {submenu.title}
                              </NavLink>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                );
              } else {
                return (
                  <li
                    className="mb-1 mx-2 rounded-md text-xs font-semibold"
                    key={i}
                  >
                    <NavLink
                      className="sidemenu hover:bg-primary hover:text-white rounded-md gap-x-5 "
                      to={menu.path}
                    >
                      <span className="icon text-[#D2D2D2]">{menu.icon}</span>
                      {menu.title}
                    </NavLink>
                  </li>
                );
              }
            })}
          </div>
        </ul>
      </div>
    );
  }
};

export default Sidebar;
