import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingSpinner from '../../../components/utils/LoadingSpinner';
import getDataById from '../../../hooks/getDataById';

const AdminEditPaymentMethods = () => {
    const { control, register, setValue, handleSubmit, watch, formState: { errors } } = useForm();
    const user = JSON.parse(localStorage.getItem("user"))
    const navigate = useNavigate()
    const { id } = useParams()
    const [loading, setLoading] = useState(false);
    const [gatewayImage, setGatewayImage] = useState("")

    useEffect(() => {
        setLoading(true)

        const getPaymentGateway = async () => {
            const res = await getDataById("admin/getbyidpaymentgateway", id);
            if (res.status_code === 200) {
                setValue("gateway_name", res.data.gateway_name);
                setValue("description", res.data.description);
                setValue("action_url", res.data.action_url);
                setGatewayImage(res.data.gateway_image);
            }
            setLoading(false);
        }

        getPaymentGateway();
    }, [id])

    const onSubmit = async (data) => {

        
        const form = new FormData();
        form.append("id", id);
        form.append("gateway_name", data.gateway_name);
        form.append("action_url", data.action_url);
        form.append("description", data.description);
    
        if(typeof(gatewayImage) !== "string"){
          
            form.append("gateway_image", gatewayImage);
        }
       
      
        const res = await fetch(`${process.env.REACT_APP_APIURL}/admin/updatepaymentgateway`, {
            method: 'PATCH',
            body: form,
            headers: {

                'Authorization': `Bearer ${user.token}`
            }

        })
        const response = await res.json();
        // const response = undefined
        if (response?.status_code === 200) {
            toast.success("Gateway Added Successfully");

            navigate("/admin/payment-gateway");

        }
        else {
            toast.error("Something went Wrong")
        }
    }

    if(loading){
        return <LoadingSpinner/>
    }

    return (
        <>
            <h2 className="text-title text-lg md:text-2xl pb-2">Edit Gateway</h2>
            <div className="p-4 border border-gray-200 bg-white shadow-sm col-span-2 max-w-lg mx-auto">

                <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
                    <div className="form-control w-full max-w-md">
                        <label className="label">
                            <span className="label-text">Gateway Name</span>

                        </label>
                        <input
                            {...register("gateway_name")}
                            type="text"
                            placeholder="Type here"
                            required
                            className="input input-bordered w-full max-w-md rounded-none" />

                    </div>
                    <div className="form-control w-full max-w-md">
                        <label className="label">
                            <span className="label-text">Action Url</span>

                        </label>
                        <input
                            {...register("action_url")}
                            type="text"
                            placeholder="Type here"
                            required
                            className="input input-bordered w-full max-w-md rounded-none" />

                    </div>




                    <div className="form-control w-full max-w-md">
                        <label className="label">
                            <span className="label-text">Gateway Image</span>

                        </label>
                        <input
                            onChange={(e) => setGatewayImage(e.target.files[0])}
                            type="file"
                            placeholder="Type here"
                            className="input w-full max-w-md rounded-none -ml-3" />
                        {
                            gatewayImage && typeof(gatewayImage) === "string" && <img src={gatewayImage} alt="" className="w-20 h-20 object-contain mt-2" />
                        }


                    </div>
                    <div className="form-control w-full max-w-md">

                        <div className="form-control rounded-none">



                            <label className="label">
                                <span className="label-text">Description</span>

                            </label>
                            <textarea
                                {...register("description")}
                                rows="30"
                                cols="30"
                                placeholder="Type here"
                                className="input input-bordered w-full max-w-md rounded-none">

                            </textarea>


                        </div>


                    </div>


                  
                    <div className="flex justify-center gap-x-4">

                        <Link to={'/admin/payment-gateway'} className="btn btn-sm btn-error max-w-md text-white rounded">Cancel</Link>
                        <button className="btn btn-sm btn-primary max-w-md text-white rounded">Update</button>
                    </div>
                </form>


            </div>
        </>
    );
};

export default AdminEditPaymentMethods;