import React from 'react';
import { AiFillDelete, AiOutlineDownload } from 'react-icons/ai';
import { BsThreeDotsVertical } from 'react-icons/bs';
import Pagination from '../../../components/utils/Pagination';
import UploadFileModal from './UploadFileModal';

const AllmediaGrid = ({currentItems,itemsPerPage,deleteItem,files,paginate,currentPage}) => {
    return (
        <div className="mb-32">
                <div className="flex justify-between items-center">
                    <h2 className="text-title text-sm md:text-base font-medium roboto">All Uploaded Files</h2>
                    <label htmlFor="upload-file-modal" className="py-2 px-3 font-medium text-white rounded bg-primary modal-button cursor-pointer">
                        Upload New Files
                    </label>
                </div>

                <div className="my-5 bg-white border rounded">
                    <div className="header p-4 border-b md:px-8">
                        <h2 className="text-title text-sm md:text-base font-medium roboto">All Files</h2>

                    </div>

                    <div className="body uploaded-files p-4">
                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-3">
                            {
                                currentItems.map((file, i) =>
                                    <div key={i} className="single p-2 border rounded cursor-pointer">
                                        <figure className="bg-gray-200 border-b relative">
                                            <img className="object-contain w-40 h-32 mx-auto text-center" src={file.url} alt="" />

                                            <div className="dropdown dropdown-end absolute -top-4 right-2">
                                                <label tabIndex={0} className="m-1 cursor-pointer bg-red-500">
                                                    <BsThreeDotsVertical />
                                                </label>
                                                <ul tabIndex={0} className="dropdown-content menu p-1 shadow bg-base-100 rounded w-52">
                                                    {/* <li>
                                                        <a className="flex items-center" href="/">
                                                            <AiOutlineInfoCircle />
                                                            Details Info
                                                        </a>
                                                    </li> */}
                                                    <li>
                                                        <a className="flex items-center" href={file.url} rel="noreferrer" target="_blank">
                                                            <AiOutlineDownload />
                                                            Download
                                                        </a>
                                                    </li>
                                                    {/* <li>
                                                        <a className="flex items-center" href="/">
                                                            <HiOutlineClipboardCopy />
                                                            Copy Link
                                                        </a>
                                                    </li> */}
                                                    <li>
                                                        <p onClick={() => deleteItem(file.key)} className="text-xs flex items-center" >
                                                            <AiFillDelete />
                                                            Delete
                                                        </p>
                                                    </li>


                                                </ul>
                                            </div>
                                        </figure>

                                        <div className="title pt-2">
                                            <h2 className="text-xs md:text-sm font-semibold">
                                                <span className="truncate text-ellipsis">{file.key.split(".")[0].slice(13, 20)}... .{file.key.split(".")[1]}</span>

                                            </h2>
                                            <small className="text-gray-400 text-[10px]">745kb</small>
                                        </div>
                                    </div>)
                            }

                        </div>
                    </div>

                </div>

                <Pagination itemsPerPage={itemsPerPage} totalItems={files.length} paginate={paginate} currentPage={currentPage} />

                {/* upload file modal */}
                <UploadFileModal />
            </div>
    );
};

export default AllmediaGrid;