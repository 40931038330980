import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import Footer2 from "../components/Footer/Footer2";
import Header from "../components/Header/Header";
import Div from "../components/utils/Div";
import AgrementPage from "./AgrementPage";
import { domainProvider } from "../contexts/DomainContext";

const Agrement = () => {
  const { titleName } = useContext(domainProvider);
  return (
    <>
      <Helmet>
        <title>Usage Agreement - {titleName}</title>
      </Helmet>
      <Header navigation={true} />
      <Div>
        <AgrementPage />
      </Div>
      <Footer2 />
    </>
  );
};

export default Agrement;
