import React, { useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { AiFillCheckCircle } from 'react-icons/ai';
import { FiEye } from 'react-icons/fi';
import FilterComponent from '../../../components/utils/FilterComponent';


const columns = [
    {
        name: 'Name',
        selector: row => row.vendorName,
        sortable: true,
    },

    {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
    },

    {
        name: 'Domain',
        selector: row => row.domain,
        sortable: true,
    },
    {
        name: 'Plan',
        selector: row => row.plan,
        sortable: true,
    },
    {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
    },
    {
        name: 'Join At',
        selector: row => row.joined_at,
        sortable: true,
    },
    {
        name: 'Action',
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
            return (
                <>
                    <div className="flex gap-2">

                        <span className="p-2 flex items-center border border-primary hover:bg-primary rounded-full text-primary hover:text-white cursor-pointer duration-300" title='View'>
                            <FiEye />
                        </span>

                        <span className="p-2 flex items-center border border-success hover:bg-success rounded-full text-success hover:text-white cursor-pointer duration-300" title='Approve'>
                            <AiFillCheckCircle />
                        </span>
                
                    </div>
                </>
            );
        },
    },


];

const data = [

    { id: 2, vendorName: "Anthony Washington", email: "awashington0@cafepress.com", domain: "test.aitech.sa", plan: 'Free Trial', status: "Suspended", joined_at: "16-09-22" },

];

const SuspendedVendors = () => {
    const [selectedData, setSelectedData] = useState();


    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    let filteredItems = data.filter(
        item => item.vendorName.toLowerCase().includes(filterText.toLowerCase())
    );



    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <div className="flex items-center  gap-2 flex-wrap my-5">
                <div className="justify-self-start flex items-center">
                    <select className="select select-sm rounded select-bordered">
                        <option disabled selected>Choose Action</option>
                        <option>Punlish</option>
                        <option>Suspend</option>
                        <option>Move to Trash</option>

                    </select>
                    <button className="ml-5 btn-xs btn btn-primary text-white font-normal md:btn-sm rounded">Process</button>
                </div>



                <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholder="Filter by name" />
            </div>
        );
    }, [filterText, resetPaginationToggle]);


    const handleChange = (state) => {
        setSelectedData(state.selectedRows);
    };

    return (
        <>
            <h2 className="text-title text-lg md:text-2xl pb-2">Vendors</h2>
            <div className="dashboard-box">


                <DataTable
                    columns={columns}
                    data={filteredItems}
                    // selectableRows
                    pagination
                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}

                    onSelectedRowsChange={handleChange}
                />

            </div>
        </>
    );
};

export default SuspendedVendors;