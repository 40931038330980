import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingSpinner from '../../../components/utils/LoadingSpinner';
import getDataById from '../../../hooks/getDataById';
import ImageUploading from "react-images-uploading";
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const EditLanguage = () => {
    const { t } = useTranslation()
    const { register, setValue, handleSubmit, formState: { errors } } = useForm();
    const user = JSON.parse(localStorage.getItem("user"))
    const navigate = useNavigate()
    const { id } = useParams()
    const [loading, setLoading] = useState(false)
    const [images, setImages] = useState([]);
    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
    };

    useEffect(() => {
        setLoading(true)

        const getPaymentGateway = async () => {
            const res = await getDataById("admin/getbyidlanguage", id);
           
            if (res.status_code === 200) {
                setValue("language_short_name", res.data.language_short_name);
                setValue("language_full_name", res.data.language_full_name);

            }
            setLoading(false);
        }

        getPaymentGateway();
    }, [id])

    const onSubmit = async (data) => {
        setLoading(true)
        const languInfo = new FormData();
        if (images[0]?.file) {
            languInfo.append("image", images[0]?.file)
        }
        languInfo.append("id", id);
        languInfo.append("language_short_name", data.language_short_name);
        languInfo.append("language_full_name", data.language_full_name);

        const res = await fetch(`${process.env.REACT_APP_APIURL}/admin/updatelanguage`, {
            method: 'PATCH',
            // body: JSON.stringify(data),
            body: languInfo,
            headers: {
                // 'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }

        })
        const response = await res.json();

        if (response?.status_code === 200) {
            toast.success(response.message);
            setLoading(false)
            navigate("/admin/language/manage");

        }
        else {
            toast.error(response?.message || response?.err)
        }
    }

    if (loading) {
        return <LoadingSpinner />
    }

    return (
        <>
            <Helmet>
                <title>{t('Admin')} - {t("Edit_Language")}</title>
            </Helmet>
            <h2 className="text-title text-lg md:text-2xl pb-2">{t("Edit_Language")}</h2>
            <div className="p-4 border border-gray-200 bg-white shadow-sm col-span-2 max-w-lg mx-auto">

                <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
                    <div className="form-control w-full max-w-md">
                        <label className="label">
                            <span className="label-text">{t("add_Language_srt")}</span>

                        </label>
                        <input
                            {...register("language_short_name")}
                            type="text"
                            placeholder={t("add_Language_srt")}
                            required
                            className="input input-bordered w-full max-w-md rounded-none" />

                    </div>

                    <div className="border my-5">
                        <div className="card-title border-b p-4">
                            <h3 className="text-sm font-medium">
                                {t("Language_county_Image")} {" "}
                                <span className="text-2xs text-gray-400"> (  )</span>{" "}
                            </h3>
                        </div>
                        <div className="py-5 bg-white  px-5">
                            <div className="col-span-8">
                                <ImageUploading
                                    value={images}
                                    onChange={onChange}
                                    dataURLKey="data_url"
                                >
                                    {({
                                        imageList,
                                        onImageUpload,
                                        onImageRemoveAll,
                                        onImageUpdate,
                                        onImageRemove,
                                        isDragging,
                                        dragProps,
                                    }) => (
                                        // write your building UI
                                        <div className="upload__image-wrapper">
                                            <p
                                                className="text-xs cursor-pointer py-2 px-3"
                                                style={isDragging ? { color: "red" } : undefined}
                                                onClick={onImageUpload}
                                                {...dragProps}
                                            >
                                                {t("add_new_product_T_img_info")}
                                            </p>
                                            &nbsp;
                                            {imageList.map((image, index) => (
                                                <div key={index} className="image-item">
                                                    <div className="image-item__btn-wrapper">
                                                        {/* <button onClick={() => onImageUpdate(index)}>Update</button> */}
                                                        <button
                                                            className="text-red-600"
                                                            onClick={() => onImageRemove(index)}
                                                        >
                                                            X
                                                        </button>
                                                    </div>
                                                    <img
                                                        className="w-20 h-20"
                                                        src={image["data_url"]}
                                                        alt=""
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </ImageUploading>
                            </div>
                        </div>
                    </div>

                    <div className="form-control w-full max-w-md">
                        <label className="label">
                            <span className="label-text">{t("Language_full_name")}</span>

                        </label>
                        <input
                            {...register("language_full_name")}
                            type="text"
                            placeholder={t("Language_full_name")}
                            required
                            className="input input-bordered w-full max-w-md rounded-none" />

                    </div>

                    <div className="flex justify-center gap-x-4">

                        <Link to={'/admin/language/manage'} className="btn btn-sm btn-error max-w-md text-white rounded">{t("Cancel")}</Link>
                        <button className="btn btn-sm btn-primary max-w-md text-white rounded">{t("vd_pc_btn2")}</button>
                    </div>
                </form>


            </div>
        </>
    );
};

export default EditLanguage;