import React, { useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import moment from 'moment'; // Example for onSort prop
import { Link } from 'react-router-dom';
import FilterComponent from '../../../components/utils/FilterComponent';



const data = [
    { id: 1, name: "Helen Banks", email: "hbanks0@netw.org", phone: "386-(842)278-0044", country: "Saudi Arabia", city: "city name", gender: "male" },

];

const AdminCustomers = () => {
    const { t } = useTranslation()
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data.filter(
        item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
    );

    const columns = [
        {
            name: `${t("vd_pc_C_ID")}`,
            selector: row => row.id,
            sortable: true,
        },

        {
            name: `${t("form_name")}`,
            selector: row => row.name,
            sortable: true,
        },

        {
            name: `${t("form_email")}`,
            selector: row => row.email,

        },
        {
            name: `${t("Cus_phone")}`,
            selector: row => row.phone,

        },
        {
            name: `${t("Cus_country")}`,
            selector: row => row.country,
            sortable: true,
        },
        {
            name: `${t("Cus_city")}`,
            selector: row => row.city,
            sortable: true,
        },

        {
            name: `${t("Cus_gender")}`,
            selector: row => row.gender,

        },

    ];

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);

    return (
        <>
            <h2 className="text-title text-lg md:text-2xl pb-2">{t("customers")}</h2>
            <div className="flex justify-end my-2">
                <Link to="/admin/add-customer" className="ml-5 btn btn-secondary text-white font-normal btn-sm">{t("add_customers")}</Link>
                <button onClick={() => alert("This will work after database connection")} className="ml-5 btn btn-primary text-white font-normal btn-sm">{t("all_product_Import")}</button>
                <button onClick={() => alert("This will work after database connection")} className="ml-5 btn btn-dark text-white font-normal btn-sm">{t("all_product_Export")}</button>
            </div>
            <div>


                <DataTable
                    columns={columns}
                    data={filteredItems}
                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    // selectableRows
                    pagination
                />

            </div>
        </>
    );
};

export default AdminCustomers;