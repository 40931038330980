import React from "react";
import StoreReviews from "./StoreReviews";
import { Rating } from "react-simple-star-rating";
import i18next from "i18next";
import client_1 from "../../assets/client-1.jpg";
const url =
  "https://megamart.pythonistindia.com/uploaded_files/about_us_page/630fb92a748991661974826.png";

const FeedBack = ({ average }) => {
  let rating = average?.toFixed(1);
  const selectLanguage = i18next.language;

  const feedbacks = [
    {
      name: "John Kim",
      feedback: `${
        selectLanguage === "ar"
          ? "لوريم إيبسوم هو نص عربي عشوائي يستخدم في تصاميم الغرافيك وبرمجيات الويب لملء الفراغات بشكل مؤقت."
          : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo."
      }`,
    },
    {
      name: "John Kim",
      feedback: `${
        selectLanguage === "ar"
          ? "لوريم إيبسوم هو نص عربي عشوائي يستخدم في تصاميم الغرافيك وبرمجيات الويب لملء الفراغات بشكل مؤقت."
          : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo."
      }`,
    },
    {
      name: "John Kim",
      feedback: `${
        selectLanguage === "ar"
          ? "لوريم إيبسوم هو نص عربي عشوائي يستخدم في تصاميم الغرافيك وبرمجيات الويب لملء الفراغات بشكل مؤقت."
          : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo."
      }`,
    },
  ];

  return (
    <>
      <div
        className={`Container ${
          !average && "hidden"
        } bg-gradient-to-t from-sky-50 via-gray-50 to-gray-100 font-domain`}
      >
        <div className="pt-8 px-8 md:px-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 justify-items-center">
            <div className="text-center lg:text-start">
              <p className="text-violet-700 font-bold my-4 tracking-wide text-base">
                {selectLanguage === "ar" ? "شهادات العملاء" : "Testimonials"}
              </p>
              <span className="text-4xl font-bold text-pink-500">
                {selectLanguage === "ar"
                  ? "ماذا يقول عملاؤنا..."
                  : "What Our Clients Says"}
              </span>
            </div>
            <div className="flex justify-center content-center flex-col text-center text-gray-950	">
              <span className="text-7xl my-0">{rating}</span>
              <Rating readonly={true} initialValue={rating} size={20} />
              <span className="text-2xl font-bold mt-3">
                {selectLanguage === "ar"
                  ? "متوسط تقييم العملاء"
                  : "Average Customer Rating"}
              </span>
            </div>
          </div>
          <StoreReviews />
        </div>
      </div>

      <div
        className={`Container ${
          average && "hidden"
        } bg-gradient-to-t from-sky-50 via-gray-50 to-gray-100 font-domain`}
      >
        <div className="flex flex-row px-4 pt-8">
          <div className="flex-col w-1/2">
            <p className="text-violet-700 font-bold my-4 tracking-wide text-base">
              {selectLanguage === "ar" ? "شهادات العملاء" : "Testimonials"}
            </p>

            <p className="text-4xl font-bold text-pink-500 font-poppin mb-2">
              {" "}
              {selectLanguage === "ar"
                ? "ماذا يقول عملاؤنا..."
                : "What Our Clients Says"}
            </p>
            <p className="text-gray-500">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
              tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
            </p>
          </div>
          <div className="flex justify-center content-center flex-col text-center text-gray-950	w-1/2">
            <span className="text-7xl">4</span>
            <span className="relative bottom-7">
              <Rating readonly={true} initialValue={4} size={20} />
            </span>
            <p className="text-2xl font-bold mt-[-24px]">
              {selectLanguage === "ar"
                ? "متوسط تقييم العملاء"
                : "Average Customer Rating"}
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 py-12 pr-6 pl-12">
          {feedbacks?.map((feed, ind) => {
            return (
              <div
                key={ind}
                className="card w-11/12 px-4 py-6 shadow-inner border-r-2"
              >
                <p className="text-lg text-gray-600">{feed.feedback}</p>
                <span className="flex flex-row my-4 items-center">
                  <img
                    src={client_1}
                    alt="client"
                    className="w-32 h-32 rounded-br-[60px]"
                  />
                  <span className="mx-2 text-lg font-semibold">
                    {feed.name}
                  </span>
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default FeedBack;
