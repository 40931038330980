import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaArrowCircleLeft } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ImageUploading from "react-images-uploading";
import ApiLoadingSpinner from '../../../components/utils/ApiLoadingSpinner';
import { useTranslation } from 'react-i18next';

const AdminCreatePlan = () => {
    const { t } = useTranslation()
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'))
    const navigate = useNavigate()
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
    };
    const onSubmit = async (data) => {
        setLoading(true)
        const planInfo = new FormData();
        if (images[0]?.file) {
            planInfo.append("plan_logo", images[0]?.file)
        }
        planInfo.append("plan_name", data.plan_name);
        planInfo.append("plan_description", data.plan_description);
        planInfo.append("plan_price", data.plan_price);
        planInfo.append("product_limit", data.product_limit);
        planInfo.append("storage_limit", data.storage_limit);
        planInfo.append("customer_limit", data.customer_limit);
        planInfo.append("no_of_days", data.no_of_days);

        const res = await fetch(`${process.env.REACT_APP_APIURL}/admin/createplan`, {
            method: 'POST',
            // body: JSON.stringify(data),
            body: planInfo,
            headers: {
                // 'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }

        })
        const response = await res.json();
        // const response = undefined

        if (response?.status_code === 200) {
            toast.success("Plan added Successfully");
            setLoading(false)
            navigate("/admin/plan/all-plans");
        }
        else {
            toast.error(response.message || response.err)
        }
        setLoading(false)
    }
    if (loading) {
        return <ApiLoadingSpinner />
    }

    return (
        <>
            <div className="flex gap-x-4">
                <h2 className="text-title text-lg md:text-2xl pb-2 uppercase">{t("Add_New_Plan")}</h2>
                <Link to={'/admin/plan/all-plans'}>
                    <button className="btn btn-primary btn-xs text-white mb-5 flex justify-center items-center gap-x-2 mt-1 rounded">
                        <FaArrowCircleLeft size={15} />
                        {t("Go_Back")}
                    </button>
                </Link>
            </div>
            <div className="mt-2 mb-16">
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">

                        <div className="md:col-span-2">

                            <div className="border bg-white">

                                <div className="py-5 px-5">

                                    <div className="form-group mb-5">
                                        <div className="grid grid-cols-1 gap-y-3">
                                            <div>
                                                <label className="text-[13px]" htmlFor="planName">{t("Plan_required")} <span className="text-error">*</span></label>
                                            </div>
                                            <div>
                                                <input className="w-full h-[40px] input input-bordered rounded" placeholder={t("Plan_Name")} {...register("plan_name", { required: true })} />
                                                {errors.planName?.type === 'required' && `${t("Plan_required")}`}
                                            </div>
                                            <div className="border bg-white my-5">
                                                <div className="card-title border-b p-4">
                                                    <h3 className="text-sm font-medium">
                                                        {t("Plan_Logo")}{" "}
                                                    </h3>
                                                </div>
                                                <div className="py-5 px-5">
                                                    <div className="col-span-8">
                                                        <ImageUploading
                                                            value={images}
                                                            onChange={onChange}
                                                            dataURLKey="data_url"
                                                        >
                                                            {({
                                                                imageList,
                                                                onImageUpload,
                                                                onImageRemoveAll,
                                                                onImageUpdate,
                                                                onImageRemove,
                                                                isDragging,
                                                                dragProps,
                                                            }) => (
                                                                // write your building UI
                                                                <div className="upload__image-wrapper">
                                                                    <p
                                                                        className="text-xs cursor-pointer py-2 px-3"
                                                                        style={isDragging ? { color: "red" } : undefined}
                                                                        onClick={onImageUpload}
                                                                        {...dragProps}
                                                                    >
                                                                        {t("add_new_product_T_img_info")}
                                                                    </p>
                                                                    &nbsp;
                                                                    {imageList.map((image, index) => (
                                                                        <div key={index} className="image-item">
                                                                            <div className="image-item__btn-wrapper">
                                                                                {/* <button onClick={() => onImageUpdate(index)}>Update</button> */}
                                                                                <button
                                                                                    className="text-red-600"
                                                                                    onClick={() => onImageRemove(index)}
                                                                                >
                                                                                    X
                                                                                </button>
                                                                            </div>
                                                                            <img
                                                                                className="w-20 h-20"
                                                                                src={image["data_url"]}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    ))}


                                                                </div>
                                                            )}
                                                        </ImageUploading>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mt-2">
                                                <label className="text-[13px]" htmlFor="planDescription">{t("Plan_Description")} <span className="text-error">*</span></label>
                                            </div>
                                            <div>
                                                <input className="w-full h-[40px] input input-bordered rounded" placeholder={t("Plan_Description")} {...register("plan_description", { required: true })} />
                                                {errors.planDescription?.type === 'required' && `${t("Description_required")}`}
                                            </div>


                                            <div className="mt-2">
                                                <label className="text-[13px]" htmlFor="planPrice">{t("Plan_Price")} <span className="text-error">*</span></label>
                                            </div>
                                            <div>
                                                <input type="number" className="w-full h-[40px] input input-bordered rounded" placeholder={t("all_product_dataTable2")} {...register("plan_price", { required: true })} />
                                                {errors.planPrice?.type === 'required' && `${t("Price_required")}`}
                                            </div>

                                            <div className="mt-2">
                                                <label className="text-[13px]" htmlFor="productLimit">{t("Product_Limit")} <span className="text-error">*</span></label>
                                            </div>
                                            <div>
                                                <input type="number" className="w-full h-[40px] input input-bordered rounded" placeholder={t("Product_Limit")} {...register("product_limit", { required: true })} />
                                                {errors.productLimit?.type === 'required' && `${t("Product_Limit_required")}`}
                                            </div>


                                            <div className="mt-2">
                                                <label className="text-[13px]" htmlFor="storageLimit">{t("Storage_Limit")} <span className="text-error">*</span></label>
                                            </div>
                                            <div>
                                                <input type="number" className="w-full h-[40px] input input-bordered rounded" placeholder={t("Storage_Limit")} {...register("storage_limit", { required: true })} />
                                                {errors.storageLimit?.type === 'required' && `${t("Limit_required")}`}
                                            </div>

                                            <div className="mt-2">
                                                <label className="text-[13px]" htmlFor="customerLimit">{t("Customer_Limit")} <span className="text-error">*</span></label>
                                            </div>
                                            <div>
                                                <input type="number" className="w-full h-[40px] input input-bordered rounded" placeholder={t("Customer_Limit")} {...register("customer_limit", { required: true })} />
                                                {errors.customerLimit?.type === 'required' && `${t("C_Limit_required")}`}
                                            </div>

                                            <div className="mt-2">
                                                <label className="text-[13px]" htmlFor="customerLimit">{t("Number_of_Days")} <span className="text-error">*</span></label>
                                            </div>
                                            <div>
                                                <input type="number" className="w-full h-[40px] input input-bordered rounded" placeholder={t("add_new_product_price_place")} {...register("no_of_days")} />

                                            </div>

                                            <input className="py-1 mt-2 px-3 bg-primary text-white rounded cursor-pointer" type="submit" value={t("Save")} />
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default AdminCreatePlan;