import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { AiFillCheckCircle, AiFillMinusCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import FilterComponent from "../../../components/utils/FilterComponent";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { confirmAlert } from "react-confirm-alert";
import deleteDataById from "../../../hooks/deleteDataById";
import { useTranslation } from "react-i18next";
import updateData from "../../../hooks/updateData";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import axios from "../../../components/utils/Interceptors"

const Customers = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  let filteredItems = customers.filter(
    (item) =>
      item.cust_fullname.toLowerCase().includes(filterText.toLowerCase()) ||
      item.cust_email.toLowerCase().includes(filterText.toLowerCase())
  );

  const columns = [
    {
      name: `${t("Full_Name")}`,
      selector: (row) => row.cust_fullname,
      sortable: true,
    },

    {
      name: `${t("form_email")}`,
      selector: (row) => row.cust_email,
    },
    {
      name: `${t("Cus_phone")}`,
      selector: (row) => row.cust_phone,
    },
    {
      name: `${t("Action")}`,
      sortable: true,
      cell: (row) => (
        <div className="flex gap-2">
          <Link
            to={`/vendor/customer/edit/${row.cust_id}`}
            className="p-2 flex items-center border border-secondary hover:bg-secondary rounded-full text-secondary hover:!text-white cursor-pointer duration-300"
            title={`${t("Edit")}`}
          >
            <AiOutlineEdit />
          </Link>
          <span
            onClick={() => {
              deleteItem(row.cust_id);
            }}
            className="p-2 flex items-center border border-error hover:bg-error rounded-full text-error hover:text-white cursor-pointer duration-300"
            title={`${t("Delete")}`}
          >
            <AiOutlineDelete />
          </span>
          {row.status === 0 ? (
            <span
              onClick={() => changeVendorStatus(row.cust_id, row.status)}
              className="p-2 flex items-center border border-green-700 hover:bg-green-700 rounded-full text-green-700 hover:text-white cursor-pointer duration-300"
              title={`${t("unsuspend")}`}
            >
              <AiFillCheckCircle />
            </span>
          ) : (
            <span
              onClick={() => changeVendorStatus(row.cust_id, row.status)}
              className="p-2 flex items-center border border-red-700 hover:bg-red-700 rounded-full text-red-700 hover:text-white cursor-pointer duration-300"
              title={`${t("Suspend")}`}
            >
              <AiFillMinusCircle />
            </span>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    const getAllCustomers = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getallcustomer`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      const result = await response.data;
      if (result.status_code === 200 && result.data) {
        setCustomers(result.data);
      }
    };
    getAllCustomers();
  }, []);

  const changeVendorStatus = async (id, status) => {
    setLoading(true);
    const statusValue = status ? 0 : 1;
    const res = await updateData("vendor/updatecustomer", {
      id: id,
      status: statusValue,
    });

    if (res.status_code === 200) {
      const newCustomers = customers.map((c) => {
        if (c.cust_id === id) {
          return { ...c, status: statusValue };
        }
        return c;
      });

      setCustomers(newCustomers);
      filteredItems = filteredItems.map((c) => {
        if (c.cust_id === id) {
          return { ...c, status: statusValue };
        }
        return c;
      });

      setLoading(false);
    }
  };
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const deleteItem = async (id) => {
    confirmAlert({
      title: `${t("delete_alert_title")}`,
      message: `${t("delete_alert_des")}`,
      buttons: [
        {
          label: `${t("yes")}`,
          onClick: async () => {
            setLoading(true);

            const response = await deleteDataById("vendor/deletecustomer", id);

            if (response.status_code === 200) {
              const dataList = customers.filter(
                (item) => item.customer_id !== id
              );
              setCustomers(dataList);
              filteredItems = filteredItems.filter(
                (item) => item.customer_id !== id
              );
            }
            setLoading(false);
          },
        },
        {
          label: `${t("no")}`,
          onClick: () => {},
        },
      ],
    });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <h2 className="text-title text-lg md:text-2xl pb-2">{t("customers")}</h2>
      <div className="hidden flex  justify-end my-2">
        <Link
          to="/vendor/add-customer"
          className="ml-5 btn btn-secondary text-white font-normal btn-sm rounded"
        >
          {t("add_customers")}
        </Link>
      </div>
      <div>
        <DataTable
          columns={columns}
          data={filteredItems}
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          // selectableRows
          pagination
          noDataComponent={
            <h4 className="py-8 text-gray-400 text-center">
              {t("No records")}
            </h4>
          }
        />
      </div>
    </>
  );
};

export default Customers;
