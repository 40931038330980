import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import deleteDataById from '../../../hooks/deleteDataById';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import ApiLoadingSpinner from '../../../components/utils/ApiLoadingSpinner';
import { AiFillDelete, AiTwotoneEdit } from 'react-icons/ai';
import LoadingSpinner from '../../../components/utils/LoadingSpinner';


const ManageLanguage = () => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false);
    const [languages, setLanguages] = useState([]);
    const user = JSON.parse(localStorage.getItem('user'))


    useEffect(() => {
        setLoading(true)
        const getAllGateways = async () => {

            const res = await fetch(`${process.env.REACT_APP_APIURL}/admin/getalllanguage`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            });
            const response = await res.json();
           
            if (response.data) {

                setLanguages(response.data);
            }

            setLoading(false)
        }

        getAllGateways();
    }, [])

    const columns = [
        {
            name: `${t("Flags")}`,

            cell: (item) => (<div className="w-14">
                <img src={item.image} alt="" />
            </div>),
        },

        {
            name: `${t("Short_name")}`,
            selector: row => row.language_short_name,

        },
        {
            name: `${t("Full_Name")}`,
            selector: row => row.language_full_name,

        },

        {
            name: `${t("Options")}`,

            cell: (item) => (<div className="flex gap-2">
                <Link to={`/admin/language/edit/${item.language_id}`} className="p-2 flex items-center border border-success hover:bg-success rounded-full text-success hover:text-white cursor-pointer duration-300" title={`${t("Edit")}`}>
                    <AiTwotoneEdit />
                </Link>

                <span onClick={() => deleteItem(item.language_id)} className="p-2 flex items-center border border-primary hover:bg-primary rounded-full text-primary hover:text-white cursor-pointer duration-300" title={`${t("Delete")}`}>
                    <AiFillDelete />
                </span>
            </div>),
        },
    ]


    const deleteItem = async (id) => {

        confirmAlert({
            title: `${t("delete_alert_title")}`,
            message: `${t("delete_alert_des")}`,
            buttons: [
                {
                    label: `${t("Yes")}`,
                    onClick: async () => {
                        setLoading(true);
                        const res = await deleteDataById("admin/deletelanguage", id);
                        
                        if (res.status_code === 200) {
                            const updatedLanguages = languages.filter(item => item.language_id !== id);
                            toast.success(`${t("data_deleted_successfully")}`);
                            setLanguages(updatedLanguages);
                        }
                        setLoading(false);
                    }
                },
                {
                    label: `${t("No")}`,
                    onClick: () => { }
                }
            ]
        });

    }

    if (loading) {
        return <LoadingSpinner />
    }

    return (
        <div>
            <Helmet>
                <title>{t('Admin')} - {t('All_Languages')}</title>
            </Helmet>
            <div className="flex justify-between">
                <h1 className="text-lg">{t('All_Languages')}</h1>
                <Link className="btn btn-sm rounded btn-primary text-white font-thin" to={'/admin/language/create'}>{t("Create_Language")}</Link>
            </div>

            <div className="bg-gray-50 p-3">


                <DataTable
                    columns={columns}
                    data={languages}
                />
            </div>
        </div>
    );
};

export default ManageLanguage;