import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useForm } from "react-hook-form";
import { AiFillEdit } from "react-icons/ai";
import { TiDelete } from "react-icons/ti";
import { toast } from "react-toastify";
import FilterComponent from "../../../components/utils/FilterComponent";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import deleteDataById from "../../../hooks/deleteDataById";
import getDataById from "../../../hooks/getDataById";
import postData from "../../../hooks/postData";
import updateData from "../../../hooks/updateData";
import { useTranslation } from "react-i18next";
import { confirmAlert } from "react-confirm-alert";
import i18next from "i18next";
import { FaArrowCircleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "../../../components/utils/Interceptors"

const Categories = () => {
  const { t } = useTranslation();
  const [selectedCat, setSelectedCat] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [categories, setCategories] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getAllCategories = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getallcategory`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;
      if (response.data) {
        setCategories(response.data);
      } else {
        setCategories([]);
      }

      setLoading(false);
    };
    getAllCategories();
  }, [refresh]);

  const selectLanguage = i18next.language;

  const columns = [
    {
      name: `${t("vd_pc_C_ID")}`,
      selector: (row, i) => i + 1,
      sortable: true,
    },

    {
      name: `${t("vd_pc_Category")}`,
      selector: (row) =>
        selectLanguage == "ar" && row.category_name_ar
          ? row.category_name_ar
          : row.category_name,
      sortable: true,
    },

    {
      name: `${t("Description")}`,
      selector: (row) =>
        selectLanguage == "ar" && row.category_description_ar
          ? row.category_description_ar
          : row.category_description,
      sortable: true,
    },

    {
      name: `${t("vd_pc_C_Options")}`,
      sortable: true,
      cell: (cat) => (
        <div className="flex gap-2">
          <label
            onClick={() => selectCategoryToEdit(cat.category_id)}
            htmlFor="my-modal-4"
            className="p-2 flex items-center border border-secondary hover:bg-secondary rounded-full text-secondary hover:text-white cursor-pointer duration-300"
            title={`${t("Edit")}`}
          >
            <AiFillEdit />
          </label>
          <span
            onClick={() => deleteCategory(cat.category_id)}
            className="p-2 flex items-center border border-error hover:bg-error rounded-full text-error hover:text-white cursor-pointer duration-300"
            title={`${t("Delete")}`}
          >
            <TiDelete />
          </span>
        </div>
      ),
    },
  ];

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  let filteredItems = categories?.filter(
    (item) =>
      item.category_name &&
      item.category_name.toLowerCase().includes(filterText.toLowerCase())
  );

  // handlers
  const onSubmit = async (data) => {
    setLoading(true);
    const response = await postData("/vendor/createcategory", data);

    if (response !== 0) {
      if (filteredItems) {
        filteredItems.push(response);
      } else {
        filteredItems = [response];
      }

      setCategories([...categories, response]);
      setRefresh(!refresh);
    }

    // getAllParentCategories();
    setLoading(false);
    reset();
  };

  const deleteCategory = async (id) => {
    confirmAlert({
      title: `${t("delete_alert_title")}`,
      message: `${t("delete_alert_des")}`,
      buttons: [
        {
          label: `${t("yes")}`,
          onClick: async () => {
            setLoading(true);

            const response = await deleteDataById("vendor/deletecategory", id);

            if (response.status_code === 200) {
              const newCategorieList = categories.filter(
                (cat) => cat.category_id !== id
              );
              setCategories(newCategorieList);
              setRefresh(!refresh);
              filteredItems = filteredItems.filter(
                (cat) => cat.category_id !== id
              );
            }

            setLoading(false);
          },
        },
        {
          label: `${t("no")}`,
          onClick: () => {},
        },
      ],
    });
  };
  const selectCategoryToEdit = async (id) => {
    const response = await getDataById("vendor/getbyidcategory", id);
    if (response.data) {
      setSelectedCat(response.data);
    } else {
    }
  };

  const [editNameErr, setEditNameErr] = useState("");
  const [editDescErr, setEditDescErr] = useState("");
  const [editNameArabErr, setEditNameArabErr] = useState("");
  const [editDescArabErr, setEditDescArabErr] = useState("");
  useEffect(() => {
    const regexName = /^(?=.{1,30}$)[a-zA-Z ,.'-]+$/i;
    const arabic = /^[\u0600-\u06FF ,.'-]+$/i;

    if (!regexName.test(selectedCat.category_name)) {
      setEditNameErr(
        "Category name must only contain alphabet letters with a maximum of 30 characters"
      );
    } else if (
      selectedCat.category_name_ar &&
      !arabic.test(selectedCat.category_name_ar)
    ) {
      setEditNameArabErr("Category name must only contain arabic letters");
    } else {
      setEditNameErr("");
      setEditNameArabErr("");
    }
  }, [selectedCat]);

  useEffect(() => {
    const regexDesc = /^[a-zA-Z ,.'-]+$/i;
    const arabic = /^[\u0600-\u06FF ,.'-]+$/i;
    if (
      selectedCat.category_description &&
      !regexDesc.test(selectedCat.category_description)
    ) {
      setEditDescErr("Description must only contain alphabet letters");
    } else if (
      selectedCat.category_description_ar &&
      !arabic.test(selectedCat.category_description_ar)
    ) {
      setEditDescArabErr("Description must only contain arabic letters");
    } else {
      setEditDescErr("");
      setEditDescArabErr("");
    }
  }, [selectedCat]);

  const submitEditForm = async (e) => {
    e.preventDefault();

    const data = {
      id: selectedCat.category_id,
      category_name: selectedCat.category_name,
      category_description: selectedCat.category_description,
      category_name_ar: selectedCat.category_name_ar,
      category_description_ar: selectedCat.category_description_ar,
    };

    if (editNameErr || editDescErr) {
      console.log("no");
    } else {
      const response = await updateData("vendor/updatecategory", data);

      if (response.status_code === 200) {
        const updatedList = categories.map((c) => {
          if (c.category_id === selectedCat.category_id) {
            return response.data;
          }
          return c;
        });

        setCategories(updatedList);

        filteredItems = filteredItems.map((c) => {
          if (c.category_id === selectedCat.category_id) {
            return response.data;
          }
          return c;
        });

        setSelectedCat("");
        toast.success("Updated Successfully");
      } else {
        toast.error(response?.error?.message);
      }
    }
  };

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  function handleGoBack() {
    // history.goBack();
    navigate(-1);
  }

  return (
    <>
      <div className="flex gap-x-4">
        <h2 className="text-title text-lg md:text-2xl uppercase">
          {t("vd_pc_Categories")}
        </h2>
        <button
          onClick={handleGoBack}
          className="mt-1 btn btn-primary btn-xs text-white flex justify-center items-center gap-x-2"
        >
          <FaArrowCircleLeft size={15} />
          {t("add_new_product_back")}
        </button>
      </div>
      <div className="p-4 border border-gray-200 bg-white shadow-sm col-span-2 mt-5">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
          <div>
            <form
              className="flex flex-col gap-4"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-control w-full max-w-lg">
                <label className="label">
                  <span className="label-text">{t("vd_pc_Category")}</span>
                </label>
                <input
                  {...register("category_name", {
                    required: true,
                    pattern: /^(?=.{1,30}$)[a-zA-Z ,.'-]+$/i,
                  })}
                  type="text"
                  className="input input-bordered w-full max-w-lg rounded-none shadow"
                />
                {errors.category_name?.type === "required" && (
                  <span className="label-text-alt text-red-500 font-Roboto my-1">
                    Category name is required
                  </span>
                )}
                {errors.category_name?.type === "pattern" && (
                  <span className="label-text-alt text-red-500 font-Roboto my-1">
                    Category name must only contain alphabet letters with a
                    maximum of 30 characters
                  </span>
                )}
              </div>

              <div className="form-control w-full max-w-lg">
                <label className="label">
                  <span className="label-text">
                    {t("vd_pc_Category")} {t("in Arabic")}{" "}
                  </span>
                </label>
                <input
                  {...register("category_name_ar", {
                    pattern: /^[\u0600-\u06FF ,.'-]+$/i,
                  })}
                  type="text"
                  className="input input-bordered w-full max-w-lg rounded-none shadow"
                />
                {errors.category_name_ar?.type === "pattern" && (
                  <span className="label-text-alt text-red-500 font-Roboto my-1">
                    Category name must only contain alphabet letters
                  </span>
                )}
              </div>

              <div className="form-control w-full max-w-lg">
                <label className="label">
                  <span className="label-text">{t("Description")}</span>
                </label>
                <textarea
                  {...register("category_description", {
                    pattern: /^[a-zA-Z ,.'-]+$/i,
                  })}
                  className="textarea textarea-bordered w-full max-w-lg rounded-none shadow"
                ></textarea>
                {errors.category_description?.type === "pattern" && (
                  <span className="label-text-alt text-red-500 font-Roboto my-1">
                    Description must only contain alphabet letters
                  </span>
                )}
              </div>

              <div className="form-control w-full max-w-lg">
                <label className="label">
                  <span className="label-text">
                    {t("Description")} {t("in Arabic")}{" "}
                  </span>
                </label>
                <textarea
                  {...register("category_description_ar", {
                    pattern: /^[\u0600-\u06FF ,.'-]+$/i,
                  })}
                  className="textarea textarea-bordered w-full max-w-lg rounded-none shadow"
                ></textarea>
                {errors.category_description_ar?.type === "pattern" && (
                  <span className="label-text-alt text-red-500 font-Roboto my-1">
                    Description must only contain arabic letters
                  </span>
                )}
              </div>

              <button
                type="submit"
                className={`btn btn-primary max-w-lg rounded-none text-white ${
                  loading && "loading"
                }`}
              >
                {t("vd_pc_btn")}
              </button>
            </form>
          </div>

          <div className="col-span-2 md:p-5">
            {loading ? (
              <LoadingSpinner />
            ) : (
              <DataTable
                columns={columns}
                data={filteredItems}
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                paginationPerPage={6}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                pagination
                noDataComponent={
                  <p className="py-8 text-gray-400 text-center">
                    {t("No records")}
                  </p>
                }
              />
            )}
          </div>
        </div>
      </div>

      {/* edit modal */}
      {selectedCat && (
        <div>
          <input type="checkbox" id="my-modal-4" className="modal-toggle" />
          <label htmlFor="my-modal-4" className="modal cursor-pointer">
            <label className="modal-box relative" htmlFor="">
              <h3 className="text-lg font-bold">{t("vd_pc_ec")}</h3>

              {loading ? (
                <LoadingSpinner />
              ) : (
                <form className="flex flex-col gap-4" onSubmit={submitEditForm}>
                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text">{t("vd_pc_Category")}</span>
                    </label>
                    <input
                      value={selectedCat?.category_name}
                      onChange={(e) =>
                        setSelectedCat({
                          ...selectedCat,
                          category_name: e.target.value,
                        })
                      }
                      type="text"
                      className="input input-bordered w-full max-w-lg rounded-none shadow"
                      required
                    />
                    {
                      <span className="label-text-alt text-red-500 font-Roboto my-1">
                        {editNameErr}
                      </span>
                    }
                  </div>

                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text">
                        {t("vd_pc_Category")} {t("in Arabic")}{" "}
                      </span>
                    </label>
                    <input
                      value={selectedCat?.category_name_ar}
                      onChange={(e) =>
                        setSelectedCat({
                          ...selectedCat,
                          category_name_ar: e.target.value,
                        })
                      }
                      type="text"
                      className="input input-bordered w-full max-w-lg rounded-none shadow"
                    />
                  </div>

                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text">{t("Description")}</span>
                    </label>
                    <textarea
                      value={selectedCat?.category_description}
                      onChange={(e) =>
                        setSelectedCat({
                          ...selectedCat,
                          category_description: e.target.value,
                        })
                      }
                      className="textarea textarea-bordered w-full max-w-lg rounded-none shadow"
                    ></textarea>
                    <span className="label-text-alt text-red-500 font-Roboto my-1">
                      {editDescErr}
                    </span>
                  </div>

                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text">
                        {t("Description")} {t("in Arabic")}{" "}
                      </span>
                    </label>
                    <textarea
                      value={selectedCat?.category_description_ar}
                      onChange={(e) =>
                        setSelectedCat({
                          ...selectedCat,
                          category_description_ar: e.target.value,
                        })
                      }
                      className="textarea textarea-bordered w-full max-w-lg rounded-none shadow"
                    ></textarea>
                    <span className="label-text-alt text-red-500 font-Roboto my-1">
                      {editDescArabErr}
                    </span>
                  </div>

                  <button
                    type="submit"
                    className={`btn btn-primary max-w-lg rounded-none text-white ${
                      loading && "loading"
                    }`}
                  >
                    {t("vd_pc_btn2")}
                  </button>
                </form>
              )}
            </label>
          </label>
        </div>
      )}
    </>
  );
};

export default Categories;
