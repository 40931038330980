import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";
import LoadingSpinner from '../../../components/utils/LoadingSpinner';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const AddServices = () => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]);
    const user = JSON.parse(localStorage.getItem('user'))
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
    };

    const handleAddService = (data) => {
        setLoading(true)
        const userInfo = new FormData();
        if (images[0]?.file) {
            userInfo.append("image", images[0]?.file)
        }
        userInfo.append("title", data.title);
        userInfo.append("description", data.description);
        userInfo.append("url", data.cust_phone);

        fetch(`${process.env.REACT_APP_APIURL}/admin/createservice`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
            body: (userInfo),
            // body: JSON.stringify(serviceInfo),
        })
            .then(res => res.json())
            .then(info => {
                setLoading(false)
                if (info.status_code === 200) {
                    toast.success(`${t("Add_Service_success")}`)
                    window.history.go(-1)
                } else {
                    toast.error(info?.message || info?.err)
                }
            })
    }

    if (loading) {
        return <LoadingSpinner />
    }

    return (
        <div>
            <Helmet>
                <title>{t('Admin')} - {t('Add_Service')}</title>
            </Helmet>
            <div className='flex justify-between'>
                <h1 className="text-md font-medium">{t('Add_Service')}</h1>
                <Link to={'/admin/settings/general_services'}>
                    <button className='btn btn-primary text-white btn-sm'>
                        {t("Back_Service")}
                    </button>
                </Link>
            </div>
            <>
                <div className="Container">
                    <div className=" ">
                        <div>
                            <form className="border-dashed border p-8 flex flex-col justify-center items-start" onSubmit={handleSubmit(handleAddService)}>
                                <div className="form-control w-full">
                                    <label className="label">
                                        <span className="label-text">
                                            {t("Service_Title")}
                                            <span className="text-red-600">*</span>
                                        </span>
                                    </label>
                                    <input
                                        {...register("title", { required: true })}
                                        type="text"
                                        placeholder={t("Service_Title")}
                                        className="input input-bordered focus:outline-none focus:border-gray-300 rounded-none w-full h-[40px]"
                                    />
                                    <label className="label">
                                        {errors.cust_fullname?.type === "required" && (
                                            <span className="label-text-alt text-red-500 font-Roboto">
                                                {t("Service_Title_req")}
                                            </span>
                                        )}
                                    </label>
                                </div>

                                <div className="border w-full bg-white my-5">
                                    <div className="card-title border-b p-4">
                                        <h3 className="text-sm font-medium">
                                            {t("Service_Image")} {" "}
                                        </h3>
                                    </div>
                                    <div className="py-5 px-5">
                                        <div className="col-span-8">
                                            <ImageUploading
                                                value={images}
                                                onChange={onChange}
                                                dataURLKey="data_url"
                                            >
                                                {({
                                                    imageList,
                                                    onImageUpload,
                                                    onImageRemoveAll,
                                                    onImageUpdate,
                                                    onImageRemove,
                                                    isDragging,
                                                    dragProps,
                                                }) => (
                                                    <div className="upload__image-wrapper">
                                                        <p
                                                            className="text-xs cursor-pointer py-2 px-3"
                                                            style={isDragging ? { color: "red" } : undefined}
                                                            onClick={onImageUpload}
                                                            {...dragProps}
                                                        >
                                                            {t("add_new_product_T_img_info")}
                                                        </p>
                                                        &nbsp;
                                                        {imageList.map((image, index) => (
                                                            <div key={index} className="image-item">
                                                                <div className="image-item__btn-wrapper">
                                                                    <button
                                                                        className="text-red-600"
                                                                        onClick={() => onImageRemove(index)}
                                                                    >
                                                                        X
                                                                    </button>
                                                                </div>
                                                                <img
                                                                    className="w-20 h-20"
                                                                    src={image["data_url"]}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        ))}


                                                    </div>
                                                )}
                                            </ImageUploading>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-control w-full">
                                    <label className="label">
                                        <span className="label-text">
                                            {t("Services_Description")} <span className="text-red-600">*</span>
                                        </span>
                                    </label>
                                    <input
                                        {...register("description", { required: true })}
                                        type="text"
                                        placeholder={t("Services_Description")}
                                        className="input input-bordered focus:outline-none focus:border-gray-300 rounded-none w-full h-[40px]"
                                    />
                                    <label className="label">
                                        {errors.cust_email?.type === "required" && (
                                            <span className="label-text-alt text-red-500 font-Roboto">
                                                {t("Services_Description_req")}
                                            </span>
                                        )}
                                    </label>
                                </div>
                                <div className="form-control w-full">
                                    <label className="label">
                                        <span className="label-text">
                                            {t("Url")}
                                        </span>
                                    </label>
                                    <input
                                        {...register("url")}
                                        type="text"
                                        placeholder={t("Url")}
                                        className="input input-bordered focus:outline-none focus:border-gray-300 rounded-none w-full h-[40px]"
                                    />
                                </div>

                                <button className="px-5 py-2 bg-primary text-white rounded-md my-3">
                                    {t("Add_Service")}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        </div>
    );
};

export default AddServices;