import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { storeInformation } from "../../../contexts/StoreInformationContext";
import { domainProvider } from "../../../contexts/DomainContext";

export const MoyasarPayment = () => {
  const { storeDetails } = useContext(storeInformation);
  const storeName = storeDetails?.data?.storeDetails?.store_name
    ? storeDetails?.data?.storeDetails?.store_name
    : "";
  let callback_url = `https://uat.aitech.com.sa/${storeName}/payment-thankyou`;
  const { state } = useLocation();

  let orderDetails = null;

  useEffect(() => {
    orderDetails = JSON.parse(localStorage.getItem("orderDetails"));

    let amount = orderDetails && orderDetails.amount ? orderDetails.amount : 0;
    let order_id =
      orderDetails && orderDetails.order_id ? orderDetails.order_id : 0;
    let order_description = "orderid" + order_id;
    window.Moyasar.init({
      element: ".mysr-form",
      amount: amount * 100,
      currency: "SAR",
      description: order_description,
      publishable_api_key: state?.publishable_key,
      callback_url: callback_url,
      methods: ["creditcard", "stcpay", "applepay"],
      apple_pay: {
        country: "SA",
        label: storeName,
        validate_merchant_url: "https://api.moyasar.com/v1/applepay/initiate",
      },
      on_completed: function (payment) {},
    });
  }, []);

  const { titleName } = useContext(domainProvider);

  return (
    <div>
      <Helmet>
        <title>Payment - {titleName}</title>
      </Helmet>
      <div className="mysr-form" />
    </div>
  );
};
