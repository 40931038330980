import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import deleteDataById from '../../../hooks/deleteDataById';
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from 'react-i18next';
import ApiLoadingSpinner from '../../../components/utils/ApiLoadingSpinner';
import { toast } from 'react-toastify';

const AdminPaymentMethods = () => {
    const { t } = useTranslation()
    const [gateway, setGateway] = useState([]);
    const [loading, setLoading] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'))

    useEffect(() => {

        const getAllGateways = async () => {

            const res = await fetch(`${process.env.REACT_APP_APIURL}/admin/getallpaymentgateway`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            });
            const response = await res.json();
     
            if (response.data) {

                setGateway(response.data);
            }
        }

        getAllGateways();


    }, [])


    const setGatewayInfo = (id, key, e) => {
      
        const updatedGateway = gateway.map(item => {
            if (item.id === id) {
                const newItem = { ...item }
                newItem[key] = e;
                return newItem;
            }
            return item;
        })
        setGateway(updatedGateway);
    }

    const deleteGateway = async (id) => {

        confirmAlert({
            title: `${t("delete_alert_title")}`,
            message: `${t("delete_alert_des")}`,
            buttons: [
                {
                    label: `${t("yes")}`,
                    onClick: async () => {
                        setLoading(true)
                        const res = await deleteDataById("admin/deletepaymentgateway", id);
                      
                        if (res.status_code === 200) {
                            const updatedGateway = gateway.filter(item => item.id !== id);
                            setGateway(updatedGateway);
                        } else {
                            toast.error(res?.message || res?.err)
                        }
                        setLoading(false)
                    }
                },
                {
                    label: `${t("no")}`,
                    onClick: () => { }
                }
            ]
        });
    }

    if (loading) {
        return <ApiLoadingSpinner />
    }

    return (
        <div>
            <div className="flex justify-between">
                <h1 className="text-lg">{t("All_Payment_Gateway")}</h1>
                <Link className="btn btn-sm rounded btn-primary text-white font-thin" to={'/admin/payment-gateway/add'}>{t("Add_Gateway")}</Link>
            </div>

            <div className="bg-gray-50 p-3">

                <div className="flex flex-col gap-4">

                    {
                        gateway.map((gateway, idx) => (
                            <div key={idx} className="bg-white shadow rounded p-2 flex justify-between mb-2 items-center">
                                <div className="gateway-info flex gap-2 items-center">
                                    <img src={gateway.gateway_image} className="w-20 h-16 object-contain" alt="" />
                                    <h3 className="text-lg font-medium">{gateway.gateway_name}</h3>
                                </div>

                                <div className="flex gap-2">
                                    <Link to={`/admin/payment-gateway/edit/${gateway.id}`} onClick={() => { }} className="btn btn-sm rounded-none bg-secondary text-white border-none ">{t("Edit")}</Link>
                                    <span onClick={() => deleteGateway(gateway.id)} className="btn btn-sm rounded-none bg-red-500 text-white border-none ">{t("Delete")}</span>
                                </div>
                            </div>
                        ))
                    }

                </div>
            </div>
        </div>
    );
};

export default AdminPaymentMethods;