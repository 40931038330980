import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { customerProvider } from "../../contexts/CustomerContext";
import { userProvider } from "../../contexts/UserContext";
import ShopNavbar from "./StoreComponents/ShopNavbar";
import StoreFooter from "./StoreComponents/StoreFooter";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import LoadingSpinner from "../../components/utils/LoadingSpinner";
import { domainProvider } from "../../contexts/DomainContext";
import { storeInformation } from "../../contexts/StoreInformationContext";
import axios from "axios";

const RegisterLogin = () => {
  const { t } = useTranslation();
  let selectLanguage = i18next.language;
  const { storeDetails } = useContext(storeInformation);
  const storeName = storeDetails?.data?.storeDetails?.store_name
    ? storeDetails?.data?.storeDetails?.store_name
    : "";
  const { state } = useLocation();
  const { setIsCustomer, isCustomer } = useContext(customerProvider);
  const { refetchUser } = useContext(userProvider);
  const navigate = useNavigate();
  const [errorTxtN, seterrorTxtN] = useState();
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);

  // custom domain
  const { customdomain } = useContext(domainProvider);

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();

  const [eye, seteye] = useState(true);
  const [password, setpassword] = useState("password");
  const [hide, setHide] = useState(true);
  const [passwordR, setPasswordR] = useState("password");

  const Eye = () => {
    if (password === "password") {
      setpassword("text");
      seteye(false);
    } else {
      setpassword("password");
      seteye(true);
    }
  };

  const EyeRegister = () => {
    if (passwordR === "password") {
      setPasswordR("text");
      setHide(false);
    } else {
      setPasswordR("password");
      setHide(true);
    }
  };

  const {
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    register: register2,
  } = useForm();

  const registerSubmit = async (data) => {
    const userInfo = new FormData();

    if (!(value?.phone.length === 12)) {
      seterrorTxtN(
        "Please Enter your valid Number with your Country code. Is't must be 12 character"
      );
      return;
    } else {
      seterrorTxtN("");
    }

    userInfo.append("cust_email", data.cust_email);
    userInfo.append("cust_fullname", data.cust_fullname);
    userInfo.append("cust_phone", value?.phone);
    userInfo.append("is_enabled", 1);
    userInfo.append("password", data.password);
    userInfo.append("store_name", storeName);

    setLoading(true);
    var res;
    if (customdomain === "uat.aitech.com.sa") {
      res = await axios.post(
        `${process.env.REACT_APP_APIURL}/vendor/store/customer/register`,
        userInfo
      );
    } else {
      res = await axios.post(
        `${process.env.REACT_APP_APIURL}/vendor/store/customer/register`,
        userInfo,
        {
          headers: {
            domain_name: customdomain,
            "Content-type": "application/json",
          },
        }
      );
    }
    const response = await res.data;
    if (response) {
      setLoading(false);
      if (response.status_code === 200) {
        toast.info(response.message);
        setIsCustomer(!isCustomer);
        refetchUser();
        if (customdomain === "uat.aitech.com.sa")
          navigate(`/${storeName}/customer/verify-email`);
        else navigate(`/customer/verify-email`);
      } else {
        toast.error(response.message);
      }
    }
  };

  const loginSubmit = async (data) => {
    data["store_name"] = storeName;
    // data["store_name"] = "laststore";
    setLoading(true);

    var res;
    if (customdomain === "uat.aitech.com.sa") {
      res = await axios.post(
        `${process.env.REACT_APP_APIURL}/vendor/store/customer/login`,
        JSON.stringify(data),
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
    } else {
      res = await axios.post(
        `${process.env.REACT_APP_APIURL}/vendor/store/customer/login`,
        JSON.stringify(data),
        {
          headers: {
            domain_name: customdomain,
            "Content-type": "application/json",
          },
        }
      );
    }
    const response = await res.data;
    if (response) {
      setLoading(false);
      if (response.status_code === 200) {
        toast.success("login successful");
        localStorage.setItem("customer", JSON.stringify(response));
        setIsCustomer(!isCustomer);
        // window.history.go(-1)
        if (state) {
          navigate(state);
          refetchUser();
        } else {
          if (customdomain === "uat.aitech.com.sa") navigate(`/${storeName}`);
          else navigate(`/`);
        }
      } else {
        toast.error(response.message);
      }
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <ShopNavbar />
      <div className="Container">
        <div className="px-5 mt-12 mb-40 grid grid-cols-1 lg:grid-cols-2 gap-8 ">
          {/* login page */}
          <div>
            <h1 className="text-center text-2xl mb-2">{t("HNav_login")}</h1>
            <form
              className="border-dashed border-2 p-8 flex flex-col justify-center items-start"
              onSubmit={handleSubmit(loginSubmit)}
            >
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">
                    {t("cus_reg_title")}
                    <span className="text-red-600">*</span>
                  </span>
                </label>
                <input
                  {...register("username", { required: true })}
                  type="text"
                  placeholder={t("u_e")}
                  className="input input-bordered focus:outline-none focus:border-gray-300 rounded-none w-full h-[40px]"
                />
                <label className="label">
                  {errors.username?.type === "required" && (
                    <span className="label-text-alt text-red-500 font-Roboto">
                      {t("Required")}
                    </span>
                  )}
                </label>
              </div>
              <div className="form-control w-full relative">
                <label className="label flex justify-between">
                  <span className="label-text">
                    {t("l_p")} <span className="text-red-600">*</span>
                  </span>
                  <Link
                    className="label-text"
                    to={`${
                      customdomain === "uat.aitech.com.sa"
                        ? `/${storeName}/forgetPass`
                        : `/forgetPass`
                    }`}
                  >
                    {t("lost_pass")}
                  </Link>
                </label>
                <input
                  {...register("password", { required: true })}
                  type={password}
                  placeholder={t("l_p")}
                  className="input input-bordered focus:outline-none focus:border-gray-300 rounded-none w-full h-[40px]"
                />
                <i
                  onClick={Eye}
                  className={`fa ${
                    eye ? "fa-eye-slash" : "fa-eye"
                  } cursor-pointer absolute ${
                    selectLanguage === "ar" ? "left-2" : "right-2"
                  }  top-12`}
                ></i>
                <label className="label">
                  {errors.password?.type === "required" && (
                    <span className="label-text-alt text-red-500 font-Roboto">
                      {t("l_p_r")}
                    </span>
                  )}
                </label>
              </div>
              <div className="flex gap-2 w-full justify-center">
                <input
                  id="is_agree"
                  type="checkbox"
                  className="checkbox checkbox-xs rounded-none focus:border-gray-300"
                />

                <label htmlFor="is_agree" className="label-text">
                  {t("l_p_remember")}
                </label>
              </div>
              <div className="flex flex-col justify-center items-center w-full">
                <button className="px-10 py-2 bg-primary text-white rounded-full my-3">
                  {t("HNav_login")}
                </button>
                <Link
                  className="text-gray-600 hover:text-primary text-lg"
                  to={`${
                    customdomain === "uat.aitech.com.sa"
                      ? `/${storeName}/customer/resend-verification-email`
                      : `/customer/resend-verification-email`
                  }`}
                >
                  {t("Resend_verify_btn")}
                </Link>
              </div>
            </form>
          </div>

          {/* registration page */}
          <div>
            <h1 className="text-center text-2xl mb-2">{t("regi_btn")}</h1>
            <form
              className="border-dashed border-2 p-8 flex flex-col justify-center items-start"
              onSubmit={handleSubmit2(registerSubmit)}
            >
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">
                    {t("Full_Name")}
                    <span className="text-red-600">*</span>
                  </span>
                </label>
                <input
                  {...register2("cust_fullname", { required: true })}
                  type="text"
                  placeholder={t("Full_Name")}
                  className="input input-bordered focus:outline-none focus:border-gray-300 rounded-none w-full h-[40px]"
                />
                <label className="label">
                  {errors2.cust_fullname?.type === "required" && (
                    <span className="label-text-alt text-red-500 font-Roboto">
                      {t("u_is_req")}
                    </span>
                  )}
                </label>
              </div>

              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">
                    {t("e_address")} <span className="text-red-600">*</span>
                  </span>
                </label>
                <input
                  {...register2("cust_email", { required: true })}
                  type="email"
                  placeholder={t("e_address")}
                  className="input input-bordered focus:outline-none focus:border-gray-300 rounded-none w-full h-[40px]"
                />
                <label className="label">
                  {errors2.cust_email?.type === "required" && (
                    <span className="label-text-alt text-red-500 font-Roboto">
                      {t("e_address_r")}
                    </span>
                  )}
                </label>
              </div>

              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">
                    {t("create_mobile")} <span className="text-red-600">*</span>
                  </span>
                </label>
                <PhoneInput
                  country={"sa"}
                  onlyCountries={["sa"]}
                  inputProps={{
                    required: true,
                  }}
                  value={value?.phone}
                  onChange={(phone) => setValue({ phone })}
                  isValid={(value, country) => {
                    if (value.match(/12345/)) {
                      return "Invalid value: " + value + ", " + country.name;
                    } else if (value.match(/1234/)) {
                      return false;
                    } else {
                      return true;
                    }
                  }}
                  countryCodeEditable={false}
                />
                {errorTxtN && (
                  <span className="label-text-alt text-red-500 font-Roboto">
                    {errorTxtN}
                  </span>
                )}
                <label className="label">
                  {errors.contact_no?.type === "required" && (
                    <span className="label-text-alt text-red-500 font-Roboto">
                      {t("create_mobile_r")}
                    </span>
                  )}
                </label>
              </div>

              <div className="form-control w-full relative">
                <label className="label">
                  <span className="label-text">
                    {t("l_p")} <span className="text-red-600">*</span>
                  </span>
                </label>
                <input
                  {...register2("password", { required: true })}
                  type={passwordR}
                  placeholder={t("l_p")}
                  className="input input-bordered focus:outline-none focus:border-gray-300 rounded-none w-full h-[40px]"
                />
                <i
                  onClick={EyeRegister}
                  className={`fa ${
                    hide ? "fa-eye-slash" : "fa-eye"
                  } cursor-pointer absolute ${
                    selectLanguage === "ar" ? "left-2" : "right-2"
                  } top-12`}
                ></i>
                <label className="label">
                  {errors2.password?.type === "required" && (
                    <span className="label-text-alt text-red-500 font-Roboto">
                      {t("l_p_r")}
                    </span>
                  )}
                </label>
              </div>

              <button className="px-5 py-2 bg-blue-600 text-white rounded-full my-3">
                {t("regi_btn")}
              </button>
              <Link to={"/vendor/register"}>{t("b_vendor")}</Link>
            </form>
          </div>
        </div>
      </div>
      <StoreFooter />
    </div>
  );
};

export default RegisterLogin;
