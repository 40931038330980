import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ShoppingPng3 from "../../assets/Woman_Shopping.png";
import Footer2 from "../../components/Footer/Footer2";
import Header from "../../components/Header/Header";
import Div from "../../components/utils/Div";
import i18next from "i18next";
import { domainProvider } from "../../contexts/DomainContext";
import axios from "axios";

const Register = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errorTxt, seterrorTxt] = useState();
  const [errorTxtN, seterrorTxtN] = useState();
  const [value, setValue] = useState();
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [eye, setEye] = useState(true);
  const [password, setPassword] = useState("password");

  const Eye = () => {
    if (password === "password") {
      setPassword("text");
      setEye(false);
    } else {
      setPassword("password");
      setEye(true);
    }
  };

  const onSubmit = async (data) => {
    if (!(value?.phone.length === 12)) {
      seterrorTxtN(
        "Please Enter your valid Number with your Country code. Is't must be 12 character"
      );
      return;
    } else {
      seterrorTxtN("");
    }

    setLoading(true);

    const vendorData = {
      vendor_name: data.user_name,
      store_name: data.storeName.split(" ").join(""),
      vendor_email: data.email,
      vendor_contact: value?.phone,
      password: data.password,
      is_agreed: 1,
    };

    const res = await axios.post(
      `${process.env.REACT_APP_APIURL}/vendor/register`,
      JSON.stringify(vendorData),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const result = await res.data;

    setLoading(false);
    if (result.status_code === 200) {
      toast.success(result.message);
      navigate("/vendor/verify-email");
    } else if (result.status_code === 400) {
      toast.info(result.messsage);
    } else {
      toast.error(result.messsage);
    }
  };

  let selectLanguage = i18next.language;
  const { titleName } = useContext(domainProvider);

  useEffect(() => {
    var user = localStorage.getItem("user");
    user = JSON.parse(user);
    if (
      user && user.token
        ? user.token
        : user && user.refreshToken
        ? user.refreshToken
        : ""
    ) {
      navigate("/vendor/main-flow");
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {t("create_store_title")} - {titleName}
        </title>
      </Helmet>
      <Header />
      <Div className="" id="privacyPolicy">
        <div className="mt-5 grid grid-cols-1 md:grid-cols-3 place-content-center mx-auto">
          <div className="registration-form w-full mx-auto md:border-r-4 border-gray-200  md:pr-5">
            <form
              className="flex flex-col py-5 justify-center w-full"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">{t("create_storeName")}</span>
                </label>
                <input
                  {...register("storeName", {
                    required: true,
                    pattern: /^(?=.{1,30}$)[a-zA-Z ,.'-]+$/i,
                  })}
                  type="text"
                  placeholder={`${t("create_storeName")}`}
                  className="input input-sm input-bordered py-4 focus:outline-none rounded-none w-full "
                />
                <label className="label">
                  {errors.storeName?.type === "required" && (
                    <span className="label-text-alt text-red-500 font-Roboto">
                      {t("create_storeName_r")}
                    </span>
                  )}
                  {errors.storeName?.type === "pattern" && (
                    <span className="label-text-alt text-red-500 font-Roboto">
                      Name should consists of alphabet letters with a maximum of
                      30 characters
                    </span>
                  )}
                </label>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">{t("create_WName")}</span>
                </label>
                <input
                  {...register("user_name", {
                    required: true,
                    pattern: /^(?=.{1,30}$)[a-zA-Z ,.'-]+$/i,
                  })}
                  type="text"
                  placeholder={`${t("create_WName")}`}
                  className="input input-sm input-bordered py-4 focus:outline-none rounded-none w-full "
                />
                <label className="label">
                  {errors.user_name?.type === "required" && (
                    <span className="label-text-alt text-red-500 font-Roboto">
                      {t("create_WName_r")}
                    </span>
                  )}
                  {errors.user_name?.type === "pattern" && (
                    <span className="label-text-alt text-red-500 font-Roboto">
                      Owner Name should consists of alphabet letters with a
                      maximum of 30 characters
                    </span>
                  )}
                </label>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">{t("l_email")}</span>
                </label>
                <input
                  {...register("email", { required: true })}
                  type="email"
                  placeholder={`${t("l_phone_m")}`}
                  className="input input-sm input-bordered py-4 focus:outline-none rounded-none w-full "
                />
                <label className="label">
                  {errors.email?.type === "required" && (
                    <span className="label-text-alt text-red-500 font-Roboto">
                      {t("form_email_req")}
                    </span>
                  )}
                </label>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">{t("create_mobile")}</span>
                </label>
                <PhoneInput
                  country={"sa"}
                  onlyCountries={["sa"]}
                  inputProps={{
                    required: true,
                  }}
                  value={value?.phone}
                  onChange={(phone) => setValue({ phone })}
                  isValid={(value, country) => {
                    if (value.match(/12345/)) {
                      return "Invalid value: " + value + ", " + country.name;
                    } else if (value.match(/1234/)) {
                      return false;
                    } else {
                      return true;
                    }
                  }}
                  countryCodeEditable={false}
                  style={{
                    direction: `${selectLanguage == "ar" ? "rtl" : "ltr"}`,
                  }}
                />
                {errorTxtN && (
                  <span className="label-text-alt text-red-500 font-Roboto">
                    {errorTxtN}
                  </span>
                )}
                <label className="label">
                  {errors.contact_no?.type === "required" && (
                    <span className="label-text-alt text-red-500 font-Roboto">
                      {t("create_mobile_r")}
                    </span>
                  )}
                </label>
              </div>

              <div className="form-control w-full relative">
                <label className="label">
                  <span className="label-text">{t("l_p")}</span>
                </label>
                <input
                  {...register("password", {
                    required: true,
                    pattern:
                      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
                    min: 6,
                  })}
                  type={password}
                  placeholder={`${t("l_p_p")}`}
                  className="input input-sm input-bordered py-4 focus:outline-none rounded-none w-full "
                />
                <i
                  onClick={Eye}
                  className={`fa ${
                    eye ? "fa-eye-slash" : "fa-eye"
                  } cursor-pointer absolute top-12 ${
                    selectLanguage == "ar" ? "left-2" : "right-2"
                  } `}
                ></i>
                <label className="label">
                  {errors.password?.type === "required" && (
                    <span className="label-text-alt text-red-500 font-Roboto">
                      {t("l_p_r")}
                    </span>
                  )}
                  {errors.password?.type === "pattern" && (
                    <span className="label-text-alt text-red-500 font-Roboto">
                      Password should contain atleast one number and one special
                      character
                    </span>
                  )}
                  {errors.password?.type === "min" && (
                    <span className="label-text-alt text-red-500 font-Roboto">
                      Password must be between 6 and 16 characters in length
                    </span>
                  )}
                </label>
                {errorTxt && (
                  <span className="label-text-alt text-red-500 font-Roboto">
                    {errorTxt}
                  </span>
                )}
              </div>
              <div className="form-control">
                <div className="flex gap-2">
                  <input
                    id="is_agree"
                    type="checkbox"
                    required
                    className="checkbox checkbox-xs rounded-none"
                  />

                  <label htmlFor="is_agree" className="label-text flex gap-1">
                    <p>{t("p_policy")}</p>
                    <td
                      onClick={() => window.open("/privacy-policy", "_blank")}
                      className="text-blue-700 font-semibold cursor-pointer"
                      title={t("login_p_policy")}
                      to="/privacy-policy"
                    >
                      {t("login_p_policy")}
                    </td>
                  </label>
                </div>
              </div>
              <div className="form-control w-full">
                <button
                  className={`py-2 cursor-pointer btn-primary text-white w-full  rounded-none btn ${
                    loading && "loading"
                  }`}
                  type="submit"
                >
                  {t("regi_btn")}
                </button>
              </div>
            </form>

            <div className="flex flex-col pb-3 w-full">
              <div className="divider">{t("have_acc")}</div>

              <Link
                className="border border-px border-primary py-2 flex justify-center items-center text-primary hover:bg-primary hover:!text-white duration-300 transition-all"
                to="/vendor/login"
              >
                {t("v_l_f")}
              </Link>
            </div>
          </div>

          <div className="hidden md:flex justify-end items-start col-span-2">
            <img
              src={ShoppingPng3}
              className="max-w-md lg:max-w-xl text-center"
              alt=""
            />
          </div>
        </div>
      </Div>
      <Footer2 />
      {/* <Footer2 /> */}
    </>
  );
};

export default Register;
