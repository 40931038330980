import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import Footer from '../components/Footer/Footer';
import Footer2 from '../components/Footer/Footer2';
import Header from '../components/Header/Header';
import Pricing from '../components/HomeComponents/Pricing';
import { domainProvider } from '../contexts/DomainContext';

const PlansPricing = () => {
    const { titleName } = useContext(domainProvider);

    return (
        <div className='bg-[#F7F7F7]'>
            <Helmet>
                <title>Plans & Pricing - {titleName}</title>
            </Helmet>
            <Header navigation={true} />
            <Pricing />
            <Footer2 />
        </div>
    );
};

export default PlansPricing;