import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import ShopNavbar from "../../StoreTemplate/StoreComponents/ShopNavbar";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { domainProvider } from "../../../contexts/DomainContext";
import { storeInformation } from "../../../contexts/StoreInformationContext";
import axios from "axios";

const UserForgetPass = () => {
  const selectLanguage = i18next.language;
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const { storeDetails } = useContext(storeInformation);
  const storeName = window.location.pathname.split("/")[1];
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const { customdomain } = useContext(domainProvider);

  useEffect(() => {
    var user = localStorage.getItem("customer");
    user = JSON.parse(user);
    if (user?.data?.cust_id) {
      if (customdomain === "uat.aitech.com.sa") navigate(`/${storeName}`);
      else navigate("/");
    }
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    const res = await axios.post(
      `${process.env.REACT_APP_APIURL}/vendor/store/customer/reset_password_link`,
      JSON.stringify(data),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const response = await res.data;

    if (response.code === 200) {
      setMessage(response.message);
    } else if (response.code === 400) {
      setMessage(response.message);
    } else {
      setMessage(response.err || response.message);
    }

    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>Forget Password</title>
      </Helmet>
      <ShopNavbar />
      <section className="px-2 md:px-5 lg:px-20 py-5">
        <div className="container place-content-center mx-auto">
          <div className="registration-form max-w-md mx-auto  px-2 md:px-8">
            <form
              className="flex flex-col gap-y-4 card border shadow py-5 justify-center w-full"
              onSubmit={handleSubmit(onSubmit)}
            >
              <h2 className="text-center text-xl md:text-2xl mb-8">
                {" "}
                {selectLanguage === "ar"
                  ? "هل نسيت كلمة المرور ؟"
                  : "Forgot Your password?"}
              </h2>
              {message && (
                <span className="text-green-600 text-sm">{message}</span>
              )}
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">{t("l_email")}</span>
                </label>
                <input
                  {...register("email", { required: true })}
                  type="email"
                  placeholder={t("l_phone_m")}
                  className="input input-sm input-bordered py-4 focus:outline-none rounded-none w-full "
                  required
                />
              </div>
              <div className="form-control w-full">
                <button
                  type="submit"
                  className={`btn cursor-pointer btn-primary text-white w-full rounded-none ${
                    loading && "loading"
                  }`}
                >
                  {selectLanguage === "ar" ? "إعادة ضبط" : "Reset"}
                </button>
              </div>
            </form>
            <div className="flex flex-col w-full">
              <Link
                to={`${
                  customdomain === "uat.aitech.com.sa"
                    ? `/${storeName}/register-login`
                    : `/register-login`
                }`}
                className="divider text-primary"
              >
                {selectLanguage === "ar"
                  ? "العودة إلى تسجيل الدخول"
                  : "Back to Login"}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserForgetPass;
