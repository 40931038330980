import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import postData from '../../../hooks/postData';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const AddRoles = () => {
    const [modules, setModules] = useState([]);
    const [roleName, setroleName] = useState("");

    const [view, setView] = useState({})
    const [edit, setEdit] = useState({})
    const [dlt, setDlt] = useState({})
    const [add, setAdd] = useState({})

    const navigate = useNavigate();
    
    // const [access, setAccess] = useState({
    //     role_name: null,
    //     module_ids: [],
    //     view: {},
    //     edit: {},
    //     add: {},
    //     delete: {}

    // });
    const user = JSON.parse(localStorage.getItem('user'))

    useEffect(() => {

        const getAllModules = async () => {
            const res = await fetch(`${process.env.REACT_APP_APIURL}/admin/getallmodule`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            });
            const response = await res.json();
          
            if (response.data) {

                setModules(response.data);
            }
        }
        getAllModules();

    }, [])

    
    const handlePermission = (event, module_id, key) => {
        const checked = event.target.checked;

        switch (key) {
            case 'view':
                if (checked) {
                    const newView = { ...view };
                    newView[module_id] = 1;
                    setView(newView);
                } else {
                    const newView = { ...view }
                    delete newView[module_id];
                    setView(newView);
                }

                break;
            case 'edit':
                if (checked) {
                    const newEdit = { ...edit };
                    newEdit[module_id] = 1;

                    setEdit(newEdit);
                } else {
                    const newEdit = { ...edit }
                    delete newEdit[module_id];
                    setEdit(newEdit);
                }


                break;
            case 'delete':
                if (checked) {

                    const newDelete = { ...dlt };
                    newDelete[module_id] = 1;
                    setDlt(newDelete);

                } else {
                    const newdlt = { ...dlt }
                    delete newdlt[module_id];
                    setDlt(newdlt);
                }
                break;
            case 'add':
                if (checked) {

                    const newAdd = { ...add };
                    newAdd[module_id] = 1;
                    setAdd(newAdd);

                } else {
                    const newAdd = { ...add }
                    delete newAdd[module_id];
                    setAdd(newAdd);
                }
                break;
            default:

        }
       
    }

   
    const handleSubmit = async (e) => {
        e.preventDefault();
       
        // Object.keys(view).forEach(i => {
        //     if (!modulesId.includes(i)) {
        //         modulesId.push(i);
        //     }
        // })
        // Object.keys(edit).forEach(i => {
        //     if (!modulesId.includes(i)) {
        //         modulesId.push(i);
        //     }
        // })
        // Object.keys(dlt).forEach(i => {
        //     if (!modulesId.includes(i)) {
        //         modulesId.push(i);
        //     }
        // })
        // Object.keys(add).forEach(i => {
        //     if (!modulesId.includes(i)) {
        //         modulesId.push(i);
        //     }
        // })
    
        const data = {
            role_name: roleName,
            module_ids: modules.map(i => i.module_id),
            view: view,
            edit: edit,
            delete: dlt,
            add: add,
        }

        const baseUrl = process.env.REACT_APP_APIURL;
        const res = await fetch(`${baseUrl}/admin/createroleaccess`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        });

        const response = await res.json();

        if (response.status_code === 200) {
            if (response.data?.role_id) {
                toast.success("Updated Successfully");
                navigate('/admin/roles');

            }
        }


    }

    return (
        <div>
            <div className="mb-20">
                <div className="mx-auto border bg-white">
                    <div className="card-title border-b p-4">
                        <h3 className="text-base font-medium">Role information</h3>
                    </div>


                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="py-5 px-4">

                            <div className="max-w-lg mx-auto">

                                <div className="my-5">
                                    <div className="form-group mb-5">
                                        <div className="grid grid-cols-1 md:grid-cols-12 items-center">
                                            <div className="col-span-3">
                                                <label className="text-[13px]" htmlFor="role-name">Role Name<span className="text-error">*</span></label>
                                            </div>
                                            <div className="col-span-9">
                                                <input onChange={(e) => setroleName(e.target.value)} className="w-full h-[40px] input input-bordered rounded" placeholder="Role Name" required />

                                            </div>
                                        </div>
                                    </div>




                                </div>

                            </div>


                            <h5 className="border-b p-4 font-bold">Permissions</h5>

                            {
                                modules.map((module, i) => (
                                    <div key={i} className="border rounded mx-5 my-3">
                                        <div className="card-title border-b p-3">
                                            <h3 className="text-base font-medium">{module?.module_name}</h3>
                                        </div>
                                        <div className="p-4">
                                            {
                                                module.module_name !== 'Dashboard' ?
                                                    <>
                                                        <div className="flex gap-4 max-w-lg flex-wrap">
                                                            <div className="grow border rounded p-3">
                                                                <p className="text-sm mb-2">View</p>
                                                                <input onChange={(e) => handlePermission(e, module?.module_id, "view")} type="checkbox" className="toggle toggle-sm toggle-secondary " />
                                                            </div>
                                                            <div className="grow border rounded p-3">
                                                                <p className="text-sm mb-2">Add</p>
                                                                <input onChange={(e) => handlePermission(e, module?.module_id, "add")} type="checkbox" className="toggle toggle-sm toggle-secondary " />
                                                            </div>
                                                            <div className="grow border rounded p-3">
                                                                <p className="text-sm mb-2">Edit</p>
                                                                <input onChange={(e) => handlePermission(e, module?.module_id, "edit")} type="checkbox" className="toggle toggle-sm toggle-secondary " />
                                                            </div>
                                                            <div className="md:grow border rounded p-3">
                                                                <p className="text-sm mb-2">Delete</p>
                                                                <input onChange={(e) => handlePermission(e, module?.module_id, "delete")} type="checkbox" className="toggle toggle-sm toggle-secondary " />
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="flex gap-4 max-w-lg flex-wrap">
                                                            <div className="grow border rounded p-3">
                                                                <p className="text-sm mb-2">View</p>
                                                                <input onChange={(e) => handlePermission(e, module?.module_id, "view")} type="checkbox" className="toggle toggle-sm toggle-secondary " />
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                    </div>
                                ))
                            }





                        </div>


                        <div className="text-center mx-auto pb-5">
                            <input className="py-1 px-3 bg-primary text-white rounded cursor-pointer" type="submit" value="Save" />

                        </div>
                    </form>



                </div>
            </div>

        </div>
    );
};

export default AddRoles;