import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import getAll from '../../../hooks/getAll';
import { useTranslation } from 'react-i18next';
import ApiLoadingSpinner from '../../../components/utils/ApiLoadingSpinner';

const AdminCreateOrder = () => {
    const { t } = useTranslation()
    const [plans, setPlans] = useState();
    const [vendors, setVendors] = useState();
    const user = JSON.parse(localStorage.getItem('user'))
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const { control, register, handleSubmit, watch, formState: { errors } } = useForm();



    useEffect(() => {

        const getAllPlans = async () => {

            const res = await getAll("admin/getallplan");
            if (res.status_code === 200) {
                setPlans(res.data)
            }

        }
        const getAllVendors = async () => {

            const res = await getAll("admin/getallstore");
            if (res.status_code === 200) {
                setVendors(res.data)
            }

        }
        getAllPlans();
        getAllVendors();

    }, [])



    const onSubmit = async (data) => {
        setLoading(true)
        const res = await fetch(`${process.env.REACT_APP_APIURL}/admin/createvendorplan`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }

        })
        const response = await res.json();
        // const response = undefined

        if (response?.status_code === 200) {
            toast.success(`${t("Order_added_Successfully")}`);
            navigate("/admin/orders");

            setLoading(false)
        }
        else {
            toast.error(response?.message || response?.err)
        }
        setLoading(false)
    }

    if (loading) {
        return <ApiLoadingSpinner />
    }

    return (
        <>
            <h2 className="text-title text-lg md:text-2xl pb-2">{t("Add_new_Order")}</h2>
            <div className="p-4 border border-gray-200 bg-white shadow-sm col-span-2 max-w-lg mx-auto">

                <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-control w-full max-w-md">
                        <label className="label">
                            <span className="label-text">{t("Vendor")}</span>

                        </label>
                        {/* <input {...register("vendor_email")} type="email" placeholder="Type here" className="input input-bordered w-full max-w-md rounded-none" /> */}
                        <select {...register("store_id")} defaultValue={""} className="w-full h-[40px] input input-bordered rounded" placeholder="0" required>
                            <option value="">Choose a store</option>


                            {
                                vendors && vendors.map((vendor, index) => {
                                    return (
                                        <option key={index} value={vendor.store_id}>{vendor.store_name}</option>
                                    )
                                })
                            }

                        </select>
                    </div>





                    <div className="form-control w-full max-w-md">
                        <label className="label">
                            <span className="label-text">{t("Plan")}</span>

                        </label>
                        <select {...register("plan_id")} defaultValue={""} className="w-full h-[40px] input input-bordered rounded" placeholder="0" required>

                            <option value="">Choose a plan</option>

                            {
                                plans && plans.map((plan, index) => {
                                    return (
                                        <option key={index} value={plan.plan_id}>{plan.plan_name}</option>
                                    )
                                })
                            }

                        </select>
                    </div>

                    <div className="form-control w-full max-w-md">
                        <label className="label">
                            <span className="label-text">{t("Payment_method")}</span>

                        </label>
                        <select {...register("payment_mode")} className="w-full h-[40px] input input-bordered rounded" placeholder="0">


                            <option value="cash">{t("Cash")}</option>
                            <option value="online">{t("Online")}</option>

                        </select>
                    </div>



                    <div className="form-control w-full max-w-md">
                        <label className="label">
                            <span className="label-text">{t("Transaction_Id")}</span>

                        </label>
                        <input {...register("transaction_id")} type="text" placeholder="Type here" className="input input-bordered w-full max-w-md rounded-none" />


                    </div>

                    <div className="flex justify-center gap-x-4">

                        <Link to={'/admin/orders'} className="btn btn-sm btn-error max-w-md text-white rounded">{t("Cancel")}</Link>
                        <input type="submit" className="btn btn-sm btn-primary max-w-md text-white rounded" value={`${t("Save")}`} />
                    </div>
                </form>


            </div>
        </>
    );
};

export default AdminCreateOrder;