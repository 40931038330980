import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../../../components/utils/LoadingSpinner";
import axios from "../../../../components/utils/Interceptors";

const OtherTab = ({ otherInfo }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [indexes, setIndexes] = useState([0]);
  const [counter, setCounter] = useState(1);

  const {
    register,
    unregister,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (otherInfo) {
      const newIndexes = Array.from(Array(otherInfo.length).keys());
      setIndexes(newIndexes);
      setCounter(newIndexes.length);
      otherInfo.forEach((social, i) => {
        setValue(`social[${i}].url`, social.url);
        setValue(`social[${i}].icon`, social.icon);
      });
    }
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    const other_icon = data.social.map((social) => social.icon);
    const other_url = data.social.map((social) => social.url);
    const other_name = data.social.map((social) => social.icon);
    const newData = {
      other_icon,
      other_url,
      other_name,
    };
    try {
      const baseUrl = process.env.REACT_APP_APIURL;

      const res = await axios.patch(
        `${baseUrl}/vendor/updatestoredetails`,
        JSON.stringify(newData),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      const response = await res.data;
      toast.success("Updated Successfully!");
    } catch (error) {
      toast.error(error);
    }
    setLoading(false);
  };

  const addIcon = () => {
    setIndexes((prevIndexes) => [...prevIndexes, counter]);
    setCounter((prevCounter) => prevCounter + 1);
  };
  const removeIcon = (index) => () => {
    setIndexes((prevIndexes) => [
      ...prevIndexes.filter((item) => item !== index),
    ]);
    setCounter((prevCounter) => prevCounter - 1);
    unregister(`social[${index}]`);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-3">
        <label className="label">
          <span className="label-text">{t("Social_links")}</span>
        </label>
        <div className="w-full overflow-auto bg-base-200">
          <table className="table table-bordered table-striped w-full">
            <thead>
              <tr>
                <th width="35%">{t("Url")}</th>
                <th width="35%">{t("Select_Social_Media")}</th>
                <th width="30%">
                  <button
                    type="button"
                    onClick={addIcon}
                    name="add"
                    id="add"
                    className="btn btn-success btn-sm rounded text-white font-thin"
                  >
                    {t("Add_New")}
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {indexes.map((index, i) => (
                <tr key={i} t={index}>
                  <td>
                    <input
                      {...register(`social[${index}].url`)}
                      type="text"
                      className="p-2 border rounded bg-white border-gray-400 text-xs"
                      required
                    />
                  </td>
                  <td>
                    <select
                      {...register(`social[${index}].icon`)}
                      className="p-2 border rounded bg-white border-gray-400 text-xs"
                    >
                      <option disabled selected>
                        {t("Select_Media")}
                      </option>
                      <option value={"fa-brands fa-facebook"}>Facebook </option>
                      <option value={"fa-brands fa-instagram"}>
                        Instagram{" "}
                      </option>
                      <option value={"fa-brands fa-youtube"}>Youtube </option>
                      <option value={"fa-brands fa-linkedin"}>Linkedin </option>
                    </select>
                    {/* <input type="text" {...register(`social[${index}].icon`)}  className="p-2 border rounded bg-white border-gray-400 text-xs" placeholder="fa fa-facebook" required/> */}
                  </td>
                  <td>
                    <button
                      onClick={removeIcon(index)}
                      type="button"
                      name="remove"
                      id=""
                      className="btn btn-sm btn-error text-white rounded font-thin"
                    >
                      {t("Remove")}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <br></br>

        <input
          type="submit"
          className="btn btn-sm rounded btn-primary text-white inline max-w-fit px-4"
          value={`${t("vd_pc_btn")}`}
        />
      </form>
    </div>
  );
};

export default OtherTab;
