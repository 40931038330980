import React, { useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import FilterComponent from '../../../components/utils/FilterComponent';


const columns = [
    {
        name: 'ID',
        selector: row => row.id,
        sortable: true,
    },

    {
        name: 'Customer',
        selector: row => row.customer,
        sortable: true,
    },
    {
        name: 'Title',
        selector: row => row.title,
        sortable: true,
    },
    {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
    },
    {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
    },
    {
        name: 'Date',
        selector: row => row.date,
        sortable: true,
    },
    {
        name: 'Price',
        selector: row => row.price,
        sortable: true,
    },
    {
        name: 'Action',
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
            return (
                <>
                    <button
                        className="btn btn-primary btn-xs font-normal btn-outline"
                        onClick={() => {
                            alert("clicked");
                        }}
                    >
                        Edit
                    </button>
                </>
            );
        },
    },

];

const data = [{ id: 1, customer: "Anthony Washington", title: "branding", email: "awashington0@cafepress.com", status: "Completed", date: "11/9/2015", price: "$387.15" },

];


const Invoices = () => {


    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data.filter(
        item => item.customer.toLowerCase().includes(filterText.toLowerCase()) || item.email.toLowerCase().includes(filterText.toLowerCase())
    );

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);

    return (
        <>
            <h2 className="text-title text-lg md:text-2xl pb-2">Invoices</h2>
            <div>


                <DataTable
                    columns={columns}
                    data={filteredItems}
                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    // selectableRows
                    pagination
                />

            </div>
        </>
    );
};

export default Invoices;