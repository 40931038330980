import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingSpinner from '../../../components/utils/LoadingSpinner';
import getDataById from '../../../hooks/getDataById';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const EditPage = () => {
    const { t } = useTranslation()
    const { control, register, handleSubmit, setValue, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem("user"))
    const [loading, setLoading] = useState(true)
    const { id } = useParams()

    useEffect(() => {
        const getById = async () => {
            try {
                const response = await getDataById("admin/getbyidpage", id);
                if (response.status_code === 200) {
                    const { page_title, page_content, published_status } = response.data
                    setValue("page_title", page_title);
                    setValue("page_content", page_content);
                    setValue("published_status", published_status);
                }
            }
            catch (err) {
            }
            setLoading(false);
        }
        getById();
    }, [id])

    const onSubmit = async data => {
        data["id"] = id;
        setLoading(true)
        const res = await fetch(`${process.env.REACT_APP_APIURL}/admin/updatepage`, {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }

        })
        const response = await res.json();
        // const response = undefined
        setLoading(false)
      
        if (response?.status_code === 200) {
            toast.success(`${t("page_up_success")}`);
            navigate("/admin/page/all-pages");
        }
        else {
            toast.error(response?.message || response?.err)
        }

    }

    if (loading) {
        return <LoadingSpinner />
    }

    return (
        <>
            <Helmet>
                <title>{t('Admin')} - {t("Edit_page")}</title>
            </Helmet>
            <div>
                <h2 className="text-title text-lg md:text-2xl pb-2">{t("Edit_page")}</h2>
            </div>
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div className="md:col-span-2">
                            <div className="border bg-white">
                                <div className="py-5 px-5">
                                    <div className="form-group mb-5">
                                        <div className="grid grid-cols-1 gap-y-2">
                                            <div className="mb-2">
                                                <label className="text-[13px]" htmlFor="pageTitle">{t("Page_Title")}<span className="text-error">*</span></label>
                                            </div>
                                            <div>
                                                <input required className="w-full h-[40px] input input-bordered rounded" placeholder="Product Name"{...register("page_title")} />
                                                {errors.pageTitle?.type === 'required' && `${t("Page_req")}`}
                                            </div>

                                            <div className="mb-2">
                                                <label className="text-[13px]" htmlFor="content">{t("Page_cont")}</label>
                                            </div>
                                            <div>
                                                <div className="form-control h-60">

                                                    <Controller
                                                        name="page_content"
                                                        control={control}

                                                        render={({ field }) =>
                                                            <ReactQuill

                                                                {...field}
                                                                className="h-48"
                                                                theme="snow"
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-span-1">
                            <div className="border bg-white">
                                <div className="py-5 px-5">
                                    <div className="form-group mb-5">
                                        <div className="grid grid-cols-1 gap-y-2">
                                            <div className="mb-2">
                                                <label className="text-[13px]" htmlFor="pageTitle">{t("Status")}</label>
                                            </div>
                                            <div>
                                                <select defaultValue={1} className="w-full h-[40px] input input-bordered rounded" placeholder="0" {...register("published_status")}>
                                                    <option value={1}>{t("Published")}</option>
                                                    <option value={0}>{t("Draft")}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <input className="py-1 px-3 bg-primary text-white rounded cursor-pointer mt-5" type="submit" value={t("Save_Page")} />
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default EditPage;