import React from "react";

import { Outlet } from "react-router-dom";
import Sidebar from "../../components/DashboardPartials/Sidebar/Sidebar";
import DashboardHeader from "../../components/DashboardPartials/DashboardHeader/DashboardHeader";
import ShopNavbar from "../StoreTemplate/StoreComponents/ShopNavbar";
import { useNavigate } from "react-router-dom";

const UserLayout = () => {
  const navigate = useNavigate();
  const customerInfo = JSON.parse(localStorage.getItem("customer"));
  if (!customerInfo) {
    navigate("/register-login");
  }
  return (
    // style={{ direction: "rtl" }}
    <div className="mx-auto">
        <ShopNavbar />
      <div className="drawer h-[87vh] drawer-mobile">
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content flex flex-col bg-[#F6F9FC]">
          <div className="lg:hidden">
            <DashboardHeader role="user" />
          </div>
          <div className="px-5 pb-0 pt-5">
            <Outlet />
          </div>
        </div>

        <Sidebar role="user" />
      </div>
    </div>
  );
};

export default UserLayout;
